import { DeviceContext, DeviceType } from 'contexts/index'
import { readableColor } from 'polished'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { SVG, SVGNames } from '../SVG'
import { AppTypography, Typography } from '../Typography'

interface InfoBoxProps extends InfoBoxStyleProps {
  content?: string | string[] | React.ReactElement
  info?: string | React.ReactElement
  open: boolean
  setOpen?(): void
  setHide?(): void
}

interface InfoBoxStyleProps {
  width?: string
  display?: string

  style?: { [key: string]: string }
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  content,
  info,
  open,
  setOpen,
  setHide,
  display,
  style
}) => {
  const { device } = useContext(DeviceContext)
  const isMobile = device === DeviceType.MOBILE

  const handleOpen = () => {
    if (setOpen) {
      setOpen()
    }
  }

  const handleHide = () => {
    if (setHide) {
      setHide()
    }
  }

  return (
    <InfoBoxContainer className='infobox' width={isMobile ? '100%' : '16px'} display={display}>
      {!!info && `${info} `}
      <IconWrapper
        bgColor={AppTheme.colors.primary}
        display={isMobile ? 'inline-flex' : 'flex'}
        styles={{
          paddingLeft: '1px'
        }}
        onClick={handleOpen}>
        <SVG name={SVGNames.Info} withContainer={false} />
      </IconWrapper>
      <>
        {isMobile ? (
          <ContentContainerMobile visible={open} style={style}>
            <Typography
              type={AppTypography.INFOBOX}
              textAlignment='left'
              fontWeight={300}
              color={readableColor(
                AppTheme.colors.primary,
                AppTheme.colors.black,
                AppTheme.colors.white
              )}>
              {content}
            </Typography>
            <IconWrapper
              display='flex'
              bgColor={AppTheme.colors.white}
              onClick={handleHide}
              styles={{ position: 'absolute', top: '6px', right: '6px' }}>
              <SVG name={SVGNames.Exit} />
            </IconWrapper>
          </ContentContainerMobile>
        ) : (
          <ContentContainer visible={open}>
            <Typography
              type={AppTypography.INFOBOX}
              textAlignment='left'
              fontWeight={300}
              color={readableColor(
                AppTheme.colors.primary,
                AppTheme.colors.black,
                AppTheme.colors.white
              )}>
              {content}
            </Typography>
            <IconWrapper
              display='flex'
              bgColor={AppTheme.colors.white}
              onClick={handleHide}
              styles={{ position: 'absolute', top: '6px', right: '6px', height: '16px' }}>
              <SVG name={SVGNames.Exit} withContainer={false} />
            </IconWrapper>
          </ContentContainer>
        )}
      </>
    </InfoBoxContainer>
  )
}

const InfoBoxContainer = styled.div<InfoBoxStyleProps>`
  display: ${(props) => props.display};
  position: relative;
  width: ${(props) => props.width};
`

interface IconWrapperProps {
  bgColor: string
  display?: string
  styles?: { [key: string]: string }
}

const IconWrapper = styled.div<IconWrapperProps>`
  width: 16px;
  height: 17px;
  display: ${(props) => props.display};
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border: none;
  border-radius: 50%;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  ${(props) => props.styles};
  z-index: 101;
`

interface ContentContainerProps {
  visible: boolean
  style?: { [key: string]: string }
}
const ContentContainer = styled.div<ContentContainerProps>`
  position: absolute;
  top: 25px;
  right: -50px;
  padding: ${(props) => props.theme.spacingSize.laptop.XS}px;
  padding-right: ${(props) => props.theme.spacingSize.laptop.M}px;
  width: 268px;
  height: auto;
  border: none;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  white-space: pre-wrap;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 1s ease;
  z-index: 150;
`

const ContentContainerMobile = styled.div<ContentContainerProps>`
  position: absolute;
  margin-top: 5px;
  padding: ${(props) => props.theme.spacingSize.laptop.XS}px;
  padding-right: ${(props) => props.theme.spacingSize.laptop.M}px;
  width: 70vw;
  height: auto;
  border: none;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  white-space: pre-wrap;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 1s ease;
  z-index: 150;
  ${(props) => props.style};
`

export default InfoBox
