import React from 'react'
import styled from 'styled-components'

interface FlexboxProps extends FlexStyleProps {
  onClick?(): void
  className?: string
  children: React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNode[]
}

interface FlexStyleProps {
  bgColor?: string
  gap?: string
  justifyContent?: string
  flexDirection?: string
  alignItems?: string
  style?: { [key: string]: string }
}

export const Flex: React.FC<FlexboxProps> = ({ children, onClick, className, gap, ...props }) => {
  return (
    <StyledFlex onClick={onClick} className={className} gap={gap} {...props}>
      {children}
    </StyledFlex>
  )
}

const StyledFlex = styled.div<FlexStyleProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  background-color: ${(props) => props.bgColor};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  box-sizing: border-box;
  gap: ${(props) => props.gap};
  ${(props) => props.style};
`

export default Flex
