import { SVGNames } from 'components/index'

import { Facility, SelectOptionTypeForInstalled } from './questionInteractStates'

export const InstalledFacility: SelectOptionTypeForInstalled[] = [
  {
    value: 'PV-Anlage',
    checked: false,
    svgName: SVGNames.PVAnlage,
    value_id: Facility.PVA,
    name: Facility.PVA,
    disabled: false
  },
  {
    value: 'Batteriespeicher',
    checked: false,
    svgName: SVGNames.BatteryStorage,
    value_id: Facility.BATTERTY,
    name: Facility.BATTERTY,
    disabled: true
  },
  {
    value: 'Wallbox',
    checked: false,
    svgName: SVGNames.Wallbox,
    value_id: Facility.WALLBOX,
    name: Facility.WALLBOX,
    disabled: false
  },
  {
    value: 'Wärmepumpe',
    checked: false,
    svgName: SVGNames.WaermePumpe,
    value_id: Facility.HEATPUMP,
    name: Facility.HEATPUMP,
    disabled: false
  },
  {
    value: 'Keine',
    checked: false,
    svgName: SVGNames.None,
    value_id: Facility.NONE,
    name: Facility.NONE,
    disabled: false
  }
]
