import Collapse from '@kunukn/react-collapse'
import {
  AccordionMobile,
  AppTypography,
  QuestionTextField,
  Select,
  Space,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { AppTheme } from 'theme/index'
import { Units } from 'utils/index'

import 'react-confirm-alert/src/react-confirm-alert.css'

export const QuestionPossessionM: React.FC = observer(() => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore

  const handleOpen = () => {
    if (!questionStore.questionsStates[0].blocked) {
      questionStore.updateQuestionStateById(
        'POSSESSION',
        'open',
        !questionStore.questionsStates[0].open
      )
    }
  }

  return (
    <AccordionMobile
      className='question_possession_mobile'
      index={0}
      title='Bestand und Interesse'
      isOpen={!questionStore.questionsStates[0].blocked && questionStore.questionsStates[0].open}
      isBlocked={questionStore.questionsStates[0].blocked}
      setIsOpen={handleOpen}
      isEdited={questionStore.questionsStates[0].answered}>
      <>
        <Typography type={AppTypography.QUESTION} fontWeight={400}>
          {'Das habe ich bereits:'}
        </Typography>
        <Select
          name={'installed_facility'}
          isMultiple
          useGrid
          gridGap={`${AppTheme.spacingSize.laptop.S * 2}px`}
          disabledItemName={questionStore.disabledInstalledFacility}
          selectedOptions={questionStore.installedFacility}
          setSelectedOptions={questionStore.updateInstalledFacility}
        />

        <Collapse isOpen={questionStore.isPVInstalled}>
          <Space pt={`${AppTheme.spacingSize.mobile.M}px`}>
            <QuestionTextField
              hint={Units.KWP}
              question='Leistung Ihrer PV-Anlage:'
              hasError={store.uiStore.fieldPowerPVAHasError}
              value={questionStore.powerPVA as string}
              name='PvPower'
              placeholder='Anlagenleistung'
              onInputChange={questionStore.updatePowerPVA}
            />
          </Space>
        </Collapse>

        <Space
          mb={`${AppTheme.spacingSize.mobile.XXL}px`}
          mt={`${AppTheme.spacingSize.mobile.XL}px`}>
          <Typography type={AppTypography.QUESTION} fontWeight={400}>
            {'Dafür interessiere ich mich:'}
          </Typography>
          <Select
            isMultiple
            useGrid
            name={'interested_facility'}
            gridGap={`${AppTheme.spacingSize.laptop.S * 2}px`}
            disabledItemName={questionStore.disabledInterestedFacility}
            selectedOptions={questionStore.getInterestedFacilities(
              questionStore.interestedFacility
            )}
            setSelectedOptions={questionStore.updateInterestedFacility}
          />
        </Space>
      </>
    </AccordionMobile>
  )
})

export default QuestionPossessionM
