import Collapse from '@kunukn/react-collapse'
import {
  AccordionMobile,
  AppTypography,
  InfoBox,
  RangeSlider,
  RangeSliderCar,
  Space,
  ToggleButton,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { isFirefox } from 'react-device-detect'
import { AppTheme } from 'theme/index'
import { DrivingDistanceSteps, Units } from 'utils/index'

export const QuestionEmobility: React.FC = observer(() => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore
  const [isInfoBoxOpen, setIsInfoBoxOpen] = useState(false)

  const initialDrivingDistance = Math.floor((DrivingDistanceSteps.length - 1) / 2)
  const elemIndex = DrivingDistanceSteps.findIndex(
    (el) => el.output === questionStore.averageDrivingDistance
  )
  const stepIndex = elemIndex === -1 ? initialDrivingDistance : elemIndex

  const handleOpen = () => {
    if (!questionStore.questionsStates[2].blocked) {
      questionStore.updateQuestionStateById(
        'EMOBILITY',
        'open',
        !questionStore.questionsStates[2].open
      )
    }
  }

  return (
    <AccordionMobile
      className='question_emobility'
      title='E-Mobilität'
      index={2}
      isOpen={!questionStore.questionsStates[2].blocked && questionStore.questionsStates[2].open}
      isBlocked={questionStore.questionsStates[2].blocked}
      setIsOpen={handleOpen}
      isEdited={questionStore.questionsStates[2].answered}>
      <>
        <Space mb={`${AppTheme.spacingSize.mobile.XL}px`}>
          <ToggleButton
            label='Ist E-Mobilität für Sie zukünftig ein Thema?'
            name='isEmobilityFutureIssue'
            setChecked={() =>
              questionStore.updateIsEmobilityFutureIssue(!questionStore.isEmobilityFutureIssue)
            }
            checked={questionStore.isEmobilityFutureIssue}
          />
        </Space>
        <Collapse isOpen={questionStore.isEmobilityFutureIssue} overflowOnExpanded={true}>
          <>
            <Typography type={AppTypography.QUESTION} fontWeight={300} width='100%'>
              <InfoBox
                open={isInfoBoxOpen}
                setOpen={() => setIsInfoBoxOpen(true)}
                setHide={() => setIsInfoBoxOpen(false)}
                content={
                  <>
                    Beachten Sie bitte, dass ein Hybridfahrzeug deutlich weniger Strecke elektrisch
                    fährt, wodurch der Mehrverbrauch geringer ausfallen kann.
                  </>
                }
                info='Wie viele Kilometer fahren Sie durchschnittlich im Jahr?'
              />
            </Typography>

            <Space
              mt={`${AppTheme.spacingSize.mobile.S}px`}
              mb={`${AppTheme.spacingSize.mobile.XXL}px`}>
              {isFirefox ? (
                <RangeSlider
                  steps={DrivingDistanceSteps}
                  stepDividedBy={50}
                  value={
                    questionStore.averageDrivingDistance !== '' ? stepIndex : initialDrivingDistance
                  }
                  setValue={questionStore.updateAverageDrivingDistance}
                  output={
                    !!questionStore.averageDrivingDistance
                      ? `${DrivingDistanceSteps[stepIndex].output} ${Units.KM}`
                      : 'Bitte wählen'
                  }
                  description={`Ihr Mehrverbrauch pro Jahr liegt bei etwa ${store.calculationStore.consumEM} ${Units.KWH}.`}
                />
              ) : (
                <RangeSliderCar
                  steps={DrivingDistanceSteps}
                  stepDividedBy={50}
                  value={
                    questionStore.averageDrivingDistance !== '' ? stepIndex : initialDrivingDistance
                  }
                  setValue={questionStore.updateAverageDrivingDistance}
                  output={
                    !!questionStore.averageDrivingDistance
                      ? `${DrivingDistanceSteps[stepIndex].output} ${Units.KM}`
                      : 'Bitte wählen'
                  }
                  description={`Ihr Mehrverbrauch pro Jahr liegt bei etwa ${store.calculationStore.consumEM} ${Units.KWH}.`}
                />
              )}
            </Space>
          </>
        </Collapse>
      </>
    </AccordionMobile>
  )
})

export default QuestionEmobility
