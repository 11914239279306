import { DeviceContext, DeviceType } from 'contexts/index'
import React, { useContext } from 'react'

import { DocumentHead } from '../template'

import { MainContentLaptop } from './MainContent_Laptop'
import { MainContentMobile } from './MainContent_Mobile'
interface MainContainerProps {
  appTitle: string
}
export const MainContainer: React.FC<MainContainerProps> = ({ appTitle }) => {
  const { device } = useContext(DeviceContext)

  return (
    <React.Fragment>
      <DocumentHead title={appTitle} />
      <React.Fragment>
        {device === DeviceType.LAPTOP ? (
          <MainContentLaptop appTitle={appTitle} />
        ) : (
          <MainContentMobile appTitle={appTitle} />
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

export default MainContainer
