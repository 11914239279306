import Axios from 'axios'
import { getBffURL } from 'utils/index'

export class API {
  private API_URL = getBffURL
  public fetchCISettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/cisettings')
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  public fetchPVSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/pvsettings')
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  public fetchNotifierSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/pvnotifiersettings')
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  public fetchPVWelcomeSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/pvwelcomesettings')
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  public fetchPVEmailSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/pvemailsettings')
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  public calculate = async (data: any) => {
    try {
      const request = await Axios.post(this.API_URL + 'api/calculate-pv', data)
      console.log('send calculate ALL request', {
        endpoint: this.API_URL + 'api/calculate-pv',
        data: request.data
      })
      return request.data
    } catch (e) {
      console.error(e)
    }
  }

  public calculateHPConsum = async (data: any) => {
    try {
      const request = await Axios.post(this.API_URL + 'api/calculate-hp-consum', data)
      console.log('send calculate HP-Consum request', {
        entpoint: this.API_URL + 'api/calculate-hp-consum',
        data: request.data
      })
      return request
    } catch (e) {
      console.error(e)
    }
  }

  public calculatePVPrice = async (data: any) => {
    try {
      const request = await Axios.post(this.API_URL + 'api/calculate-pv-price', data)
      console.log('send calculate PV request: ', {
        endpoint: this.API_URL + 'api/calculate-pv-price',
        data: request.data
      })
      return request
    } catch (e) {
      console.error(e)
    }
  }

  public calculateBSPrice = async (data: any) => {
    try {
      const request = await Axios.post(this.API_URL + 'api/calculate-bs', data)
      console.log('send calculate BS request: ', {
        endpoint: this.API_URL + 'api/calculate-bs',
        data: request.data
      })
      return request
    } catch (e) {
      console.error(e)
    }
  }
  public calculateWBPrice = async (data: any) => {
    try {
      const request = await Axios.post(this.API_URL + 'api/calculate-wb', data)
      console.log('send calculate WB request: ', {
        endpoint: this.API_URL + 'api/calculate-wb',
        data: request.data
      })
      return request
    } catch (e) {
      console.error(e)
    }
  }

  public calculateHPPrice = async (data: any) => {
    try {
      const request = await Axios.post(this.API_URL + 'api/calculate-hp', data)
      console.log('send calculate HP-Price request: ', {
        endpoint: this.API_URL + 'api/calculate-hp',
        data: request.data
      })
      return request
    } catch (e) {
      console.error(e)
    }
  }

  public sendEmailRequest = async (data: any) => {
    try {
      const request = await Axios.post(this.API_URL + 'api/savepv', data)
      console.log('send emial request: ', {
        breakpoint: this.API_URL + 'api/savepv',
        data: request
      })
      return request
    } catch (e) {
      console.error(e)
    }
  }
}

export default API
