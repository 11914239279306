// 5,10,15,20,25,30,35,40,45,55,65,75,85,95,105,>105

export const convertCapacityToSteps = () => {
  const steps: { output: string; value: number }[] = []

  for (let i = 5; i <= 55; i = i + 5) {
    steps.push({ output: `${i}`, value: i })
  }

  for (let i = 65; i <= 106; i = i + 10) {
    steps.push({ output: `${i}`, value: i })
  }

  steps.push({ output: '> 105', value: 105 })

  return steps
}
export const BatteryCapacitySteps = convertCapacityToSteps()
