import { ScrollContainer } from 'components/index'
import React from 'react'
import { AppTheme } from 'theme/index'

import ResultAfterCalculation from './AfterCalculation'
import ResultBeforeCalculation from './BeforeCalculation'

interface ResultProps {
  onShowResult(): void
  onShowQuestions(): void
  showCalculation: boolean
}

export const Result: React.FC<ResultProps> = ({
  onShowResult,
  showCalculation,
  onShowQuestions
}) => {
  return (
    <ScrollContainer
      height={`calc(${AppTheme.window.innerHeight}px - 44px)`}
      heightX='100%'
      padding='0px 50px'
      margin='0px -50px'
      ySpace={[`${AppTheme.spacingSize.laptop.S}px`, `${AppTheme.spacingSize.laptop.M}px`]}
      xSpace={[`${AppTheme.spacingSize.laptop.L}px`]}>
      {showCalculation ? (
        <ResultAfterCalculation onShowQuestions={onShowQuestions} />
      ) : (
        <ResultBeforeCalculation onSubmit={onShowResult} />
      )}
    </ScrollContainer>
  )
}

export default Result
