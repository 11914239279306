/* eslint-disable react/display-name */
import Collapse from '@kunukn/react-collapse'
import { observer } from 'mobx-react-lite'
import React, { ReactElement, ReactNode } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { Divider } from '../Divider'
import { IconState, SVG, SVGNames } from '../SVG'
import { Space } from '../Space'
import { AppTypography, Typography } from '../Typography'

interface AccordionMobileProps {
  className?: string
  isOpen: boolean
  title: string
  index: number
  children?: ReactElement | ReactNode
  setIsOpen(isOpen: boolean, index?: number): void
  isEdited?: boolean
  isBlocked?: boolean
}

export const AccordionMobile: React.FC<AccordionMobileProps> = observer(
  ({ className, title, children, isBlocked, isEdited, isOpen, setIsOpen }) => {
    const handleOpen = () => {
      setIsOpen(isOpen)
    }

    return (
      <AccordionContainer className={`accordion_mobile ${className}`} expanded={isOpen}>
        <Space mt={`${AppTheme.spacingSize.mobile.S}px`}>
          <TitleContainer className='question_theme' onClick={handleOpen}>
            <div>
              <Typography
                type={AppTypography.HEADLINE}
                color={isBlocked ? AppTheme.colors.grayB9 : AppTheme.colors.gray65}>
                {title}
              </Typography>
            </div>
            <SVG
              name={SVGNames.Edit}
              iconState={isEdited ? IconState.ACTIVE : IconState.DEACTIVATED}
            />
          </TitleContainer>
          <Space mt={AppTheme.spacingSize.mobile.S} height='auto'>
            <Divider />
          </Space>

          <Collapse isOpen={isOpen} overflowOnExpanded={true}>
            <CollapsibleContainer height={AppTheme.window.innerHeight - 151 - 54}>
              <Space pt={`${AppTheme.spacingSize.mobile.M}px`} height='auto'>
                {children}
              </Space>
            </CollapsibleContainer>
          </Collapse>
        </Space>
      </AccordionContainer>
    )
  }
)

interface CollapseProps {
  expanded?: boolean
  height?: number
}

const AccordionContainer = styled.div<CollapseProps>`
  width: 100%;
  /* height: ${(props) => (props.expanded ? 'calc(100% - 10px)' : 'auto')};
  max-height: ${(props) => (props.expanded ? 'calc(100% - 10px)' : 'auto')}; */
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const CollapsibleContainer = styled.div<CollapseProps>`
  width: 100%;
  height: ${(props) => props.height}px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  &::-webkit-scrollbar {
    display: none;
  }
`

interface IconContainerProps {
  bgColor: string
}
export const IconContainer = styled.div<IconContainerProps>`
  cursor: inherit;
  overflow: hidden;
  background-color: ${(props) => props.bgColor};
  display: block;
  width: 54px;
  height: 54px;
  position: relative;
  float: right;
`
export default AccordionMobile
