export const numberWithThousandSeparator = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const removeThousandSeparator = (value: string) => {
  return parseInt(value.replace(/\./g, ''))
}

export const numberFormat = (value: string | number, keepTwoDecimalPlaces?: boolean): string => {
  let newValue =
    typeof value === 'string'
      ? new Intl.NumberFormat('de-DE').format(+value)
      : new Intl.NumberFormat('de-DE').format(value)

  if (Number.isInteger(+value)) {
    newValue = newValue + ',00'
  }

  if (newValue[newValue.length - 2] === ',' && keepTwoDecimalPlaces) {
    newValue = newValue + '0'
  }
  return newValue
}

export const changeObjPropValueLoop = (
  obj: { [key: string]: string | number },
  keepTwoDecimalPlaces?: boolean
) => {
  Object.keys(obj).forEach(function (key) {
    obj[key] = numberFormat(obj[key], keepTwoDecimalPlaces)
  })
  return obj
}

export const timeConvert = (num: number): string => {
  const hours = parseInt((num / 1).toString())
  const minutes = Math.floor((num % 1) * 60)
  const minute: string = minutes < 10 ? '0' + minutes.toString() : minutes.toString()
  return hours + ':' + minute
}
