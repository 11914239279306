import React from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import {
  AppTypography,
  AppTypoTypes,
  TeaserTypography,
  TeaserTypoTypes,
  TypographyAlignmentType,
  TypographyDisplayType
} from './static'

interface TypographyProps extends TypographyStyleProps {
  children: string | React.ReactElement | React.ReactNode
  className?: string
  info?: React.ReactElement | React.ReactNode
  type?: AppTypoTypes | TeaserTypoTypes
}

export const Typography: React.FunctionComponent<TypographyProps> = (props: TypographyProps) => {
  const renderTypography = (props: TypographyProps) => {
    const { children, type } = props

    switch (type) {
      case AppTypography.HEADLINE:
        return React.createElement(
          StyledText,
          {
            fontWeight: AppTheme.fonts.weight.medium,
            fontSize: AppTheme.fonts.fontsize.app.XL,
            lineHeight: AppTheme.fonts.lineheight.app.L,
            ...props
          },
          children
        )

      case AppTypography.QUESTION:
      case AppTypography.BUTTON:
        return React.createElement(
          StyledText,
          {
            fontSize: AppTheme.fonts.fontsize.app.L,
            lineHeight: AppTheme.fonts.lineheight.app.L,
            ...props
          },
          children
        )

      case AppTypography.ICONDESCRIPTION:
        return React.createElement(
          StyledText,
          {
            fontWeight: AppTheme.fonts.weight.light,
            fontSize: AppTheme.fonts.fontsize.app.XS,
            lineHeight: AppTheme.fonts.lineheight.app.S,
            color: AppTheme.colors.gray65,
            ...props
          },
          children
        )

      case AppTypography.SUBLINE:
      case AppTypography.INFOBOX:
        return React.createElement(
          StyledText,
          {
            fontSize: AppTheme.fonts.fontsize.app.S,
            lineHeight: AppTheme.fonts.lineheight.app.M,
            ...props
          },
          children
        )

      case AppTypography.RESULT:
        return React.createElement(
          StyledText,
          {
            fontSize: AppTheme.fonts.fontsize.app.M,
            lineHeight: AppTheme.fonts.lineheight.app.L,
            color: AppTheme.colors.black,
            ...props
          },
          children
        )

      case AppTypography.TABLE:
        return React.createElement(
          StyledText,
          {
            fontSize: AppTheme.fonts.fontsize.app.XS,
            lineHeight: AppTheme.fonts.lineheight.app.M,
            ...props
          },
          children
        )

      /** ===== Teaser Typo  */

      case TeaserTypography.PRODUCTNAME:
        return React.createElement(
          StyledText,
          {
            fontWeight: AppTheme.fonts.weight.medium,
            fontSize: AppTheme.fonts.fontsize.teaser.laptop.L,
            lineHeight: AppTheme.fonts.lineheight.teaser.laptop.L,
            color: AppTheme.colors.white,
            ...props
          },
          children
        )

      case TeaserTypography.HEADLINE:
        return React.createElement(
          StyledText,
          {
            fontWeight: AppTheme.fonts.weight.medium,
            fontSize: AppTheme.fonts.fontsize.teaser.laptop.XL,
            lineHeight: AppTheme.fonts.lineheight.teaser.laptop.XL,
            color: AppTheme.colors.white,
            ...props
          },
          children
        )

      case TeaserTypography.SUBLINEA:
        return React.createElement(
          StyledText,
          {
            fontWeight: AppTheme.fonts.weight.regular,
            fontSize: AppTheme.fonts.fontsize.teaser.laptop.M,
            lineHeight: AppTheme.fonts.lineheight.teaser.laptop.M,
            color: AppTheme.colors.white,
            ...props
          },
          children
        )

      case TeaserTypography.SUBLINEB:
        return React.createElement(
          StyledText,
          {
            fontWeight: AppTheme.fonts.weight.medium,
            fontSize: AppTheme.fonts.fontsize.teaser.laptop.M,
            lineHeight: AppTheme.fonts.lineheight.teaser.laptop.S,
            ...props
          },
          children
        )

      case TeaserTypography.TEXT:
        return React.createElement(
          StyledText,
          {
            fontSize: AppTheme.fonts.fontsize.teaser.laptop.S,
            lineHeight: AppTheme.fonts.lineheight.teaser.laptop.S,
            ...props
          },
          children
        )

      case TeaserTypography.TEXTINBOX:
        return React.createElement(
          StyledText,
          {
            fontSize: AppTheme.fonts.fontsize.teaser.laptop.M,
            lineHeight: AppTheme.fonts.lineheight.teaser.laptop.S,
            color: AppTheme.colors.black,
            ...props
          },
          children
        )

      case TeaserTypography.PRODUCTNAME_M:
        return React.createElement(
          StyledText,
          {
            fontWeight: AppTheme.fonts.weight.medium,
            fontSize: AppTheme.fonts.fontsize.teaser.mobile.L,
            lineHeight: AppTheme.fonts.lineheight.teaser.mobile.M,
            color: AppTheme.colors.white,
            ...props
          },
          children
        )

      case TeaserTypography.HEADLINE_M:
        return React.createElement(
          StyledText,
          {
            fontWeight: AppTheme.fonts.weight.medium,
            fontSize: AppTheme.fonts.fontsize.teaser.mobile.XL,
            lineHeight: AppTheme.fonts.lineheight.teaser.mobile.L,
            color: AppTheme.colors.white,
            ...props
          },
          children
        )

      case TeaserTypography.SUBLINEA_M:
        return React.createElement(
          StyledText,
          {
            fontWeight: AppTheme.fonts.weight.regular,
            fontSize: AppTheme.fonts.fontsize.teaser.mobile.L,
            lineHeight: AppTheme.fonts.lineheight.teaser.mobile.M,
            color: AppTheme.colors.white,
            ...props
          },
          children
        )

      case TeaserTypography.SUBLINEB_M:
        return React.createElement(
          StyledText,
          {
            fontWeight: AppTheme.fonts.weight.medium,
            fontSize: AppTheme.fonts.fontsize.teaser.mobile.L,
            lineHeight: AppTheme.fonts.lineheight.teaser.mobile.M,
            ...props
          },
          children
        )

      case TeaserTypography.TEXT_M:
        return React.createElement(
          StyledText,
          {
            fontSize: AppTheme.fonts.fontsize.teaser.mobile.S,
            lineHeight: AppTheme.fonts.lineheight.teaser.mobile.S,
            ...props
          },
          children
        )

      case TeaserTypography.TEXTINBOX_M:
        return React.createElement(
          StyledText,
          {
            fontSize: AppTheme.fonts.fontsize.teaser.mobile.M,
            lineHeight: AppTheme.fonts.lineheight.teaser.mobile.M,
            color: AppTheme.colors.black,
            ...props
          },
          children
        )

      default:
        return React.createElement(
          StyledText,
          {
            ...props
          },
          children
        )
    }
  }

  return renderTypography(props)
}

Typography.defaultProps = {
  userSelect: 'none'
}

interface TypographyStyleProps {
  color?: string
  position?: string
  display?: TypographyDisplayType
  fontFamily?: string
  fontWeight?: number | string
  fontSize?: number
  textAlignment?: TypographyAlignmentType
  lineHeight?: number
  letterSpacing?: string
  textTransform?: string
  wordWrap?: boolean
  width?: string
  fontStyle?: string
  justifyContent?: string

  userSelect?: 'none' | 'all'
}

const StyledText = styled.div<TypographyStyleProps>`
  color: ${(props) => props.color};
  display: ${(props) => props.display};
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize}px;
  text-align: ${(props) => props.textAlignment};
  line-height: ${(props) => props.lineHeight}px;
  letter-spacing: ${(props) => (props.letterSpacing ? props.letterSpacing : '0px')};
  text-transform: ${(props) => props.textTransform};
  white-space: ${(props) => (props.wordWrap ? 'normal' : 'break-spaces')};
  width: ${(props) => (props.width ? props.width : '100%')};
  font-weight: ${(props) => props.fontWeight};
  font-style: ${(props) => props.fontStyle};
  position: ${(props) => props.position};
  justify-content: ${(props) => props.justifyContent};
  user-select: ${(props) => props.userSelect};
`

export default Typography
