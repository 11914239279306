import { DeviceContext, DeviceType } from 'contexts/index'
import { readableColor } from 'polished'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { Space } from '../Space'
import { Typography } from '../Typography'

interface SwitchButtonProps {
  switchText: [string, string]
  isLeftSelected: boolean
  setIsLeftSelected(isLeftSelected: boolean): void

  disabled?: [string, string]
}
export const SwitchButton: React.FC<SwitchButtonProps> = ({
  switchText,
  isLeftSelected,
  setIsLeftSelected,
  disabled
}) => {
  const { device } = useContext(DeviceContext)

  const [leftDisabled, setLeftDisabled] = useState(false)
  const [rightDisabled, setRightDisabled] = useState(false)

  useEffect(() => {
    if (disabled) {
      setLeftDisabled(disabled[0] !== '' && disabled[0] === switchText[0])
      setRightDisabled(disabled[1] !== '' && disabled[1] === switchText[1])
    }
  })

  const textColor = readableColor(
    AppTheme.colors.secondary,
    AppTheme.colors.black,
    AppTheme.colors.white
  )

  return (
    <>
      <StyledBTN
        selected={isLeftSelected}
        isOnLeft
        onClick={() => setIsLeftSelected(true)}
        disabled={leftDisabled}>
        <Space px={`${device === DeviceType.LAPTOP ? AppTheme.spacingSize.laptop.XS : 6}px`} py={1}>
          <Typography
            fontSize={
              device === DeviceType.LAPTOP
                ? AppTheme.fonts.fontsize.app.M
                : AppTheme.fonts.fontsize.app.XS
            }
            fontWeight={300}
            userSelect='none'
            color={
              leftDisabled
                ? AppTheme.colors.grayE
                : isLeftSelected
                ? textColor
                : AppTheme.colors.grayB9
            }>
            {switchText[0]}
          </Typography>
        </Space>
      </StyledBTN>
      <StyledBTN
        selected={!isLeftSelected}
        onClick={() => setIsLeftSelected(false)}
        isOnLeft={false}
        disabled={rightDisabled}>
        <Space px={`${device === DeviceType.LAPTOP ? AppTheme.spacingSize.laptop.XS : 6}px`} py={1}>
          <Typography
            fontSize={
              device === DeviceType.LAPTOP
                ? AppTheme.fonts.fontsize.app.M
                : AppTheme.fonts.fontsize.app.XS
            }
            fontWeight={300}
            userSelect='none'
            color={
              rightDisabled
                ? AppTheme.colors.grayE
                : isLeftSelected
                ? AppTheme.colors.grayB9
                : textColor
            }>
            {switchText[1]}
          </Typography>
        </Space>
      </StyledBTN>
    </>
  )
}

interface ButtonProps {
  selected: boolean
  isOnLeft?: boolean
  disabled?: boolean
}

const StyledBTN = styled.div<ButtonProps>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  display: inline-block;
  width: auto;
  height: auto;
  border: ${(props) =>
    props.disabled
      ? `1px solid ${props.theme.colors.grayE}`
      : props.selected
      ? `1px solid ${props.theme.colors.secondary}`
      : `1px solid ${props.theme.colors.grayB9}`};
  background-color: ${(props) => (props.selected ? props.theme.colors.secondary : 'white')};
  transition: all 0.3s ease;
  border-radius: ${(props) => (props.isOnLeft ? '3px 0px 0px 3px' : '0px 3px 3px 0px')};
`

export default SwitchButton
