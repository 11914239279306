export const BuildYearSteps: { output: string; value: string }[] = [
  { output: 'bis 1918', value: '1918' },
  { output: '1919 - 1948', value: '1919' },
  { output: '1949 - 1978', value: '1949' },
  { output: '1979 - 1990', value: '1979' },
  { output: '1991 - 2000', value: '1991' },
  { output: '2001 - 2008', value: '2001' },
  { output: '2009 - 2017', value: '2009' },
  { output: 'Neubau', value: '2009' }
]
