import { Flex, Space, Typography } from 'components/index'
import React from 'react'
import { AppTheme } from 'theme/index'

export const CapacityInfobox: React.FC = () => {
  return (
    <>
      <Typography lineHeight={AppTheme.fonts.lineheight.app.M}>
        Als Richtwert für die Batteriekapazität können Sie sich an den folgenden Werten orientieren.
      </Typography>
      <Space my={`${AppTheme.spacingSize.laptop.M}px`}>
        <Typography lineHeight={AppTheme.fonts.lineheight.app.M}>
          Vollelektrische Fahrzeuge:
        </Typography>
        <Flex flexDirection='row' justifyContent='space-between'>
          <Typography display='inline' fontWeight='bold'>
            Renault Zoe
          </Typography>
          <Typography display='inline' textAlignment='right' fontWeight='bold'>
            41-52 kWh
          </Typography>
        </Flex>
        <Flex flexDirection='row' justifyContent='space-between'>
          <Typography display='inline' fontWeight='bold'>
            Tesla Model S
          </Typography>
          <Typography display='inline' textAlignment='right' fontWeight='bold'>
            70-100 kWh
          </Typography>
        </Flex>
      </Space>
      <>
        <Typography lineHeight={AppTheme.fonts.lineheight.app.M}>Plug-In-Hybride:</Typography>
        <Flex flexDirection='row' justifyContent='space-between'>
          <Typography display='inline' fontWeight='bold'>
            Volvo XC 90
          </Typography>
          <Typography display='inline' textAlignment='right' fontWeight='bold'>
            10 kWh
          </Typography>
        </Flex>
        <Flex flexDirection='row' justifyContent='space-between'>
          <Typography display='inline' fontWeight='bold'>
            Mercedes GLC 300e
          </Typography>
          <Typography display='inline' textAlignment='right' fontWeight='bold'>
            13 kWh
          </Typography>
        </Flex>
      </>
    </>
  )
}

export default CapacityInfobox
