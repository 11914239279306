import React, { Fragment } from 'react'
import styled from 'styled-components'

import { IconStateType, SVGNames, SVGType } from './SVGLib'

interface SVGProps {
  name: string
  iconState?: IconStateType
  className?: string

  withContainer?: boolean
  style?: { [key: string]: string }
}

export const SVG: React.FC<SVGProps> = ({ name, iconState, className, style, withContainer }) => {
  const renderSVG = (svgName: string) => {
    switch (svgName) {
      case SVGNames.BatteryStorage:
        return <SVGType.BatteryStorage state={iconState} style={style} />

      case SVGNames.EinfamilienHaus:
        return <SVGType.EinfamilienHaus state={iconState} />

      case SVGNames.None:
        return <SVGType.None state={iconState} />

      case SVGNames.MehrfamilienHaus:
        return <SVGType.MehrfamilienHaus state={iconState} />

      case SVGNames.PVAnlage:
        return <SVGType.PVAnlage state={iconState} style={style} />

      case SVGNames.ReihenHaus:
        return <SVGType.ReihenHaus state={iconState} style={{ transform: 'scale(1.1)' }} />

      case SVGNames.WaermePumpe:
        return <SVGType.WaermePumpe state={iconState} style={style} />

      case SVGNames.Wallbox:
        return <SVGType.Wallbox state={iconState} style={style} />

      case SVGNames.Wohnung:
        return <SVGType.Wohnung state={iconState} />

      case SVGNames.Edit:
        return <SVGType.Edit state={iconState} />

      case SVGNames.Info:
        return <SVGType.Info />

      case SVGNames.ArrowUp:
        return <SVGType.ArrowUp />

      case SVGNames.ArrowDown:
        return <SVGType.ArrowDown />

      case SVGNames.Exit:
        return <SVGType.Exit style={style} />

      case SVGNames.ShowResult:
        return <SVGType.ShowResult />

      case SVGNames.Next:
        return <SVGType.Next />

      case SVGNames.House:
        return <SVGType.House />

      default:
        return null
    }
  }
  return (
    <>
      {withContainer ? (
        <SVGContainer className={`svg_icon ${className}`}>{renderSVG(name)}</SVGContainer>
      ) : (
        renderSVG(name)
      )}
    </>
  )
}

SVG.defaultProps = {
  withContainer: true
}

const SVGContainer = styled.div`
  display: block;
  cursor: pointer;
  text-align: center;
`

export default SVG
