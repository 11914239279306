import {
  Alert,
  AppTypography,
  Checkbox,
  Flex,
  InputField,
  Link,
  ScrollContainer,
  Select,
  SelectOptionType,
  Space,
  TextArea,
  ToggleButton,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import {
  AppointmentDays,
  AppointmentDaytime,
  ContactFormFields,
  ContactUserDataType,
  IFormValues
} from './static'

export const ContactForm: React.VFC = observer(() => {
  const store = useContext(AppStoreContext)
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<IFormValues>()

  const [isOwner, setIsOwner] = useState(false)

  const [selectedDay, setSelectedDay] = useState(
    store.pvSettings.form.available_days ? store.mapDataToAvailableDays() : AppointmentDays
  )
  const [selectedAppoitmentTime, setSelectedAppoitmentTime] = useState(
    store.pvSettings.form.available_time ? store.mapDataToAvailableTime() : AppointmentDaytime
  )
  const [selectedService, setSelectedService] = useState(store.mapDataToInterestedServices())
  const [policyAgreed, setPolicyAgreed] = useState(false)
  const [isPolicyError, setIsPolicyError] = useState(false)
  const [sentEmail, setSentEmail] = useState(false)

  const additionalQuestion1 = store.pvSettings.form.additional_question_1
  const additionalQuestion2 = store.pvSettings.form.additional_question_2

  const handleCheckPolicyAgreement = () => {
    setPolicyAgreed(!policyAgreed)
    setIsPolicyError(!!policyAgreed)
  }

  const reformUserInputData = (formData: any) => {
    const data: ContactUserDataType = {
      customer: { address: {} },
      appointment: {},
      interested: []
    }
    // customer
    data.customer.isOwner = isOwner
    Object.keys(formData).forEach((key: string) => {
      if (key === 'street' || key === 'zip' || key === 'city') {
        data.customer.address[`${key}`] = formData[key] as string
      } else {
        data.customer[`${key}`] = formData[key] as string
      }
    })

    // apointment
    const days: string[] = []
    selectedDay.forEach((day: SelectOptionType) => {
      if (day.checked) {
        days.push(day.value)
      }
    })
    const daytime: string[] = []
    selectedAppoitmentTime.forEach((appointment: SelectOptionType) => {
      if (appointment.checked) {
        daytime.push(appointment.value)
      }
    })
    data.appointment.days = days
    data.appointment.daytime = daytime

    // interested
    const interested: string[] = []
    !!selectedService &&
      selectedService.forEach((service: SelectOptionType) => {
        if (service.checked) {
          interested.push(service.value)
        }
      })
    data.interested = interested

    return data
  }

  const onSubmit: SubmitHandler<IFormValues> = (formData) => {
    if (!isPolicyError && policyAgreed) {
      const useData = reformUserInputData(formData)
      store.sendEmail(useData)
      setSentEmail(true)
    } else {
      const ContactAlert = ({
        onClose
      }: {
        title: string
        message: string
        onClose: () => void
      }): JSX.Element => {
        return (
          <Alert
            onClose={onClose}
            title='Nicht gesendet'
            description='Bitte korrigieren Sie die rot hinterlegten Eingaben'
          />
        )
      }
      confirmAlert({
        customUI: ContactAlert
      })
    }
  }

  return (
    <ContactContainer className='contact'>
      <ScrollContainer
        height='100%'
        ySpace={[`${AppTheme.spacingSize.laptop.S}px`, '0px']}
        xSpace={[`${AppTheme.spacingSize.laptop.L}px`]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            flexDirection='column'
            gap='12px'
            style={{ marginBottom: `${AppTheme.spacingSize.laptop.L}px` }}>
            <Space
              mt={`${AppTheme.spacingSize.laptop.XXS}px`}
              mb={`${AppTheme.spacingSize.laptop.XS}px`}>
              <ToggleButton
                label='Ich bin Eigentümer'
                name='isOwner'
                setChecked={setIsOwner}
                checked={isOwner}
              />
            </Space>

            {ContactFormFields.map((field) => (
              <InputField
                key={field.fieldName}
                label={field.fieldName}
                placeholder={field.placeholder}
                register={register}
                required={
                  store.pvSettings.form.requiredFields.find(
                    (item: any) => item.key === field.fieldName
                  ).value ?? false
                }
                error={errors[field.fieldName]}
              />
            ))}

            <Flex flexDirection='row' gap='12px'>
              <div style={{ flex: '1' }}>
                <InputField
                  label='zip'
                  register={register}
                  required={
                    store.pvSettings.form.requiredFields.find((item: any) => item.key === 'zip')
                      .value ?? true
                  }
                  placeholder='PLZ'
                  error={errors.zip}
                />
              </div>
              <div style={{ flex: '3' }}>
                <InputField
                  label='city'
                  register={register}
                  required={
                    store.pvSettings.form.requiredFields.find((item: any) => item.key === 'city')
                      .value ?? true //by default true
                  }
                  placeholder='Ort'
                  error={errors.city}
                />
              </div>
            </Flex>

            {additionalQuestion1 && (
              <InputField
                label='additionalQuestion1'
                placeholder={additionalQuestion1.inputValue}
                register={register}
                required={
                  store.pvSettings.form.requiredFields.find(
                    (item: any) => item.key === 'additionalQuestion1'
                  ).value ?? false //by default false
                }
                error={errors.additionalQuestion1}
              />
            )}
            {additionalQuestion2 && (
              <InputField
                label='additionalQuestion2'
                placeholder={additionalQuestion2.inputValue}
                register={register}
                required={
                  store.pvSettings.form.requiredFields.find(
                    (item: any) => item.key === 'additionalQuestion2'
                  ).value ?? false //by default true
                }
                error={errors.additionalQuestion2}
              />
            )}
          </Flex>

          <>
            <Typography
              type={AppTypography.QUESTION}
              color={AppTheme.colors.gray65}
              fontWeight={300}>
              {'Ich bin erreichbar'}
            </Typography>

            <Space
              mt={`${AppTheme.spacingSize.laptop.M - AppTheme.spacingSize.laptop.XS}px`}
              mb={AppTheme.spacingSize.laptop.M}>
              <Select
                isMultiple
                selectedOptions={selectedDay}
                setSelectedOptions={setSelectedDay}
                itemWidth='28%'
                isInline={false}
              />
              {selectedDay.length > 0 && (
                <Space mt={`${AppTheme.spacingSize.laptop.XS}px`}>
                  <Select
                    isMultiple
                    selectedOptions={selectedAppoitmentTime}
                    setSelectedOptions={setSelectedAppoitmentTime}
                  />
                </Space>
              )}
            </Space>
          </>

          {!!selectedService && (
            <>
              <Typography
                type={AppTypography.QUESTION}
                color={AppTheme.colors.gray65}
                fontWeight={300}>
                {'Ich interessiere mich außerdem für'}
              </Typography>
              <Space
                mt={`${AppTheme.spacingSize.laptop.XS}px`}
                mb={`${AppTheme.spacingSize.laptop.M}px`}>
                <Select
                  itemGapX={`${AppTheme.spacingSize.laptop.XS}px`}
                  itemGapY={`${AppTheme.spacingSize.laptop.XS}px`}
                  isMultiple
                  selectedOptions={selectedService}
                  setSelectedOptions={setSelectedService}
                />
              </Space>
            </>
          )}

          <>
            <Typography
              type={AppTypography.QUESTION}
              color={AppTheme.colors.gray65}
              fontWeight={300}>
              {'Kommentar:'}
            </Typography>
            <Space
              mt={`${AppTheme.spacingSize.laptop.XS}px`}
              mb={`${AppTheme.spacingSize.laptop.M}px`}>
              <TextArea register={register} label='comment' placeholder='Kommentar hier eingeben' />
            </Space>
          </>

          <Space mb={`${AppTheme.spacingSize.laptop.L}px`}>
            <Checkbox name='policy' checked={policyAgreed} setChecked={handleCheckPolicyAgreement}>
              <Typography
                type={AppTypography.QUESTION}
                fontWeight={300}
                color={isPolicyError ? AppTheme.colors.red : AppTheme.colors.gray65}>
                {store.pvSettings?.form?.accept_data_protection_checkbox_text}
              </Typography>
            </Checkbox>
            <Link href={store.pvSettings?.form?.protection_url} target='_blank'>
              Datenschutzerklärung öffnen
            </Link>
          </Space>

          <>
            <Flex justifyContent='space-between' alignItems='center'>
              <Typography
                type={AppTypography.SUBLINE}
                color={AppTheme.colors.grayB9}
                fontWeight={300}>
                {'*Pflichtangaben'}
              </Typography>

              <ButtonOuterWrapper disabled={sentEmail}>
                <ButtonWithBorder className='btn btn-lg pro' id='submit' disabled={sentEmail}>
                  {sentEmail && store.uiStore.emailState === '' ? 'Sending' : 'Absenden'}
                </ButtonWithBorder>
              </ButtonOuterWrapper>
            </Flex>
          </>
        </form>
      </ScrollContainer>
    </ContactContainer>
  )
})

const ContactContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 44px);
  color: ${(props) => props.theme.colors.gray65};
`

const ButtonWithBorder = styled.button.attrs({
  type: 'submit'
})<{ disabled: boolean }>`
  height: auto;
  width: auto;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.white};
  appearance: none;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 2px 2px;
  color: ${(props) => (props.disabled ? props.theme.colors.grayB9 : props.theme.colors.secondary)};
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.family};
  font-size: ${(props) => props.theme.fonts.fontsize.app.L}px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:active {
    box-shadow: none;
  }
`
ButtonWithBorder.displayName = 'Sending_ButtonWithBorder'

const ButtonOuterWrapper = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  display: block;
  border-radius: 5px;
  font-family: ${(props) => props.theme.fonts.family};
  font-size: ${(props) => props.theme.fonts.fontsize.app.L}px;
  text-transform: uppercase;
  padding: 5px;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.grayB9 : props.theme.colors.secondary};
`
