/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-undef */
import {
  Accordion,
  Alert,
  AppTypography,
  Flex,
  InfoBox,
  RangeSlider,
  RangeSliderCar,
  Space,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { isFirefox } from 'react-device-detect'
import { AppTheme } from 'theme/index'
import { BatteryCapacitySteps, DrivingDistanceSteps, Question, Units } from 'utils/index'

import CapacityInfobox from './CapacityInfobox'
import 'react-confirm-alert/src/react-confirm-alert.css'

interface QuestionWallboxProps {
  index: number
  isLast: boolean
}
export const QuestionWallbox: React.FC<QuestionWallboxProps> = observer(({ index, isLast }) => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore
  const [isInfoBoxOpen, setIsInfoBoxOpen] = useState([false, false])

  const initialDrivingDistance = Math.floor((DrivingDistanceSteps.length - 1) / 2)
  const elemIndex = DrivingDistanceSteps.findIndex(
    (el) => el.output === questionStore.averageDrivingDistance
  )
  const stepIndex = elemIndex === -1 ? initialDrivingDistance : elemIndex

  const initialCapacity = Math.floor((BatteryCapacitySteps.length - 1) / 2)
  const capacityIndex = BatteryCapacitySteps.findIndex(
    (el) => el.output === questionStore.eMobilityCapacity
  )
  const capacityStepIndex = capacityIndex === -1 ? initialCapacity : capacityIndex

  const handleOpen = () => {
    if (!questionStore.questionsStates[3].blocked) {
      questionStore.updateQuestionStateById(
        Question.WALLBOX,
        'open',
        !questionStore.questionsStates[3].open
      )
    }
  }

  const handleClose = () => {
    // onSubmit()
    questionStore.handleSubmitWallbox()
    if (!questionStore.questionsStates[3].answered) {
      questionStore.updateQuestionStateById(Question.WALLBOX, 'open', true)
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert
              onClose={onClose}
              title='Bitte beantworten Sie die Frage'
              description='Nach der Beantwortung kommen Sie zur nächsten Frage.'
            />
          )
        }
      })
    } else {
      if (isLast && !store.uiStore.resultDeactivated) {
        store.uiStore.updateLeftSideWidth(35)
        store.uiStore.updateShowContact(true)
        store.calculateResult()
      } else {
        questionStore.openNextAccordion(index)
      }

      // questionStore.updateQuestionStateById('WALLBOX', 'open', false)
    }
  }

  return (
    <Accordion
      className='question_wallbox'
      isOpen={!questionStore.questionsStates[3].blocked && questionStore.questionsStates[3].open}
      title='Wallbox'
      isBlocked={questionStore.questionsStates[3].blocked}
      index={3}
      btnText={isLast ? 'Angebot anzeigen' : 'weiter'}
      setIsOpen={handleOpen}
      onSubmit={handleClose}
      isEdited={questionStore.questionsStates[3].answered}
      btnDisabled={isLast ? !store.questionStore.allAnswered : isLast}>
      <>
        {!questionStore.isEmobilityFutureIssue && (
          <>
            <Flex flexDirection='row' justifyContent='flex-start' alignItems='center'>
              <Typography
                type={AppTypography.QUESTION}
                fontWeight={300}
                display='inline'
                width='auto'>
                {'Wie viele Kilometer fahren Sie durchschnittlich im Jahr?'}{' '}
              </Typography>
              <InfoBox
                open={isInfoBoxOpen[0]}
                setOpen={() => setIsInfoBoxOpen([true, false])}
                setHide={() => setIsInfoBoxOpen([false, false])}
                content={
                  <>
                    Beachten Sie bitte, dass ein Hybridfahrzeug deutlich weniger Strecke elektrisch
                    fährt, wodurch der Mehrverbrauch geringer ausfallen kann.
                  </>
                }
              />
            </Flex>
            <Space
              mt={`${AppTheme.spacingSize.laptop.S}px`}
              mb={`${AppTheme.spacingSize.laptop.XL}px`}>
              {isFirefox ? (
                <RangeSlider
                  steps={DrivingDistanceSteps}
                  stepDividedBy={20}
                  value={
                    questionStore.averageDrivingDistance !== '' ? stepIndex : initialDrivingDistance
                  }
                  setValue={questionStore.updateAverageDrivingDistance}
                  output={
                    !!questionStore.averageDrivingDistance
                      ? `${DrivingDistanceSteps[stepIndex].output} ${Units.KM}`
                      : 'Bitte wählen'
                  }
                  description={`Ihr Mehrverbrauch pro Jahr liegt bei etwa ${store.calculationStore.consumEM} ${Units.KWH}.`}
                />
              ) : (
                <RangeSliderCar
                  steps={DrivingDistanceSteps}
                  stepDividedBy={20}
                  value={
                    questionStore.averageDrivingDistance !== '' ? stepIndex : initialDrivingDistance
                  }
                  setValue={questionStore.updateAverageDrivingDistance}
                  output={
                    !!questionStore.averageDrivingDistance
                      ? `${DrivingDistanceSteps[stepIndex].output} ${Units.KM}`
                      : 'Bitte wählen'
                  }
                  description={`Ihr Mehrverbrauch pro Jahr liegt bei etwa ${store.calculationStore.consumEM} ${Units.KWH}.`}
                />
              )}
            </Space>
          </>
        )}

        <Space mb={`${AppTheme.spacingSize.laptop.L}px`}>
          <Flex flexDirection='row' justifyContent='flex-start' alignItems='center'>
            <Typography
              type={AppTypography.QUESTION}
              fontWeight={300}
              display='inline'
              width='auto'>
              {'Welche Kapazität hat die Batterie Ihres E-Mobils?'}{' '}
            </Typography>
            <InfoBox
              open={isInfoBoxOpen[1]}
              setOpen={() => setIsInfoBoxOpen([false, true])}
              setHide={() => setIsInfoBoxOpen([false, false])}
              content={<CapacityInfobox />}
            />
          </Flex>

          <Space mt={`${AppTheme.spacingSize.laptop.S}px`}>
            <RangeSlider
              steps={BatteryCapacitySteps}
              stepDividedBy={10}
              value={questionStore.eMobilityCapacity !== '' ? capacityStepIndex : initialCapacity}
              setValue={questionStore.updateEmobilityCapacity}
              output={
                !!questionStore.eMobilityCapacity
                  ? `${BatteryCapacitySteps[capacityStepIndex].output} ${Units.KWH}`
                  : 'Bitte wählen'
              }
            />
          </Space>
        </Space>
      </>
    </Accordion>
  )
})

export default QuestionWallbox
