import Collapse from '@kunukn/react-collapse'
import { observer } from 'mobx-react-lite'
import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { Divider } from '../Divider'
import { Button } from '../Inputs'
import { IconState, SVG, SVGNames } from '../SVG'
import { Space } from '../Space'
import { AppTypography, Typography } from '../Typography'

interface AccordionProps {
  className?: string
  isOpen: boolean
  setIsOpen(isOpen: boolean, index?: number): void
  onSubmit?(isOpen: boolean): void
  title: string
  index?: number
  children?: ReactElement | ReactNode
  noteUnderTitle?: string
  showContinueBtn?: boolean
  isEdited?: boolean
  btnText: string
  isBlocked?: boolean
  btnDisabled?: boolean
}

export const Accordion: React.FC<AccordionProps> = observer(
  ({
    className,
    isOpen,
    title,
    children,
    showContinueBtn,
    setIsOpen,
    onSubmit,
    isEdited,
    btnText,
    isBlocked,
    btnDisabled
  }) => {
    // const handleClose = () => {
    //   // setExpand(!expand)
    //   if (onClose) {
    //     onClose(index)
    //   }
    // }

    const handleOpen = () => {
      setIsOpen(isOpen)
      // if (onOpen && (index !== null || index !== undefined)) {
      //   onOpen(index)
      // }
    }

    const handleSubmit = () => {
      if (onSubmit) {
        onSubmit(isOpen)
      }
    }

    return (
      <Space mb={isOpen ? `${AppTheme.spacingSize.laptop.XXL}px` : '0px'} className={className}>
        <AccordionContainer className='accordion'>
          <TitleContainer onClick={handleOpen}>
            <div>
              <Typography
                type={AppTypography.HEADLINE}
                color={isBlocked ? AppTheme.colors.grayB9 : AppTheme.colors.gray65}>
                {title}
              </Typography>
            </div>
            <SVG
              name={SVGNames.Edit}
              iconState={isEdited ? IconState.ACTIVE : IconState.DEACTIVATED}
            />
          </TitleContainer>
          <Space
            mb={AppTheme.spacingSize.laptop.M}
            mt={`${AppTheme.spacingSize.laptop.XXS}px`}
            height='auto'>
            <Divider />
          </Space>
          <Collapse isOpen={isOpen} overflowOnExpanded={true}>
            <CollapsibleContainer className='collapsible_content'>
              {children}
              {Boolean(showContinueBtn) && (
                <Button hasBorder={false} onClick={handleSubmit} disabled={btnDisabled}>
                  {btnText}
                </Button>
              )}
            </CollapsibleContainer>
          </Collapse>
        </AccordionContainer>
      </Space>
    )
  }
)

Accordion.defaultProps = {
  showContinueBtn: true
}

const AccordionContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  position: relative;
`

interface CollapseProps {
  expanded?: boolean
  height?: number
}

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const CollapsibleContainer = styled.div<CollapseProps>`
  width: 100%;
  height: auto;
  /* max-height: ${(props) => (props.expanded ? '500px' : '0px')};
  transition: all 0.4s ease; */
  overflow: visible;
`
export default Accordion
