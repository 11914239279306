import { SVGNames } from 'components/index'

import { Facility, SelectOptionTypeForInterested } from './questionInteractStates'
import { Units } from './units'

export const InterestedFacility: SelectOptionTypeForInterested[] = [
  {
    value: 'PV-Anlage',
    checked: false,
    svgName: SVGNames.PVAnlage,
    value_id: Facility.PVA,
    name: Facility.PVA,
    disabled: false
  },
  {
    value: 'Batteriespeicher',
    checked: false,
    svgName: SVGNames.BatteryStorage,
    value_id: Facility.BATTERTY,
    name: Facility.BATTERTY,
    disabled: true
  },
  {
    value: 'Wallbox',
    checked: false,
    svgName: SVGNames.Wallbox,
    value_id: Facility.WALLBOX,
    name: Facility.WALLBOX,
    disabled: false
  },
  {
    value: 'Wärmepumpe',
    checked: false,
    svgName: SVGNames.WaermePumpe,
    value_id: Facility.HEATPUMP,
    name: Facility.HEATPUMP,
    disabled: false
  }
]

export const RecommendedFacility: { [key: string]: string }[] = [
  {
    name: 'PV-Anlage',
    id: Facility.PVA,
    unit: Units.KWP,
    value: '9.90'
  },
  {
    name: 'Batteriespeicher',
    id: Facility.BATTERTY,
    unit: Units.KWH,
    value: '9.00'
  },
  {
    name: 'Wallbox',
    id: Facility.WALLBOX,
    unit: Units.KW,
    value: '13.20'
  },
  {
    name: 'Wärmepumpe',
    id: Facility.HEATPUMP,
    unit: Units.KW,
    value: '11.90'
  }
]
