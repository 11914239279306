import React from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

interface SpaceStyleProps {
  height?: string
  display?: 'inline-block' | 'block' | 'inline'
}

interface SpacingProps extends SpaceStyleProps {
  className?: string
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNode[]
}

export const Space = styled.div<SpacingProps & SpaceProps>`
  ${space}
  display: ${(props) => props.display};
  height: ${(props) => props.height};
`

export default Space
