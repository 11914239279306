import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Space } from '../Space'

interface ScrollContainerProps {
  ySpace: string[]
  xSpace: string[]
  height?: string
  heightX?: string
  margin?: string
  padding?: string
  children: ReactElement | ReactElement[]
}

export const ScrollContainer: React.FC<ScrollContainerProps> = ({
  children,
  xSpace,
  ySpace,
  height,
  heightX,
  margin,
  padding
}) => {
  return (
    <Space
      height={height}
      className='vertical'
      pt={ySpace![0]}
      pb={ySpace.length === 1 ? ySpace![0] : ySpace![1]}>
      <Scroll margin={margin} padding={padding}>
        <Space
          className='horizontal'
          height={heightX}
          pl={xSpace![0]}
          pr={xSpace.length === 1 ? xSpace![0] : xSpace![1]}>
          {children}
        </Space>
      </Scroll>
    </Space>
  )
}

const Scroll = styled.div<Partial<ScrollContainerProps>>`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: ${(props) => props.padding}; /*极为重要的两行代码 */
  margin: ${(props) => props.margin}; /* 具体值可以根据实际情况调整 */

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: ${(props) => props.theme.colors.grayD4};
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
  }
`

export default ScrollContainer
