/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-undef */
import {
  Accordion,
  Alert,
  AppTypography,
  QuestionTextField,
  RangeSlider,
  Space,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { AppTheme } from 'theme/index'
import { Question, Units } from 'utils/index'
import 'react-confirm-alert/src/react-confirm-alert.css'

interface QuestionAnnualPowerProps {
  index: number
  isLast: boolean
}

export const QuestionAnnualPower: React.FC<QuestionAnnualPowerProps> = observer(
  ({ index, isLast }) => {
    const store = useContext(AppStoreContext)
    const questionStore = store.questionStore

    const initialFamilySize = Math.floor((questionStore.familySizeSteps.length - 1) / 2)
    const familySizeIndex = questionStore.familySizeSteps.findIndex(
      (el: string) => el === questionStore.familySize
    )

    const handleOpen = () => {
      if (!questionStore.questionsStates[1].blocked) {
        questionStore.updateQuestionStateById(
          Question.ANNUALPOWER,
          'open',
          !questionStore.questionsStates[1].open
        )
      }
    }

    const handleClose = () => {
      // onSubmit()
      questionStore.handleSubmitAnnualPower()
      if (!questionStore.questionsStates[1].answered) {
        questionStore.updateQuestionStateById(Question.ANNUALPOWER, 'open', true)
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <Alert
                onClose={onClose}
                title='Bitte beantworten Sie die Frage'
                description='Nach der Beantwortung kommen Sie zur nächsten Frage.'
              />
            )
          }
        })
      } else {
        if (isLast && !store.uiStore.resultDeactivated) {
          store.uiStore.updateLeftSideWidth(35)
          store.uiStore.updateShowContact(true)
          store.calculateResult()
        } else {
          questionStore.openNextAccordion(index)
        }
        // questionStore.updateQuestionStateById('ANNUALPOWER', 'open', false)
      }
    }

    return (
      <Accordion
        className='question_annual_power'
        isBlocked={questionStore.questionsStates[1].blocked}
        isOpen={!questionStore.questionsStates[1].blocked && questionStore.questionsStates[1].open}
        setIsOpen={handleOpen}
        title='Stromverbrauch'
        index={1}
        btnText={isLast ? 'Angebot anzeigen' : 'weiter'}
        onSubmit={handleClose}
        isEdited={questionStore.questionsStates[1].answered}
        btnDisabled={isLast ? !store.questionStore.allAnswered : isLast}>
        <>
          <Typography type={AppTypography.QUESTION} fontWeight={300}>
            {'Wie viele Personen leben in Ihrem Haushalt?'}
          </Typography>
          <Space
            mt={`${AppTheme.spacingSize.laptop.S}px`}
            mb={`${AppTheme.spacingSize.laptop.M}px`}>
            <RangeSlider
              steps={questionStore.familySizeSteps}
              stepDividedBy={60}
              value={questionStore.familySize !== '' ? familySizeIndex : initialFamilySize}
              setValue={questionStore.updateFamilySize}
              output={
                !!questionStore.familySize
                  ? `${questionStore.familySizeSteps[familySizeIndex]} ${
                      familySizeIndex === 0 ? 'Person' : 'Personen'
                    }`
                  : 'Bitte wählen'
              }
            />
          </Space>
          <Space mb={`${AppTheme.spacingSize.laptop.L}px`}>
            <QuestionTextField
              hint={Units.KWH}
              question='Geschätzter Jahresstromverbrauch:'
              value={
                +questionStore.estimatedAnnualConsum === 0
                  ? ''
                  : +questionStore.estimatedAnnualConsum
              }
              name='PvPower'
              placeholder=''
              onInputChange={questionStore.updateEstimatedAnnualConsum}
            />
            <QuestionTextField
              hint={Units.CTKWH}
              question='Strompreis (brutto):'
              value={
                +questionStore.grossElectricityPrice === 0
                  ? ''
                  : +questionStore.grossElectricityPrice
              }
              name='PvPower'
              placeholder=''
              onInputChange={questionStore.updateGrossElectricityPrice}
            />
          </Space>
        </>
      </Accordion>
    )
  }
)

export default QuestionAnnualPower
