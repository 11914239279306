// export const units: {[key: string]: string}

export enum Units {
  EURO = '€',
  CTKWH = 'ct./kWh',
  KM = 'km',
  KW = 'kW',
  KWH = 'kWh',
  KWP = 'kWp',
  QM = 'm²'
}
