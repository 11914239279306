import { AppStoreContext, DeviceContext, DeviceType } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import {
  AppTypography,
  Button,
  Card,
  Divider,
  Flex,
  ScrollContainer,
  Space,
  SVG,
  SVGNames,
  Typography
} from '../../components'

import ResultAfterCalculation from './AfterCalculation'
import ResultBeforeCalculation from './BeforeCalculation'

interface ResultMobileProps {
  hidden?: boolean
  onShowResult(): void
}

export const ResultMobile: React.FC<ResultMobileProps> = observer(({ hidden, onShowResult }) => {
  const store = useContext(AppStoreContext)
  const uiStore = store.uiStore
  const questionStore = store.questionStore
  const { device } = useContext(DeviceContext)
  const isMobile = device === DeviceType.MOBILE

  const handleHideResult = () => {
    if (questionStore.allAnswered) {
      questionStore.questionsStates.forEach((item) =>
        questionStore.updateQuestionStateById(item.question, 'open', false)
      )
    }

    uiStore.showResultMobile(false)
  }

  return (
    <ResultMobileContainer hidden={hidden}>
      <Card>
        <Space
          className='result'
          height='100%'
          // pb={`${AppTheme.spacingSize.mobile.M}px`}
          pt={`${AppTheme.spacingSize.mobile.S + 2 * AppTheme.spacingSize.mobile.XXS}px`}>
          <Space px={`${AppTheme.spacingSize.mobile.S + AppTheme.spacingSize.mobile.M}px`}>
            <TitelContainer>
              <Typography type={AppTypography.HEADLINE}>{'Übersicht'}</Typography>
              <IconContainer
                hidden={hidden}
                onClick={handleHideResult}
                bgColor={AppTheme.colors.secondary}>
                <SVG name={SVGNames.ArrowDown} />
              </IconContainer>
            </TitelContainer>

            <Space
              mb={`${AppTheme.spacingSize.mobile.M}px`}
              mt={`${AppTheme.spacingSize.mobile.XXS}px`}
              height='auto'>
              <Divider />
            </Space>
          </Space>
          <ScrollContainer
            height='calc(100% - 80px)'
            ySpace={['0px', `${AppTheme.spacingSize.mobile.M}px`]}
            xSpace={[`${AppTheme.spacingSize.mobile.M}px`, `${AppTheme.spacingSize.mobile.M}px`]}>
            <>
              {questionStore.allAnswered ? (
                <ResultAfterCalculation />
              ) : (
                // <ResultAfterCalculation />
                <ResultBeforeCalculation onSubmit={onShowResult} />
              )}

              {questionStore.allAnswered &&
                uiStore.isResultMobileOpen &&
                !(isMobile && uiStore.resultDeactivated) && (
                  <Flex
                    justifyContent='flex-end'
                    style={{ width: '100%', marginTop: `${AppTheme.spacingSize.mobile.XXL}px` }}>
                    <Button hasBorder onClick={() => uiStore.triggerContactMobile(true)}>
                      ANGEBOT
                    </Button>
                  </Flex>
                )}
            </>
          </ScrollContainer>
        </Space>
      </Card>
    </ResultMobileContainer>
  )
})

const ResultMobileContainer = styled.div<Partial<ResultMobileProps>>`
  position: absolute;
  display: block;
  width: 100%;
  height: calc(100% - 44px);
  z-index: 1000;
  top: ${(props) => (props.hidden ? '100%' : '44px')};
  left: 0;
  transition: all 0.4s ease;
`

const TitelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
interface IconContainerProps {
  bgColor?: string
}
export const IconContainer = styled.div<IconContainerProps & Partial<ResultMobileProps>>`
  cursor: inherit;
  overflow: hidden;
  background-color: ${(props) => props.bgColor};
  display: block;
  width: 28px;
  height: 28px;
  position: relative;
  transition: all 0.3s ease;
  transform: ${(props) => (props.hidden ? 'rotate(0deg)' : 'rotate(180deg)')};
`
export default ResultMobile
