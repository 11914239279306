import { SelectOptionType } from 'components/index'

export interface IFormValues {
  customerNumber: string
  firstName: string
  lastName: string
  email: string
  tel: string
  street: string
  zip: string
  city: string
  comment: string
  additionalQuestion1: string
  additionalQuestion2: string
}

export const AppointmentDays: SelectOptionType[] = [
  // { value: 'Montag bis Freitag', checked: false, name: 'weekday' }
  { value: 'Montag', checked: false, name: 'monday' },
  { value: 'Dienstag', checked: false, name: 'tuesday' },
  { value: 'Mittwoch', checked: false, name: 'wednesday' },
  { value: 'Donnerstag', checked: false, name: 'thursday' },
  { value: 'Freitag', checked: false, name: 'friday' }
]

export const AppointmentDaytime: SelectOptionType[] = [
  { value: 'Vormittag', checked: false, name: 'morning' },
  { value: 'Nachmittag', checked: false, name: 'afternoon' }
  // { value: 'Abends', checked: false, name: 'evening' }
]

type ContactFormFieldType = {
  fieldName:
    | 'customerNumber'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'tel'
    | 'street'
    | 'zip'
    | 'city'
    | 'comment'
    | 'additionalQuestion1'
    | 'additionalQuestion2'
  placeholder: string
  required: boolean
}

export const ContactFormFields: ContactFormFieldType[] = [
  {
    fieldName: 'customerNumber',
    placeholder: 'Kundennummer',
    required: false
  },
  {
    fieldName: 'firstName',
    placeholder: 'Vorname',
    required: true
  },
  {
    fieldName: 'lastName',
    placeholder: 'Nachname',
    required: true
  },
  {
    fieldName: 'email',
    placeholder: 'Email',
    required: true
  },
  {
    fieldName: 'tel',
    placeholder: 'Telefon',
    required: false
  },
  {
    fieldName: 'street',
    placeholder: 'Strasse und Hausnummer',
    required: true
  }
]

export type ContactUserDataType = {
  customer: {
    [key: string]: string | boolean | { [key: string]: string }
    address: { [key: string]: string }
  }
  appointment: { [key: string]: string[] }
  interested: string[]
}
