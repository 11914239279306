export type QuestionStateType = {
  question: string
  open: boolean // window is open or closed
  hidden: boolean // window is hidden
  blocked: boolean // window can't be open
  answered: boolean
}

export enum Question {
  POSSESSION = 'POSSESSION',
  ANNUALPOWER = 'ANNUALPOWER',
  EMOBILITY = 'EMOBILITY',
  WALLBOX = 'WALLBOX',
  HEATPUMP = 'HEATPUMP'
}

export enum Facility {
  PVA = 'PVA',
  BATTERTY = 'BS',
  WALLBOX = 'WB',
  HEATPUMP = 'HP',
  NONE = 'NONE'
}

export type FacilityType = Facility.PVA | Facility.BATTERTY | Facility.WALLBOX | Facility.HEATPUMP

export interface SelectOptionTypeForInstalled {
  [key: string]: string | boolean | number | FacilityType | Facility.NONE | undefined
  value: string
  checked: boolean
  disabled: boolean
  svgName?: string
  cost?: string | number
  value_id?: FacilityType | Facility.NONE
  name: FacilityType | Facility.NONE
}

export interface SelectOptionTypeForInterested {
  [key: string]: string | boolean | number | FacilityType | Facility.NONE | undefined
  value: string
  checked: boolean
  disabled: boolean
  svgName?: string
  cost?: string | number
  value_id?: FacilityType
  name: FacilityType
}

export const QuestionInteractStates: QuestionStateType[] = [
  { question: Question.POSSESSION, open: true, hidden: false, blocked: false, answered: false },
  { question: Question.ANNUALPOWER, open: false, hidden: false, blocked: true, answered: false },
  { question: Question.EMOBILITY, open: false, hidden: true, blocked: true, answered: false },
  { question: Question.WALLBOX, open: false, hidden: true, blocked: true, answered: false },
  { question: Question.HEATPUMP, open: false, hidden: true, blocked: true, answered: false }
]

export interface ProductDescription {
  description: string
  linkText?: string
  url?: string
}

export const shownQuestions = (questions: QuestionStateType[]): QuestionStateType[] => {
  console.log(
    'questions: ',
    questions.filter((item) => item.hidden === false)
  )
  return questions.filter((item) => item.hidden === false)
}

export const questionIndex = (questions: QuestionStateType[], qId: string): number => {
  return questions.findIndex((item) => qId === item.question)
}

export const isQuestionHidden = (questions: QuestionStateType[], qId: string) => {
  return questions.find((item) => item.question === qId)?.hidden
}
