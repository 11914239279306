import React from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

import { AppTheme } from '../../../theme'

interface CheckboxProps extends CheckBoxContainerProps {
  name: string
  checked?: boolean
  setChecked(checked: boolean): void
  children?: string | React.ReactNode
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  name,
  setChecked,
  checked,
  children
}) => {
  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const handleLabelClick = () => {
    setChecked(!checked)
  }

  return (
    <RegularCheckboxContainer
      paddingY={AppTheme.spacingSize.laptop.M}
      className='checkbox'
      onClick={handleLabelClick}>
      <StyledCheckBox
        type='checkbox'
        name={name}
        onChange={(event) => handleChangeState(event)}
        checked={checked}
      />
      <StlyedLabel pl={`${AppTheme.spacingSize.laptop.XXS}px`}>{children}</StlyedLabel>
    </RegularCheckboxContainer>
  )
}

Checkbox.defaultProps = {
  checked: false
}

export default Checkbox

interface CheckBoxContainerProps {
  checked?: boolean
}

const RegularCheckboxContainer = styled.div<SpaceProps>`
  ${space}
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  position: relative;
  cursor: pointer;

  :hover {
    cursor: pointer;
  }
`

const StyledCheckBox = styled.input`
  /* display: none; */
  display: 'inline-box';
  cursor: pointer;
`

interface LabelProps {
  for?: string
}

const StlyedLabel = styled.label<SpaceProps & LabelProps>`
  ${space}
  display: inline;
  cursor: pointer;
`
