import { DeviceContext, DeviceType } from 'contexts/index'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { AppTheme } from 'theme/index'

import { AppTypography, Typography } from '../Typography'

interface QuestionTextFieldProps extends QuestionTextFieldStyleProps {
  value: string | number
  name: string
  question: string
  hint?: string
  isRequired?: boolean
  placeholder?: string
  hasError?: boolean
  onInputChange(value: string | number): void
  onBlur?(): void
  setValueFor?(key: string, value: string): void

  inputType?: 'text' | 'number'
}

interface QuestionTextFieldStyleProps {
  isInline?: boolean
  position?: string
  width?: string | number
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
}

export const QuestionTextField: React.FC<QuestionTextFieldProps> = (
  props: QuestionTextFieldProps
) => {
  const {
    value,
    name,
    question,
    hint,
    isRequired,
    placeholder,
    hasError,
    onInputChange,
    setValueFor,
    isInline,
    position,
    width,
    inputType
  } = props

  const { device } = useContext(DeviceContext)
  const isMobile = device === DeviceType.MOBILE

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onInputChange) {
      onInputChange(event.currentTarget.value)
    }

    if (!!setValueFor) {
      setValueFor(name, event.currentTarget.value)
    }
  }

  return (
    <TextFieldContainer
      position={position}
      width={isMobile ? '100%' : width}
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent={isMobile ? 'flex-start' : 'space-between'}
      flexDirection={isMobile ? 'column' : 'row'}>
      {isMobile ? (
        <>
          <StyledLabelMoible>
            <Typography type={AppTypography.QUESTION} fontWeight={300}>
              {question}
            </Typography>
          </StyledLabelMoible>
          <div style={{ width: '100%', position: 'relative' }}>
            <StyledTextFieldMobile
              marginY={`${AppTheme.spacingSize.laptop.XS}px`}
              name={name}
              onChange={(event) => handleInputChange(event)}
              paddingX={`${AppTheme.spacingSize.laptop.XS}px`}
              placeholder={isRequired ? placeholder + '*' : placeholder}
              isRequired={isRequired}
              hasError={hasError}
              value={value}
              isInline={false}
            />
            {!!hint && (
              <HintText>
                <Typography
                  type={AppTypography.QUESTION}
                  fontWeight={300}
                  color={AppTheme.colors.gray65}>
                  {hint}
                </Typography>
              </HintText>
            )}
          </div>
        </>
      ) : (
        <>
          <StyledLabel>
            <Typography type={AppTypography.QUESTION} fontWeight={300} display='inline-block'>
              {question}
            </Typography>
          </StyledLabel>

          <StyledTextField
            type={inputType}
            name={name}
            value={value}
            onChange={(event) => handleInputChange(event)}
            placeholder={isRequired ? placeholder + '*' : placeholder}
            isRequired={isRequired}
            hasError={hasError}
            isInline={isInline}
            paddingX={`${AppTheme.spacingSize.laptop.XS}px`}
            marginY={`${AppTheme.spacingSize.laptop.XS}px`}
          />
          {!!hint && (
            <HintText>
              <Typography
                type={AppTypography.QUESTION}
                fontWeight={300}
                color={AppTheme.colors.gray65}>
                {hint}
              </Typography>
            </HintText>
          )}
        </>
      )}
    </TextFieldContainer>
  )
}

QuestionTextField.defaultProps = {
  inputType: 'text'
}

const TextFieldContainer = styled.div<QuestionTextFieldStyleProps>`
  width: ${(props) => (props.width ? props.width : '45vw')};
  position: relative;
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.flexDirection};

  @media only screen and (max-width: 1536px) {
    width: ${(props) => (props.width ? props.width : '50vw')};
  }
`

const StyledLabel = styled.label`
  display: inline-block;
  margin-right: 2rem;
  @media only screen and (max-width: 1440px) {
    margin-right: 1rem;
    flex-shrink: 20;
  }
`

const StyledLabelMoible = styled.label`
  display: block;
`

const StyledTextField = styled.input<SpaceProps & Partial<QuestionTextFieldProps>>`
  ${space}
  display: inline-block;
  /* flex-grow: 3; */
  width: 400px;
  height: 40px;
  border: 1px solid
    ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grayD4)};
  font-weight: ${(props) => props.theme.fonts.weight.light};
  font-size: ${(props) => props.theme.fonts.fontsize.app.L}px;
  outline: none;
  color: ${(props) => props.theme.colors.gray65};

  :focus {
    outline: none;
  }

  ::placeholder {
    font-weight: lighter;
    color: ${(props) => props.theme.colors.grayB9};
    font-family: ${(props) => props.theme.fonts.family};
    font-size: ${(props) => props.theme.fonts.fontsize.app.L}px;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  @media only screen and (max-width: 1440px) {
    width: 300px;
  }

  @media only screen and (max-width: 1024px) {
    width: 250px;
  }
`

const StyledTextFieldMobile = styled.input<SpaceProps & Partial<QuestionTextFieldProps>>`
  ${space}
  display: inline-block;
  /* flex-grow: 3; */
  width: 100%;
  height: 40px;
  border: 1px solid
    ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grayD4)};
  font-weight: ${(props) => props.theme.fonts.weight.light};
  font-size: ${(props) => props.theme.fonts.fontsize.app.L}px;
  outline: none;
  color: ${(props) => props.theme.colors.gray65};

  :focus {
    outline: none;
    outline-width: 0;
  }

  ::placeholder {
    font-weight: lighter;
    color: ${(props) => props.theme.colors.grayB9};
    font-family: ${(props) => props.theme.fonts.family};
    font-size: ${(props) => props.theme.fonts.fontsize.app.L}px;
  }
`

const HintText = styled.div`
  display: inline;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
`

export default QuestionTextField
