import { AppTypography, Space, SVG, SVGNames, Typography } from 'components/index'
import { AppStoreContext, DeviceContext, DeviceType } from 'contexts/index'
import React, { useContext, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { AppTheme } from 'theme/index'
import { baseUploadsUrl } from 'utils/index'

interface EmailFeedbackProps {
  succeeded: boolean
}

export const EmailFeedback: React.FC<EmailFeedbackProps> = ({ succeeded }) => {
  const store = useContext(AppStoreContext)
  const phone = store.ciSettings?.settings_sw_text_sw_phone
  const email = store.ciSettings?.settings_sw_text_sw_email
  const { device } = useContext(DeviceContext)
  const isMobile = device === DeviceType.MOBILE
  const [feedbackText, setFeedbackText] = useState('')
  // const [logoURL, setLogoURL] = useState('')

  useEffect(() => {
    if (!!store) {
      setFeedbackText(
        !!store.pvSettings?.form?.send_success
          ? store.pvSettings?.form?.send_success
          : 'Ihre Daten wurden erfolgreich an uns übermittelt.'
      )
      // setLogoURL(baseUploadsUrl + store.ciSettings?.settings_sw_file_logo)
    }
  }, [baseUploadsUrl, store])

  return (
    <FeedbackContainer>
      <Space
        px={AppTheme.spacingSize.laptop.L}
        pt={`${AppTheme.spacingSize.laptop.S + AppTheme.spacingSize.laptop.XXS}px`}
        height='auto'>
        {/* <ImageContainer>
          <Image
            src={logoURL}
            display='block'
            maxWidth='inherit'
            width='100%'
            maxHeight='200px'
            style={{ alignSelf: 'flex-start', objectFit: 'contain' }}
          />
        </ImageContainer> */}
        {succeeded ? (
          <>
            <Space
              mt={`${AppTheme.spacingSize.laptop.L}px`}
              mb={`${AppTheme.spacingSize.laptop.XS}px`}>
              <Typography type={AppTypography.HEADLINE} color={AppTheme.colors.gray65}>
                {'Vielen Dank!'}
              </Typography>
            </Space>
            <Typography
              type={AppTypography.QUESTION}
              color={AppTheme.colors.gray65}
              fontWeight={300}>
              {feedbackText}
            </Typography>
          </>
        ) : (
          <>
            <Space
              mt={`${AppTheme.spacingSize.laptop.L}px`}
              mb={`${AppTheme.spacingSize.laptop.XS}px`}>
              <Typography type={AppTypography.HEADLINE} color={AppTheme.colors.red}>
                {'Oops, etwas ist schief gelaufen.'}
              </Typography>
            </Space>
            <Typography
              type={AppTypography.QUESTION}
              color={AppTheme.colors.gray65}
              lineHeight={30}
              fontWeight={300}>
              {`Bitte versuchen Sie es nochmal oder kontaktieren Sie uns unter ${phone} oder ${email}. \nVielen Dank!`}
            </Typography>
          </>
        )}

        <SVGContainer width={isMobile ? '100vw' : '35vw'}>
          <SVG name={SVGNames.House} />
        </SVGContainer>
      </Space>
    </FeedbackContainer>
  )
}

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1}
`

const FeedbackContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.black};
  animation: ${fadeIn} 1.5s ease 1;
`

interface SVGContainerProps {
  width: string
}

const SVGContainer = styled.div<SVGContainerProps>`
  position: absolute;
  width: ${(props) => props.width};
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

export default EmailFeedback
