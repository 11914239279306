/* eslint-disable react/display-name */
import { Alert, Divider, Flex, Progress, Space, SVG, SVGNames } from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useRef } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'
import { Question } from 'utils/index'

import QuestionAnnualPowerM from './QuestionAnnualPowerM'
import QuestionEmobilityM from './QuestionEmobilityM'
import QuestionHeatpumpM from './QuestionHeatpumpM'
import QuestionPossessionM from './QuestionPossessionM'
import QuestionWallboxM from './QuestionWallboxM'

import 'react-confirm-alert/src/react-confirm-alert.css'

export const QuestionsMobile: React.FC = observer(() => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore
  const uiStore = store.uiStore

  const openIndex = questionStore.questionsStates
    .filter((item) => !item.hidden)
    .findIndex((item) => item.open === true)
  const hiddenAllQuestion =
    questionStore.questionsStates.findIndex((item) => item.open === true) === -1

  const btnGroupRef = useRef<HTMLDivElement>(null)
  const btnHeight = btnGroupRef.current?.offsetHeight

  const handleClickNext = () => {
    const openIndex = questionStore.shownQuestions.findIndex((item) => item.open === true)
    const questionName = questionStore.questionsStates.find((item) => item.open === true)?.question

    console.log({ questionName, openIndex })

    switch (questionName) {
      case Question.POSSESSION:
        questionStore.handleSubmitQuestionPossession()
        break
      case Question.ANNUALPOWER:
        questionStore.handleSubmitAnnualPower(true, true)
        break
      case Question.EMOBILITY:
        questionStore.handleSubmitEmobility()
        break
      case Question.WALLBOX:
        questionStore.handleSubmitWallbox()
        break
      case Question.HEATPUMP:
        questionStore.handleSumbitHeatpump()
        break
      default:
        undefined
    }

    // questionStore.openNextAccordion(openIndex)

    const question = questionStore.shownQuestions[openIndex].question

    if (!questionStore.shownQuestions[openIndex].answered) {
      questionStore.updateQuestionStateById(question, 'open', true)

      if (question === 'HEATPUMP') {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <Alert
                onClose={onClose}
                title='Bitte beantworten Sie alle Fragen'
                description='Nach der Beantwortung kommen Sie zu den Ergebenisse und Angebote.'
              />
            )
          }
        })
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <Alert
                onClose={onClose}
                title='Bitte beantworten Sie die Frage'
                description='Nach der Beantwortung kommen Sie zur nächsten Frage.'
              />
            )
          }
        })
      }
    } else {
      // questionStore.updateQuestionStateById(question, 'open', false)
      questionStore.openNextAccordion(openIndex)
      if (questionStore.allAnswered) {
        uiStore.showResultMobile(true)
        store.calculateResult()
      }
    }
  }

  const handleClickShowResult = () => {
    if (questionStore.isInterestedNoneSelected) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert
              onClose={onClose}
              title='Bitte beantworten Sie die erste Frage'
              description='Nach der Beantwortung kommen Sie zu den empfohlenen Anlagen.'
            />
          )
        }
      })
    } else {
      uiStore.showResultMobile(true)
    }
  }

  return (
    <QuestionsContainer className='questions_mobile'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginLeft: `${AppTheme.spacingSize.mobile.S}px`,
          bottom: '0px',
          zIndex: 1000
        }}>
        <Progress
          length={questionStore.questionsStates.filter((item) => item.hidden === false).length}
          completeNumber={
            questionStore.questionsStates.filter((item) => item.answered === true).length
          }
        />
      </div>
      <div style={{ flexGrow: 1, position: 'relative' }}>
        <Space
          height='100%'
          px={`${AppTheme.spacingSize.mobile.L}px`}
          pt={`${AppTheme.spacingSize.mobile.S}px`}
          pb={`${AppTheme.spacingSize.mobile.M}px`}>
          <VisibleContainer className='visible_container'>
            <div
              className='transform_container'
              style={{
                height: 'auto',
                overflowY: 'hidden',
                transform: `translateY(-${
                  hiddenAllQuestion
                    ? 0
                    : 67 * (questionStore.allAnswered ? openIndex + 1 : openIndex)
                }px)`,
                transition: 'all 0.3s ease'
              }}>
              {questionStore.allAnswered && (
                <>
                  <Space
                    mb={`${AppTheme.spacingSize.mobile.S}px`}
                    mt={`${AppTheme.spacingSize.mobile.M}px`}
                    height='auto'>
                    <Flex flexDirection='row' justifyContent='flex-end'>
                      <TitleIconContainer
                        bgColor={AppTheme.colors.secondary}
                        onClick={() => uiStore.showResultMobile(true)}>
                        <SVG name={SVGNames.ArrowUp} />
                      </TitleIconContainer>
                    </Flex>
                  </Space>
                  <Divider />
                </>
              )}
              <>
                {questionStore.shownQuestions.map((question, index: number) => {
                  switch (question.question) {
                    case Question.POSSESSION:
                      return <QuestionPossessionM key={index} />
                    case Question.ANNUALPOWER:
                      return <QuestionAnnualPowerM key={index} />
                    case Question.EMOBILITY:
                      return <QuestionEmobilityM key={index} />
                    case Question.WALLBOX:
                      return <QuestionWallboxM key={index} />
                    case Question.HEATPUMP:
                      return <QuestionHeatpumpM key={index} />
                    default:
                      undefined
                  }
                })}
              </>
            </div>
          </VisibleContainer>

          <ButtonGroup ref={btnGroupRef} refHeight={btnHeight} hidden={hiddenAllQuestion}>
            <IconContainer
              className='icon-container__show-result'
              disabled={false}
              bgColor={AppTheme.colors.secondary}
              onClick={handleClickShowResult}>
              <SVG name={SVGNames.ShowResult} className='icon_show_result' />
            </IconContainer>
            <IconContainer
              className='icon-container__next'
              bgColor={AppTheme.colors.secondary}
              onClick={handleClickNext}>
              <SVG name={SVGNames.Next} className='icon_next' />
            </IconContainer>
          </ButtonGroup>
        </Space>
      </div>
    </QuestionsContainer>
  )
})

const VisibleContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`

const QuestionsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.gray65};
  display: flex;
`
interface BtnGroupStyleProps {
  refHeight?: number
  hidden?: boolean
}

const ButtonGroup = styled.div<BtnGroupStyleProps>`
  position: absolute;
  bottom: ${(props) => (props.hidden ? `-${props.refHeight}px` : '0px')};
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 50;
  padding: ${(props) => `${props.theme.spacingSize.mobile.M}px`};
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
`

interface IconContainerProps {
  bgColor: string
  disabled?: boolean
}
export const IconContainer = styled.div<IconContainerProps>`
  cursor: inherit;
  overflow: hidden;
  background-color: ${({ disabled, bgColor, theme }) => (disabled ? theme.colors.grayB9 : bgColor)};
  display: block;
  width: 54px;
  height: 54px;
  position: relative;
  float: right;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

export const TitleIconContainer = styled.div<IconContainerProps>`
  cursor: inherit;
  overflow: hidden;
  background-color: ${(props) => props.bgColor};
  display: block;
  width: 28px;
  height: 28px;
  position: relative;
  transition: all 0.3s ease;
  /* transform: rotate(180deg); */
  /* transform: ${(props) => (props.hidden ? 'rotate(0deg)' : 'rotate(180deg)')}; */
`

export default QuestionsMobile
