/* eslint-disable array-callback-return */
import { SelectOptionType } from 'components/index'
import DOMPurify from 'dompurify'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { AppTheme } from 'theme/index'
import {
  changeObjPropValueLoop,
  Facility,
  hasFacility,
  InterestedFacility,
  mapDataToNotifierSettings,
  mapDataToPVSettings,
  numberFormat,
  numberWithThousandSeparator,
  removeThousandSeparator,
  SelectOptionTypeForInterested,
  timeConvert
} from 'utils/index'
import { getBffURL } from 'utils/index'

import { API } from '../api/api'

import CalculationStore from './CalculationStore'
import QuestionStore from './QuestionStore'
import UIStore from './UIStore'

type ObjectWithString = { [key: string]: string }

class ApplicationStore {
  api: API
  questionStore: QuestionStore
  uiStore: UIStore
  calculationStore: CalculationStore
  ciSettings: any
  pvSettings: any
  notifierSettings: any
  pvEmailSettings: any
  pvWelcomeSettings: any
  appName: string = ''

  public customFont: string = ''

  emailPDFText: { intro: string; middle: string; outro: string } = {
    intro: '',
    middle: '',
    outro: ''
  }

  offeredFacility: SelectOptionTypeForInterested[] = []
  electricityConsumYear: number = 0

  pricePV: ObjectWithString = { facilityPower: '', price: '', monthlyRate: '' }
  priceBS: ObjectWithString = { facilityPower: '', price: '', monthlyRate: '' }
  priceWB: ObjectWithString = { capacity: '', facilityPower: '', smartPrice: '' }
  priceHP: ObjectWithString = {
    heatload: '',
    investmentCost: '',
    jaz: '',
    monthlyRate: ''
  }

  savings: ObjectWithString = {
    reducePotential: '',
    independencePotential: '',
    annualReducedEmissionCO2: ''
  }

  loadingTimeEM: string = ''

  private API_URL = getBffURL

  public constructor() {
    makeAutoObservable(this)
    this.api = new API()
    // this.loadPVSettings()
    this.questionStore = new QuestionStore(this)
    this.calculationStore = new CalculationStore(this)
    this.uiStore = new UIStore(this)
    this.loadCISettings()
    this.loadNotifierSettings()
    this.loadPVEmailSettings()
    this.loadPVWelcomeSettings()
  }

  loadCISettings() {
    return new Promise((resolve) => {
      this.api
        .fetchCISettings()
        .then((data: any) => {
          runInAction(() => {
            this.ciSettings = toJS(this.mapDataToCISettings(data))
            console.log('cisettings: ', toJS(this.ciSettings))
            this.updateThemeFromCiSettings(toJS(this.ciSettings))
          })

          resolve(toJS(this.ciSettings))
        })
        .catch((error) => console.log(error))
    })
  }

  loadPVSettings() {
    return new Promise((resolve) => {
      this.api
        .fetchPVSettings()
        .then((data: any) => {
          this.updatePVSettings(toJS(mapDataToPVSettings(data.settings)))
          // this.pvSettings = toJS(mapDataToPVSettings(data.settings))
          this.appName = data.settings.settings_sw_text_heat_tool_name.question
          console.log('pvSettings: ', toJS(this.pvSettings))
          this.offeredFacility = InterestedFacility.filter((item) =>
            this.pvSettings!.offer.includes(item.name)
          )
          this.deactivateResult()
          resolve(toJS(this.pvSettings))
        })
        .catch((error) => console.log(error))
    })
  }

  loadNotifierSettings() {
    return new Promise((resolve) => {
      this.api
        .fetchNotifierSettings()
        .then((data: any) => {
          this.notifierSettings = toJS(mapDataToNotifierSettings(data.settings))
          console.log('notifier settings: ', toJS(this.notifierSettings))
          resolve(toJS(this.notifierSettings))
        })
        .catch((error) => console.log(error))
    })
  }

  loadPVEmailSettings() {
    return new Promise((resolve) => {
      this.api
        .fetchPVEmailSettings()
        .then((data: any) => {
          this.pvEmailSettings = toJS(data.settings)
          console.log('email settings: ', toJS(this.pvEmailSettings))
          resolve(toJS(this.pvEmailSettings))
        })
        .catch((error) => console.log(error))
    })
  }

  loadPVWelcomeSettings() {
    return new Promise((resolve) => {
      this.api
        .fetchPVWelcomeSettings()
        .then((data: any) => {
          this.pvWelcomeSettings = toJS(data.settings)
          console.log('welcome settings: ', toJS(this.pvWelcomeSettings))
          resolve(toJS(this.pvWelcomeSettings))
        })
        .catch((error) => console.log(error))
    })
  }

  mapDataToCISettings(data: any) {
    const ciSettings: { [key: string]: string | number | boolean } = {}
    Object.keys(data).map((key) => (ciSettings[`${key}`] = data[`${key}`]))
    delete ciSettings.createdAt
    delete ciSettings.updatedAt
    delete ciSettings.__v
    return ciSettings
  }

  mapDataToInterestedServices = (): SelectOptionType[] | undefined => {
    if (this.pvSettings.form.cross_selling_text !== '') {
      const crossSellingProducts = this.pvSettings.form.cross_selling_text.split(', ')
      const services: SelectOptionType[] = []
      crossSellingProducts.map((item: string) =>
        services.push({ value: item, checked: false, name: item })
      )
      return services
    } else {
      return undefined
    }
  }

  mapDataToAvailableDays = (): SelectOptionType[] => {
    if (this.pvSettings.form.available_days !== '') {
      const availableDays = this.pvSettings.form.available_days.split(', ')
      const days: SelectOptionType[] = []
      availableDays.map((item: string) => days.push({ value: item, checked: false, name: item }))
      return days
    } else {
      return []
    }
  }

  mapDataToAvailableTime = (): SelectOptionType[] => {
    if (this.pvSettings.form.available_time !== '') {
      const availableTime = this.pvSettings.form.available_time.split(', ')
      const time: SelectOptionType[] = []
      availableTime.map((item: string) => time.push({ value: item, checked: false, name: item }))
      return time
    } else {
      return []
    }
  }

  updatePVSettings(data: any) {
    this.pvSettings = data
  }

  updateThemeFromCiSettings(ciSettings: any) {
    const Roboto = `, "Roboto", sans-serif`
    AppTheme.colors.primary = ciSettings.settings_sw_color_color1
    AppTheme.colors.secondary = ciSettings.settings_sw_color_color2

    if (
      ciSettings.settings_sw_text_font &&
      ciSettings.settings_sw_text_font !== '' &&
      ciSettings.settings_sw_text_font.trim().length > 0
    ) {
      AppTheme.fonts.family = ciSettings.settings_sw_text_font + Roboto
      AppTheme.fonts.customFont = ciSettings.settings_sw_text_font
      this.customFont = AppTheme.fonts.customFont
      // console.log('setting fonts in theme:', AppTheme.fonts.family, AppTheme.fonts.customFont)
    } else {
      AppTheme.fonts.family = 'Roboto, sans-serif'
    }

    if (ciSettings.settings_sw_file_upload_font && ciSettings.settings_sw_file_upload_font !== '') {
      AppTheme.fonts.fontURL = this.API_URL + 'api/font/' + ciSettings.settings_sw_file_upload_font
      // console.log('fonturl:', AppTheme.fonts.fontURL)
      // process.env.APP_UPLOADS_BASE_URL + ciSettings.settings_sw_file_upload_font
    }
  }

  updateElectricityConsumYear(value: number) {
    this.electricityConsumYear = value
  }

  updatePricePV(dataPV: ObjectWithString) {
    this.pricePV = toJS(dataPV)
  }

  updatePriceBS(dataBS: ObjectWithString) {
    this.priceBS = toJS(dataBS)
  }

  updatePriceWB(dataWB: ObjectWithString) {
    this.priceWB = toJS(dataWB)
  }

  updatePriceHP(dataHP: ObjectWithString) {
    this.priceHP = toJS(dataHP)
  }

  updateSavings(data: ObjectWithString) {
    this.savings = data
  }

  updateLoadingTimeEM(value: string) {
    this.loadingTimeEM = timeConvert(+value)
  }

  updateEmailPDFText(text: any) {
    this.emailPDFText = text
  }

  calculateHPConsum(inputData: { [key: string]: string | number }) {
    const data = {
      priceClass: toJS(this.pvSettings.heatpump.price),
      ...toJS(inputData),
      withTww: this.pvSettings.heatpump.drinking_hotwater_supply_included,
      heatingHours: this.pvSettings.heatpump.average_heating_hours
    }

    this.api
      .calculateHPConsum(data)
      .then((result) => {
        this.updateElectricityConsumYear(Math.round(result?.data.result))
        this.updatePriceHP(toJS(result?.data.price))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  calculateResult() {
    const sendToServer: sendToServerDataType = {
      clientParams: {
        userInput: {},
        clientCalculate: {},
        rentModel: {
          purchaseRentPV: this.calculationStore.pvShowPurchasePrice,
          purchaseRentBS: this.calculationStore.bsShowPurchasePrice,
          purchaseRentHP: this.calculationStore.hpShowPurchasePrice
        }
      },
      serverParams: {}
    }

    sendToServer.clientParams.userInput = this.questionStore.userInputForCalculation
    sendToServer.clientParams.clientCalculate.additionConsumEM = removeThousandSeparator(
      this.calculationStore.consumEM
    )

    const serverData: { [key: string]: any } = {}
    serverData.additionalElecConsumPerKM =
      this.pvSettings.electricity.additional_electricity_consum_per_km
    serverData.facilityHP = toJS(this.pvSettings.heatpump.price) // object + array
    serverData.averageHeatingHourHP = this.pvSettings.heatpump.average_heating_hours
    serverData.annualYieldPV = this.pvSettings.pvanlage.annual_yield
    serverData.facilityPV = toJS(this.pvSettings.pvanlage.price_pv_facility) // object + array
    serverData.facilityBS = toJS(this.pvSettings.battery.price_battery)
    serverData.facilityWB = toJS(this.pvSettings.wallbox.price_wallbox)
    serverData.facilityHP = toJS(this.pvSettings.heatpump.price)
    serverData.operationCostInInvestitionPerYearPV =
      this.pvSettings.pvanlage.operating_costs_per_year
    serverData.purchaseRentPV = toJS(this.pvSettings.pvanlage.purchase_rent)
    serverData.purchaseRentBS = toJS(this.pvSettings.battery.purchase_rent)
    serverData.purchaseRentHP = toJS(this.pvSettings.heatpump.purchase_rent)
    serverData.powerPerModulePV = +this.pvSettings.pvanlage.power_per_module
    serverData.areaPerKwpPV = +this.pvSettings.pvanlage.area_per_kwp
    serverData.selfConsumRate = toJS(this.pvSettings.evq)
    serverData.evq = +this.questionStore.evq * 0.01
    serverData.priceIncrementInPercent =
      +this.pvSettings.electricity.annual_price_increasement / 100

    sendToServer.serverParams = serverData

    console.log('client params', sendToServer.clientParams)
    console.log('server params', sendToServer.serverParams)

    this.api
      .calculate(sendToServer)
      .then((result) => {
        this.updatePricePV(toJS(result.pricePV))
        this.updatePriceBS(toJS(result.priceBS))
        this.updatePriceWB(toJS(result.priceWB))
        this.updatePriceHP(toJS(result.priceHP))
        this.updateSavings(toJS(result.saving))
        this.updateLoadingTimeEM(toJS(result.loadingTimeEM))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  calculateWallbox() {
    const params = {
      eMobilityCapacity: this.questionStore.eMobilityCapacity,
      prices: this.pvSettings.wallbox.price_wallbox
    }

    this.api
      .calculateWBPrice(params)
      .then((result) => {
        this.updatePriceWB(toJS(result?.data))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  calculateBattery() {
    const params = {
      estimatedAnnualConsum: this.questionStore.estimatedAnnualConsum,
      additionConsumEM: removeThousandSeparator(this.calculationStore.consumEM),
      priceClassHP: toJS(this.pvSettings.heatpump.price),
      priceClassBS: toJS(this.pvSettings.battery.price_battery),
      buildingData: toJS(this.questionStore.heatpumAnswer),
      withTww: this.pvSettings.heatpump.drinking_hotwater_supply_included,
      heatingHours: this.pvSettings.heatpump.average_heating_hours
    }

    this.api
      .calculateBSPrice(params)
      .then((result) => {
        this.updatePriceBS(toJS(result?.data))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  calculatePV() {
    const params = {
      estimatedAnnualConsum: this.questionStore.estimatedAnnualConsum,
      additionConsumEM: removeThousandSeparator(this.calculationStore.consumEM),
      priceClassHP: toJS(this.pvSettings.heatpump.price),
      priceClassPV: toJS(this.pvSettings.pvanlage.price_pv_facility),
      buildingData: toJS(this.questionStore.heatpumAnswer),
      withTww: this.pvSettings.heatpump.drinking_hotwater_supply_included,
      heatingHours: this.pvSettings.heatpump.average_heating_hours,
      annualYieldPV: this.pvSettings.pvanlage.annual_yield
    }

    this.api
      .calculatePVPrice(params)
      .then((result) => {
        this.updatePricePV(toJS(result?.data))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  deactivateResult() {
    const lowConsum: boolean =
      Boolean(this.pvSettings?.hint?.min_power_consum) &&
      (isNaN(this.calculationStore.electricityConsumYear) ||
        this.calculationStore.electricityConsumYear < this.pvSettings?.hint?.min_power_consum)

    this.uiStore.updateResultDeactivated(this.pvSettings?.hint?.result_disabled || lowConsum)

    if (this.pvSettings?.hint?.result_disabled) {
      this.uiStore.updateDeactivedResultHint(this.pvSettings?.hint?.hint__disabled_result)
    } else {
      this.uiStore.updateDeactivedResultHint(this.pvSettings?.hint?.hint__low_power_consum)
    }
  }

  sendEmail(userInput: any) {
    runInAction(() => {
      this.uiStore.emailState = ''
    })
    const formatedSaving = changeObjPropValueLoop(toJS(this.savings), true)
    const interestedFacility = this.questionStore.possessionAnswer.interestedFacility

    const calculatedData = {
      priceClassPV: changeObjPropValueLoop(toJS(this.pricePV)),
      priceClassBS: changeObjPropValueLoop(toJS(this.priceBS)),
      priceClassWB: changeObjPropValueLoop(toJS(this.priceWB)),
      priceClassHP: changeObjPropValueLoop(toJS(this.priceHP)),
      pricePV: this.calculationStore.pvShowPurchasePrice
        ? `${this.pvSettings.form.text_show_before_price}${numberFormat(
            this.pricePV.price,
            true
          )} €`
        : `${this.pvSettings.form.text_show_before_price}${numberFormat(
            this.pricePV.monthlyRate,
            true
          )} €/Monat`,
      priceHP: this.calculationStore.hpShowPurchasePrice
        ? `${this.pvSettings.form.text_show_before_price}${numberFormat(
            this.priceHP.investmentCost,
            true
          )} €`
        : `${this.pvSettings.form.text_show_before_price}${numberFormat(
            this.priceHP.monthlyRate,
            true
          )} €/Monat`,
      priceBS: this.calculationStore.bsShowPurchasePrice
        ? `${this.pvSettings.form.text_show_before_price}${numberFormat(
            this.priceBS.price,
            true
          )} €`
        : `${this.pvSettings.form.text_show_before_price}${numberFormat(
            this.priceBS.monthlyRate,
            true
          )} €/Monat`,
      priceWB: `${this.pvSettings.form.text_show_before_price}${numberFormat(
        this.priceWB.smartPrice,
        true
      )} €`,
      calculatedFacility: {
        isPVCalculated: hasFacility(Facility.PVA, interestedFacility),
        isBSCalculated: hasFacility(Facility.BATTERTY, interestedFacility),
        isWBCalculated: hasFacility(Facility.WALLBOX, interestedFacility),
        isHPCalculated: hasFacility(Facility.HEATPUMP, interestedFacility),
        isPVInstalled: this.questionStore.isPVInstalled
      },

      saving: {
        ...formatedSaving,
        evq: numberFormat(Math.round(+this.savings.evq * 100), true),
        elecConsum: numberWithThousandSeparator(Math.round(+this.savings.elecConsum)),
        restElectricity: numberWithThousandSeparator(Math.round(+this.savings.restElectricity)),
        isPossibleSavingOver20YearsPositive: +this.savings.possibleSavingOver20Years > 0,
        electricityCoveredByExistingSystem: numberWithThousandSeparator(
          Math.round(+this.savings.elecConsum) - Math.round(+this.savings.restElectricity)
        )
      },
      cost: {
        additionalConsumEM: numberFormat(removeThousandSeparator(this.calculationStore.consumEM)),
        additionalConsumHP: numberFormat(this.electricityConsumYear)
      },
      showPrices: this.pvSettings.showPrice,
      rentModel: {
        purchaseRentPV: this.calculationStore.pvShowPurchasePrice,
        purchaseRentBS: this.calculationStore.bsShowPurchasePrice,
        purchaseRentHP: this.calculationStore.hpShowPurchasePrice
      },
      loadingTimeEM: this.loadingTimeEM
    }
    const request = { data: calculatedData, ...userInput }
    const ciSetting = toJS(this.ciSettings)
    const emailSetting = toJS(this.pvEmailSettings)
    const htmlMessage = DOMPurify.sanitize(
      emailSetting?.settings_pvemail_text_message_text?.question ?? ''
    )
    const htmlSignature = DOMPurify.sanitize(
      emailSetting?.settings_pvemail_text_signature_text?.question ?? ''
    )

    console.log('data for email: ', calculatedData)

    request.meta = {
      sw: {
        company: ciSetting.settings_sw_text_name,
        email: ciSetting.settings_sw_text_sw_email,
        phone: ciSetting.settings_sw_text_sw_phone,
        contact: ciSetting.settings_sw_text_sw_contact_person,
        address: {
          street: ciSetting.settings_sw_text_sw_street,
          zip: ciSetting.settings_sw_text_sw_zip,
          city: ciSetting.settings_sw_text_sw_city
        },
        ci: {
          logo: process.env.APP_UPLOADS_BASE_URL + ciSetting.settings_sw_file_logo,
          primary: ciSetting.settings_sw_color_color1,
          secondary: ciSetting.settings_sw_color_color2
        },
        template: {
          subject: emailSetting?.settings_pvemail_text_subject_text?.question,
          greeting: emailSetting?.settings_pvemail_text_greeting_text?.question,
          message: htmlMessage,
          signature: htmlSignature
        }
      },
      product: {
        name: this.pvSettings.form.heat_tool_name,
        description_intro: this.emailPDFText.intro,
        description_middle: this.emailPDFText.middle,
        description_outro: this.emailPDFText.outro
      }
    }
    console.log('send email request data: ', request, JSON.stringify(request))
    this.api.sendEmailRequest(request).then((result) => {
      console.log('Email Feedback: ', { status: result?.status, statusText: result?.statusText })
      if (result?.status === 200 || result?.statusText === 'OK') {
        runInAction(() => {
          this.uiStore.emailState = 'succeeded'
        })
      } else {
        runInAction(() => {
          this.uiStore.emailState = 'failed'
        })
      }
    })
    setTimeout(() => {
      if (this.uiStore.emailState !== 'succeeded')
        runInAction(() => {
          this.uiStore.emailState = 'failed'
        })
    }, 20000)
  }
}

type sendToServerDataType = {
  clientParams: {
    userInput: { [key: string]: any }
    clientCalculate: { [key: string]: any }
    rentModel: { [key: string]: any }
  }
  serverParams: { [key: string]: any }
}

export const AppStore = new ApplicationStore()
export default AppStore
