import React from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'
import { SelectOptionTypeForInstalled, SelectOptionTypeForInterested } from 'utils/index'

import { Space } from '../Space'

import SelectItem from './SelectItem'

type allSelectionInArray =
  | SelectOptionType[]
  | SelectOptionTypeForInterested[]
  | SelectOptionTypeForInstalled[]

type allSelection = SelectOptionType | SelectOptionTypeForInterested | SelectOptionTypeForInstalled

interface SelectProps extends SelectStyleProps {
  isMultiple: boolean
  selectedOptions: allSelectionInArray
  setSelectedOptions?(selectionOptions: allSelectionInArray): void
  setValueFor?(name: string, value: string[] | string): void
  name?: string
  disabledItemName?: string[]
}

interface SelectStyleProps {
  itemWidth?: string
  isInline?: boolean
  itemGapX?: string
  itemGapY?: string
  justifyContent?: string
  useGrid?: boolean
  gridGap?: string
}
export type SelectOptionType = {
  value: string
  checked: boolean
  svgName?: string
  cost?: string | number
  value_id?: string
  name: string
}

export const Select: React.FC<SelectProps> = ({
  isMultiple,
  itemWidth,
  isInline,
  selectedOptions,
  setSelectedOptions,
  setValueFor,
  name,
  itemGapX,
  itemGapY,
  disabledItemName,
  justifyContent,
  useGrid,
  gridGap
}) => {
  const handleSelectOption = (optionVaule: string) => {
    const updatedOpts: allSelectionInArray = selectedOptions.map((option: allSelection) => {
      if (option.value === optionVaule) {
        return { ...option, checked: isMultiple ? !option.checked : true }
      } else if (!isMultiple) {
        return { ...option, checked: false }
      }
      return option
    }) as allSelectionInArray

    if (setSelectedOptions) {
      setSelectedOptions(updatedOpts)
    }

    const answerValue = isMultiple
      ? formatedAnswerOfMulti(updatedOpts)
      : updatedOpts.find((item: SelectOptionType) => item.checked === true)?.value

    if (!!name && setValueFor) {
      setValueFor(name, !!answerValue ? answerValue : '')
    }
  }

  const formatedAnswerOfMulti = (options: allSelectionInArray) => {
    const answer: string[] = []
    options.forEach((item: allSelection) => {
      if (item.checked === true) {
        answer.push(item.value)
      }
    })
    return answer
  }

  return (
    <Space mt={`${AppTheme.spacingSize.laptop.S}px`}>
      {useGrid ? (
        <OptionsContainerGrid name={name} className='select' gridGap={gridGap}>
          {selectedOptions.map((option: SelectOptionType, index: number) => (
            <SelectItem
              name={option.name}
              disabled={
                !!disabledItemName &&
                disabledItemName.length > 0 &&
                disabledItemName.includes(option.name)
              }
              key={index}
              itemWidth={itemWidth}
              isInline={isInline}
              value={option.value}
              selected={option.checked}
              onSelect={() => handleSelectOption(option.value)}
              svgName={option.svgName}
              isMultiple={isMultiple}
            />
          ))}
        </OptionsContainerGrid>
      ) : (
        <OptionsContainer
          name={name}
          className='select'
          itemGapX={itemGapX}
          itemGapY={itemGapY}
          justifyContent={justifyContent}>
          {selectedOptions.map((option: SelectOptionType, index: number) => (
            <SelectItem
              name={option.name}
              disabled={
                !!disabledItemName &&
                disabledItemName.length > 0 &&
                disabledItemName.includes(option.name)
              }
              key={index}
              itemWidth={itemWidth}
              isInline={isInline}
              value={option.value}
              selected={option.checked}
              onSelect={() => handleSelectOption(option.value)}
              svgName={option.svgName}
              isMultiple={isMultiple}
            />
          ))}
        </OptionsContainer>
      )}
    </Space>
  )
}

Select.defaultProps = {
  isMultiple: false,
  justifyContent: 'flex-start'
}

const OptionsContainer = styled.div<Partial<SelectProps>>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
  align-items: baseline;
  width: 100%;
  height: auto;
  box-sizing: border-box;

  & > * {
    margin-bottom: ${(props) => (props.itemGapY ? props.itemGapY : '12px')};
    margin-right: ${(props) => (props.itemGapX ? props.itemGapX : '12px')};
  }
`

const OptionsContainerGrid = styled.div<Partial<SelectProps>>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${(props) => props.gridGap};
  justify-items: center;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  & > * {
    margin-bottom: ${(props) => (props.itemGapY ? props.itemGapY : '12px')};
    margin-right: ${(props) => (props.itemGapX ? props.itemGapX : '12px')};
  }
`

export default Select
