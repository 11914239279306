import { makeAutoObservable, toJS } from 'mobx'

import AppStore from './ApplicationStore'

class UIStore {
  appStore: typeof AppStore
  showContact: boolean = false
  emailState: 'succeeded' | 'failed' | '' = ''
  isContactMobileVisible: boolean = false
  isResultMobileOpen: boolean = false
  leftSideWidth: 65 | 35 = 65
  resultDeactivated: boolean = false
  deactivedResultHint: string = ''

  fieldPowerPVAHasError: boolean = false

  resultInfoBoxState: { open: boolean }[] = [
    { open: false },
    { open: false },
    { open: false },
    { open: false },
    { open: false }
  ]

  contactFormErrors: {
    firstname: string
    lastname: string
    email: string
    street: string
    zip: string
    city: string
  } = {
    firstname: '',
    lastname: '',
    email: '',
    street: '',
    zip: '',
    city: ''
  }

  public constructor(appStore: typeof AppStore) {
    makeAutoObservable(this)
    this.appStore = appStore
    this.emailState = ''
  }

  updateShowContact = (state: boolean) => {
    this.showContact = state
  }

  updateLeftSideWidth = (width: 65 | 35) => {
    this.leftSideWidth = width
  }

  triggerContactMobile = (state: boolean) => {
    this.isContactMobileVisible = state
    console.log(' isContactOpen: ', this.isContactMobileVisible)
  }

  showResultMobile = (state: boolean) => {
    this.isResultMobileOpen = state
  }

  updateResultInfoBoxState = (index: number, newState: boolean) => {
    const newStates = toJS(this.resultInfoBoxState).map((item, i) => {
      if (newState) {
        i === index ? (item.open = true) : (item.open = false)
      } else {
        item.open = false
      }
      return item
    })

    this.resultInfoBoxState = toJS(newStates)
  }

  updateContactFormErrors = (
    name: 'firstname' | 'lastname' | 'email' | 'street' | 'zip' | 'city',
    value: string
  ) => {
    const data = this.contactFormErrors
    data[name] = value
    this.contactFormErrors = data
  }

  updateFieldPowerPVAHasError = (hasError: boolean) => {
    this.fieldPowerPVAHasError = hasError
  }

  updateEmailState = (state: 'succeeded' | 'failed' | '') => {
    this.emailState = state
  }

  updateResultDeactivated = (v: boolean) => {
    this.resultDeactivated = v
  }

  updateDeactivedResultHint = (content: string) => {
    this.deactivedResultHint = content
  }
}

export default UIStore
