import {
  AppTypography,
  Flex,
  IconState,
  InfoBox,
  SVG,
  SVGNames,
  SwitchButton,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import { readableColor } from 'polished'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'
import { Facility, FacilityType, numberFormat } from 'utils/index'

export const ResultTable: React.FC = observer(() => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore
  const uiStore = store.uiStore
  const calculationStore = store.calculationStore
  const textBeforeResult = store.pvSettings?.form?.text_show_before_price
  const showPricePV = store.pvSettings?.showPrice?.show_price_pv
  const showPriceBS = store.pvSettings?.showPrice?.show_price_bs
  const showPriceWB = store.pvSettings?.showPrice?.show_price_wb
  const showPriceHP = store.pvSettings?.showPrice?.show_price_hp
  const PVRentModel: boolean = store.pvSettings?.pvanlage?.purchase_rent?.rentModel
  const PVPurchaseModel: boolean = store.pvSettings?.pvanlage?.purchase_rent?.purchaseModel
  const HPRentModel: boolean = store.pvSettings?.heatpump?.purchase_rent?.rentModel
  const HPPurchaseModel: boolean = store.pvSettings?.heatpump?.purchase_rent?.purchaseModel
  const BSRentModel: boolean = store.pvSettings?.battery.purchase_rent?.rentModel
  const BSPurchaseModel: boolean = store.pvSettings?.battery.purchase_rent?.purchaseModel

  const [isPVLeased, setIsPVLeased] = useState(
    (PVRentModel && !PVPurchaseModel) ||
      (PVRentModel &&
        PVPurchaseModel &&
        store.pvSettings?.pvanlage?.purchase_rent?.standardSelect === 'rent')
  )
  const [isBSLeased, setIsBSLeased] = useState(
    (BSRentModel && !BSPurchaseModel) ||
      (BSRentModel &&
        BSPurchaseModel &&
        store.pvSettings?.battery?.purchase_rent?.standardSelect === 'rent')
  )
  const [isHPLeased, setIsHPLeased] = useState(
    (HPRentModel && !HPPurchaseModel) ||
      (HPRentModel &&
        HPPurchaseModel &&
        store.pvSettings?.heatpump?.purchase_rent?.standardSelect === 'rent')
  )

  const disabledPV: [string, string] = [!PVRentModel ? 'Pacht' : '', !PVPurchaseModel ? 'Kauf' : '']
  const disabledHP: [string, string] = [!HPRentModel ? 'Pacht' : '', !HPPurchaseModel ? 'Kauf' : '']
  const disabledBS: [string, string] = [!BSRentModel ? 'Pacht' : '', !BSPurchaseModel ? 'Kauf' : '']

  const hasFacility = (facilityName: FacilityType): boolean =>
    questionStore.possessionAnswer.interestedFacility.includes(facilityName)

  const changePVSwitsch = () => {
    if (PVRentModel && PVPurchaseModel) {
      setIsPVLeased(!isPVLeased)
      calculationStore.updatePvShowPurchasePrice(isPVLeased)
      store.calculateResult()
    }
  }

  const changeHPSwitsch = () => {
    if (HPRentModel && HPPurchaseModel) {
      setIsHPLeased(!isHPLeased)
      calculationStore.updateHpShowPurchasePrice(isHPLeased)
      store.calculateResult()
    }
  }

  const changeBSSwitch = () => {
    if (BSRentModel && BSPurchaseModel) {
      setIsBSLeased(!isBSLeased)
      calculationStore.updateBsShowPurchasePrice(isBSLeased)
      store.calculateResult()
    }
  }

  return (
    <>
      <TitleContainer>
        <Typography
          type={AppTypography.RESULT}
          color={readableColor(
            AppTheme.colors.secondary,
            AppTheme.colors.black,
            AppTheme.colors.white
          )}
          fontWeight='bold'>
          {store.pvSettings?.form?.heat_tool_claim}
        </Typography>
      </TitleContainer>

      <StyledTable>
        <StyledTableHead>
          <HeadColumnL>
            <Flex
              flexDirection='row'
              justifyContent='flex-end'
              alignItems='center'
              style={{ height: '2.6rem' }}>
              <Typography
                type={AppTypography.RESULT}
                fontWeight={300}
                display='inline'
                width='auto'>
                Ihre neue Anlage
              </Typography>
            </Flex>
          </HeadColumnL>
          <HeadColumnR>
            <Flex
              flexDirection='row'
              justifyContent='flex-end'
              alignItems='center'
              style={{ height: '2.6rem' }}>
              <Typography
                type={AppTypography.RESULT}
                color={readableColor(
                  AppTheme.colors.secondary,
                  AppTheme.colors.black,
                  AppTheme.colors.white
                )}
                fontWeight={300}
                display='inline'
                width='auto'>
                {store.appName}
              </Typography>
            </Flex>
          </HeadColumnR>
        </StyledTableHead>

        <StyledTableBody>
          <StyledColumnL hasBorder>
            {hasFacility(Facility.PVA) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-start'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography
                  type={AppTypography.RESULT}
                  fontWeight={300}
                  display='inline'
                  width='auto'>
                  {`PV-Anlage mit ${numberFormat(store.pricePV?.facilityPower as string)} kWp `}
                </Typography>
                <InfoBox
                  open={uiStore.resultInfoBoxState[0].open}
                  setOpen={() => uiStore.updateResultInfoBoxState(0, true)}
                  setHide={() => uiStore.updateResultInfoBoxState(0, false)}
                  content={
                    <>
                      Das entspricht in der Regel {store.savings?.requiredPVModules} Modulen und{' '}
                      {numberFormat(+store.savings?.requiredRoofArea)} m<sup>2</sup> Dachfläche.
                    </>
                  }
                />
              </Flex>
            )}

            {hasFacility(Facility.BATTERTY) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-start'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography type={AppTypography.RESULT} fontWeight={300}>
                  {`Batteriespeicher mit ${numberFormat(
                    !!store.priceBS.facilityPower && store.priceBS.facilityPower !== '0'
                      ? store.priceBS.facilityPower
                      : store.pvSettings.battery.price_battery.prices[0].facilityPower
                  )} kWh`}
                </Typography>
              </Flex>
            )}

            {hasFacility(Facility.WALLBOX) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-start'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography
                  type={AppTypography.RESULT}
                  fontWeight={300}
                  display='inline'
                  width='auto'>
                  {`Wallbox mit ${numberFormat(store.priceWB.facilityPower)} kW `}
                </Typography>
                <InfoBox
                  open={uiStore.resultInfoBoxState[1].open}
                  setOpen={() => uiStore.updateResultInfoBoxState(1, true)}
                  setHide={() => uiStore.updateResultInfoBoxState(1, false)}
                  content={
                    <>
                      <p>{`Ladezeit bei ${numberFormat(store.priceWB.facilityPower)} kW ca ${
                        store.loadingTimeEM
                      }h*`}</p>
                      <br />
                      <Typography type={AppTypography.INFOBOX}>
                        *kann durch die Ladeleistung des Autos limitiert werden
                      </Typography>
                    </>
                  }
                />
              </Flex>
            )}

            {hasFacility(Facility.HEATPUMP) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-start'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography type={AppTypography.RESULT} fontWeight={300}>
                  {`Wärmepumpe mit ${numberFormat(store.priceHP.heatload)} kW`}
                </Typography>
              </Flex>
            )}
          </StyledColumnL>

          <StyledColumnM hasBorder>
            {hasFacility(Facility.PVA) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <SwitchButton
                  switchText={['Pacht', 'Kauf']}
                  isLeftSelected={isPVLeased}
                  setIsLeftSelected={changePVSwitsch}
                  disabled={disabledPV}
                />
              </Flex>
            )}

            {hasFacility(Facility.BATTERTY) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <SwitchButton
                  switchText={['Pacht', 'Kauf']}
                  isLeftSelected={isBSLeased}
                  setIsLeftSelected={changeBSSwitch}
                  disabled={disabledBS}
                />
              </Flex>
            )}

            {hasFacility(Facility.WALLBOX) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography
                  type={AppTypography.RESULT}
                  fontWeight={300}
                  display='inline'
                  width='auto'>
                  Kaufpreis einmalig
                </Typography>
              </Flex>
            )}

            {hasFacility(Facility.HEATPUMP) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <SwitchButton
                  switchText={['Pacht', 'Kauf']}
                  isLeftSelected={isHPLeased}
                  setIsLeftSelected={changeHPSwitsch}
                  disabled={disabledHP}
                />
              </Flex>
            )}
          </StyledColumnM>

          <StyledColumnR
            hasBorder
            roundEdge={!(questionStore.isPVInstalled || questionStore.isPVInterested)}>
            {hasFacility(Facility.PVA) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                {showPricePV ? (
                  <Typography
                    type={AppTypography.RESULT}
                    fontWeight={500}
                    color={readableColor(
                      AppTheme.colors.secondary,
                      AppTheme.colors.black,
                      AppTheme.colors.white
                    )}
                    display='inline'
                    width='auto'>
                    {!isPVLeased
                      ? `${textBeforeResult}${numberFormat(store.pricePV.price, true)} €`
                      : `${textBeforeResult}${numberFormat(
                          store.pricePV.monthlyRate,
                          true
                        )} €/Monat`}
                  </Typography>
                ) : (
                  <SVG
                    name={SVGNames.PVAnlage}
                    iconState={IconState.ACTIVE}
                    style={{ transform: 'scale(0.6)' }}
                  />
                )}
              </Flex>
            )}

            {hasFacility(Facility.BATTERTY) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                {showPriceBS ? (
                  <Typography
                    type={AppTypography.RESULT}
                    fontWeight={500}
                    color={readableColor(
                      AppTheme.colors.secondary,
                      AppTheme.colors.black,
                      AppTheme.colors.white
                    )}
                    display='inline'
                    width='auto'>
                    {!isBSLeased
                      ? `${textBeforeResult}${numberFormat(store.priceBS.price, true)} €`
                      : `${textBeforeResult}${numberFormat(
                          store.priceBS.monthlyRate,
                          true
                        )} €/Monat`}
                  </Typography>
                ) : (
                  <SVG
                    name={SVGNames.BatteryStorage}
                    iconState={IconState.ACTIVE}
                    style={{ transform: 'scale(0.6)' }}
                  />
                )}
              </Flex>
            )}

            {hasFacility(Facility.WALLBOX) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                {showPriceWB ? (
                  <Typography
                    type={AppTypography.RESULT}
                    fontWeight={500}
                    color={readableColor(
                      AppTheme.colors.secondary,
                      AppTheme.colors.black,
                      AppTheme.colors.white
                    )}
                    display='inline'
                    width='auto'>
                    {`${textBeforeResult}${numberFormat(store.priceWB.smartPrice, true)} €`}
                  </Typography>
                ) : (
                  <SVG
                    name={SVGNames.Wallbox}
                    iconState={IconState.ACTIVE}
                    style={{ transform: 'scale(0.6)' }}
                  />
                )}
              </Flex>
            )}

            {hasFacility(Facility.HEATPUMP) && (
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                {showPriceHP ? (
                  <Typography
                    type={AppTypography.RESULT}
                    fontWeight={500}
                    color={readableColor(
                      AppTheme.colors.secondary,
                      AppTheme.colors.black,
                      AppTheme.colors.white
                    )}
                    display='inline'
                    width='auto'>
                    {!isHPLeased
                      ? `${textBeforeResult}${numberFormat(store.priceHP.investmentCost, true)} €`
                      : `${textBeforeResult}${numberFormat(
                          store.priceHP.monthlyRate,
                          true
                        )} €/Monat`}
                  </Typography>
                ) : (
                  <SVG
                    name={SVGNames.WaermePumpe}
                    iconState={IconState.ACTIVE}
                    style={{ transform: 'scale(0.6)' }}
                  />
                )}
              </Flex>
            )}
          </StyledColumnR>
        </StyledTableBody>

        {(questionStore.isPVInstalled || questionStore.isPVInterested) && (
          <StyledTableBody alignItem='flex-start'>
            <StyledColumnL>
              <Flex
                flexDirection='row'
                justifyContent='flex-start'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography
                  type={AppTypography.RESULT}
                  fontWeight={500}
                  display='inline'
                  width='auto'>
                  {store.pvSettings?.form?.heat_tool_claim}
                </Typography>
              </Flex>
            </StyledColumnL>

            <StyledColumnM>
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography
                  type={AppTypography.RESULT}
                  fontWeight={500}
                  display='inline'
                  width='auto'>
                  {'Ihr Einsparpotenzial '}
                </Typography>
                <InfoBox
                  open={uiStore.resultInfoBoxState[2].open}
                  setOpen={() => uiStore.updateResultInfoBoxState(2, true)}
                  setHide={() => uiStore.updateResultInfoBoxState(2, false)}
                  content={
                    'Das Einsparpotenzial ergibt sich aus möglichen Einsparungen durch Nutzung des selbsterzeugten Stroms und der Vergütung durch Einspeisung von Strom ins Netz über die nächsten 20 Jahre.'
                  }
                />
              </Flex>
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography
                  type={AppTypography.RESULT}
                  fontWeight={500}
                  display='inline'
                  width='auto'>
                  {'Ihr Unabhängigkeitspotenzial '}
                </Typography>
                <InfoBox
                  open={uiStore.resultInfoBoxState[3]?.open}
                  setOpen={() => uiStore.updateResultInfoBoxState(3, true)}
                  setHide={() => uiStore.updateResultInfoBoxState(3, false)}
                  content={
                    'Das Unabhängigkeitspotenzial gibt den möglichen Anteil des selbstproduzierten Solarstroms an Ihrem jährlichen Stromverbrauch an.'
                  }
                />
              </Flex>
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography
                  type={AppTypography.RESULT}
                  fontWeight={500}
                  display='inline'
                  width='auto'>
                  <>
                    Ihre möglichen CO<sub>2</sub>-Einsparung{' '}
                  </>
                </Typography>
                <InfoBox
                  open={uiStore.resultInfoBoxState[4]?.open}
                  setOpen={() => uiStore.updateResultInfoBoxState(4, true)}
                  setHide={() => uiStore.updateResultInfoBoxState(4, false)}
                  content={
                    <>
                      Die Einsparung von CO<sub>2</sub> ergibt sich durch die Abweichung des CO
                      <sub>2</sub>- Ausstoßes des Solarstroms gegenüber des durchschnittlichen
                      Ausstoßes von Strom aus dem Netz.
                    </>
                  }
                />
              </Flex>
            </StyledColumnM>

            <StyledColumnR roundEdge>
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography
                  type={AppTypography.RESULT}
                  fontWeight={500}
                  color={readableColor(
                    AppTheme.colors.secondary,
                    AppTheme.colors.black,
                    AppTheme.colors.white
                  )}
                  display='inline'
                  width='auto'>
                  {`${numberFormat(Math.round(+store.savings?.reducePotential))} €`}
                </Typography>
              </Flex>
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography
                  type={AppTypography.RESULT}
                  fontWeight={500}
                  color={readableColor(
                    AppTheme.colors.secondary,
                    AppTheme.colors.black,
                    AppTheme.colors.white
                  )}
                  display='inline'
                  width='auto'>
                  {`${Math.round(+store.savings?.independencePotential)} %`}
                </Typography>
              </Flex>
              <Flex
                flexDirection='row'
                justifyContent='flex-end'
                alignItems='center'
                style={{ height: '2.6rem' }}>
                <Typography
                  type={AppTypography.RESULT}
                  fontWeight={500}
                  color={readableColor(
                    AppTheme.colors.secondary,
                    AppTheme.colors.black,
                    AppTheme.colors.white
                  )}
                  display='inline'
                  width='auto'>
                  <>
                    {`${numberFormat(store.savings?.annualReducedEmissionCO2, true)}`} t CO
                    <sub>2</sub>
                    /Jahr
                  </>
                </Typography>
              </Flex>
            </StyledColumnR>
          </StyledTableBody>
        )}
      </StyledTable>
    </>
  )
})

interface TableStyleProps {
  hasBorder?: boolean
  alignItem?: string
  roundEdge?: boolean
}

const TitleContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: ${(props) => props.theme.spacingSize.laptop.XS}px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

const StyledTable = styled.div`
  display: table;
  width: calc(100% + 1px);
`

const StyledTableHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const StyledTableBody = styled.div<TableStyleProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: ${(props) => (props.alignItem ? props.alignItem : 'center')};
  width: 100%;
`

const HeadColumnL = styled.div`
  width: 65%;
  padding-right: 1rem;
`
const HeadColumnR = styled.div`
  width: 35%;
  background-color: ${(props) => props.theme.colors.secondary};
  border-top: 1px solid ${(props) => props.theme.colors.white};
  padding-right: 1rem;
`

const StyledColumnL = styled.div<TableStyleProps>`
  width: 35%;
  border-top: ${(props) => (props.hasBorder ? `1px solid ${props.theme.colors.grayD4}` : 'none')};
  border-bottom: ${(props) =>
    props.hasBorder ? `1px solid ${props.theme.colors.grayD4}` : 'none'};
`
const StyledColumnM = styled.div<TableStyleProps>`
  width: 30%;
  padding-right: 1rem;
  border-top: ${(props) => (props.hasBorder ? `1px solid ${props.theme.colors.grayD4}` : 'none')};
  border-bottom: ${(props) =>
    props.hasBorder ? `1px solid ${props.theme.colors.grayD4}` : 'none'};
`
const StyledColumnR = styled.div<TableStyleProps>`
  width: 35%;
  background-color: ${(props) => props.theme.colors.secondary};
  border-top: ${(props) => (props.hasBorder ? '1px solid white' : 'none')};
  border-bottom: ${(props) => (props.hasBorder ? '1px solid white' : 'none')};
  border-bottom-right-radius: ${(props) => (props.roundEdge ? '5px' : '0px')};
  padding-right: 1rem;
`

export default ResultTable
