import React from 'react'
import { AppTheme } from 'theme/index'

import IconArrowUp from './assets/arrow-up.svg'
import BatteryStorageActive from './assets/batteriespeicher_active.svg'
import BatteryStorageDeactivated from './assets/batteriespeicher_deactivated.svg'
import BatteryStorageSelected from './assets/batteriespeicher_selected.svg'
import EinfamilienHausActive from './assets/einfamilienhaus_active.svg'
import EinfamilienHausDeactivated from './assets/einfamilienhaus_deactivated.svg'
import EinfamilienHausSelected from './assets/einfamilienhaus_selected.svg'
import IconExit from './assets/exit.svg'
import GraphicHouse from './assets/house_grafik.svg'
import IconEditActive from './assets/icon_edit_active.svg'
import IconEditMute from './assets/icon_edit_mute.svg'
import IconInfo from './assets/icon_info.svg'
import NoneActive from './assets/keine_active.svg'
import NoneDeactivated from './assets/keine_deactivated.svg'
import NoneSelected from './assets/keine_selected.svg'
import MehrfamilienHausActive from './assets/mehrfamilienhaus_active.svg'
import MehrfamilienHausDeactivated from './assets/mehrfamilienhaus_deactivated.svg'
import MehrfamilienHausSelected from './assets/mehrfamilienhaus_selected.svg'
import PVAnlageActive from './assets/pvanlage_active.svg'
import PVAnlageDeactivated from './assets/pvanlage_deactivated.svg'
import PVAnlageSelected from './assets/pvanlage_selected.svg'
import ReihenHausActive from './assets/reihenhaus_active.svg'
import ReihenHausDeactivated from './assets/reihenhaus_deactivated.svg'
import ReihenHausSelected from './assets/reihenhaus_selected.svg'
import WaermePumpeActive from './assets/waermepumpe_active.svg'
import WaermePumpeDeactivated from './assets/waermepumpe_deactivated.svg'
import WaermePumpeSelected from './assets/waermepumpe_selected.svg'
import WallboxActive from './assets/wallbox_active.svg'
import WallboxDeactivated from './assets/wallbox_deactivated.svg'
import WallboxSelected from './assets/wallbox_selected.svg'
import WohnungActive from './assets/wohnung_active.svg'
import WohnungDeactivated from './assets/wohnung_deactivated.svg'
import WohnungSelected from './assets/wohnung_selected.svg'

export enum IconState {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  SELECTED = 'selected'
}

export type IconStateType = IconState.ACTIVE | IconState.DEACTIVATED | IconState.SELECTED

export const BatteryStorage = ({ ...props }): JSX.Element => {
  switch (props.state) {
    case IconState.DEACTIVATED:
      return <BatteryStorageDeactivated {...props} />

    case IconState.SELECTED:
      return <BatteryStorageSelected fill={`${AppTheme.colors.secondary}`} {...props} />

    case IconState.ACTIVE:
    default:
      return <BatteryStorageActive {...props} />
  }
}

export const EinfamilienHaus = ({ ...props }): JSX.Element => {
  switch (props.state) {
    case IconState.DEACTIVATED:
      return <EinfamilienHausDeactivated />

    case IconState.SELECTED:
      return <EinfamilienHausSelected fill={`${AppTheme.colors.secondary}`} />

    case IconState.ACTIVE:
    default:
      return <EinfamilienHausActive />
  }
}

export const None = ({ ...props }): JSX.Element => {
  switch (props.state) {
    case IconState.DEACTIVATED:
      return <NoneDeactivated />

    case IconState.SELECTED:
      return <NoneSelected fill={`${AppTheme.colors.secondary}`} />

    case IconState.ACTIVE:
    default:
      return <NoneActive />
  }
}

export const MehrfamilienHaus = ({ ...props }): JSX.Element => {
  switch (props.state) {
    case IconState.DEACTIVATED:
      return <MehrfamilienHausDeactivated />

    case IconState.SELECTED:
      return <MehrfamilienHausSelected fill={`${AppTheme.colors.secondary}`} />

    case IconState.ACTIVE:
    default:
      return <MehrfamilienHausActive />
  }
}

export const PVAnlage = ({ ...props }): JSX.Element => {
  switch (props.state) {
    case IconState.DEACTIVATED:
      return <PVAnlageDeactivated {...props} />

    case IconState.SELECTED:
      return <PVAnlageSelected fill={`${AppTheme.colors.secondary}`} {...props} />

    case IconState.ACTIVE:
    default:
      return <PVAnlageActive {...props} />
  }
}

export const ReihenHaus = ({ ...props }): JSX.Element => {
  switch (props.state) {
    case IconState.DEACTIVATED:
      return <ReihenHausDeactivated {...props} />

    case IconState.SELECTED:
      return <ReihenHausSelected fill={`${AppTheme.colors.secondary}`} {...props} />

    case IconState.ACTIVE:
    default:
      return <ReihenHausActive {...props} />
  }
}

export const WaermePumpe = ({ ...props }): JSX.Element => {
  switch (props.state) {
    case IconState.DEACTIVATED:
      return <WaermePumpeDeactivated {...props} />

    case IconState.SELECTED:
      return <WaermePumpeSelected fill={`${AppTheme.colors.secondary}`} {...props} />

    case IconState.ACTIVE:
    default:
      return <WaermePumpeActive {...props} />
  }
}

export const Wallbox = ({ ...props }): JSX.Element => {
  switch (props.state) {
    case IconState.DEACTIVATED:
      return <WallboxDeactivated {...props} />

    case IconState.SELECTED:
      return <WallboxSelected fill={`${AppTheme.colors.secondary}`} {...props} />

    case IconState.ACTIVE:
    default:
      return <WallboxActive {...props} />
  }
}

export const Wohnung = ({ ...props }): JSX.Element => {
  switch (props.state) {
    case IconState.DEACTIVATED:
      return <WohnungDeactivated />

    case IconState.SELECTED:
      return <WohnungSelected fill={`${AppTheme.colors.secondary}`} />

    case IconState.ACTIVE:
    default:
      return <WohnungActive />
  }
}

export const Edit = ({ ...props }): JSX.Element => {
  switch (props.state) {
    case IconState.ACTIVE:
      return <IconEditActive />

    case IconState.DEACTIVATED:
    default:
      return <IconEditMute />
  }
}

export const Info = ({ ...props }): JSX.Element => {
  return <IconInfo {...props} />
}

export const ArrowUp = ({ ...props }): JSX.Element => {
  return <IconArrowUp {...props} />
}

export const ArrowDown = ({ ...props }): JSX.Element => {
  return <IconArrowUp {...props} />
}

export const Exit = ({ ...props }): JSX.Element => {
  return <IconExit {...props} />
}

export const House = ({ ...props }): JSX.Element => {
  return <GraphicHouse {...props} />
}

export const ShowResult = (): JSX.Element => {
  return (
    <svg viewBox='0 0 54 54' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Button_direktzumAngebot' fill='#FFFFFF'>
          <path
            d='M0,0 L54,0 L54,54 L0,54 L0,0 Z M27,2 C13.1928813,2 2,13.1928813 2,27 C2,40.8071187 13.1928813,52 27,52 C40.8071187,52 52,40.8071187 52,27 C52,13.1928813 40.8071187,2 27,2 Z'
            id='Combined-Shape'></path>
          <path
            d='M27,6 C38.5979797,6 48,15.4020203 48,27 C48,38.5979797 38.5979797,48 27,48 C15.4020203,48 6,38.5979797 6,27 C6,15.4020203 15.4020203,6 27,6 Z M32,19 C32.553,19 33,18.553 33,18 C33,17.447 32.553,17 32,17 L22,17 C21.447,17 21,17.447 21,18 C21,18.553 21.447,19 22,19 L32,19 Z M33,22 C33,21.447 32.553,21 32,21 L22,21 C21.447,21 21,21.447 21,22 C21,22.553 21.447,23 22,23 L32,23 C32.553,23 33,22.553 33,22 Z M33,26 C33,25.447 32.553,25 32,25 L22,25 C21.447,25 21,25.447 21,26 C21,26.553 21.447,27 22,27 L32,27 C32.553,27 33,26.553 33,26 Z M27,36 C27,35.447 26.553,35 26,35 L22,35 C21.447,35 21,35.447 21,36 C21,36.553 21.447,37 22,37 L26,37 C26.553,37 27,36.553 27,36 Z M41,19.2676 C40.044,18.7156 38.82,19.0426 38.268,19.9996 L31.768,31.2576 L35.232,33.2576 L41.732,21.9996 C42.284,21.0426 41.957,19.8206 41,19.2676 Z M35,35.6602 L35,38.8002 C35,38.9102 34.91,39.0002 34.8,39.0002 L19.2,39.0002 C19.09,39.0002 19,38.9102 19,38.8002 L19,15.2002 C19,15.0902 19.09,15.0002 19.2,15.0002 L34.8,15.0002 C34.91,15.0002 35,15.0902 35,15.2002 L35,23.6592 L37,20.1952 L37,15.0002 C37,13.8952 36.104,13.0002 35,13.0002 L19,13.0002 C17.896,13.0002 17,13.8952 17,15.0002 L17,39.0002 C17,40.1042 17.896,41.0002 19,41.0002 L35,41.0002 C36.104,41.0002 37,40.1042 37,39.0002 L37,32.1972 L35,35.6602 Z M31,36.5879 L34.732,34.1239 L31.268,32.1239 L31,36.5879 Z M29,30 C29,29.447 28.553,29 28,29 L22,29 C21.447,29 21,29.447 21,30 C21,30.553 21.447,31 22,31 L28,31 C28.553,31 29,30.553 29,30 Z'
            id='Combined-Shape'></path>
        </g>
      </g>
    </svg>
  )
}

export const Next = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg viewBox='0 0 54 54' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='button-go-on'>
        <g id='Button-Weiter-Export'>
          <path
            d='M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42C32.598 42 42 32.598 42 21ZM21.4142 27.3848L25.8995 22.8995L11 22.8995C9.89543 22.8995 9 22.0041 9 20.8995C9 19.7949 9.89543 18.8995 11 18.8995L25.8995 18.8995L21.4142 14.4142C20.6332 13.6332 20.6332 12.3668 21.4142 11.5858C22.1953 10.8047 23.4616 10.8047 24.2426 11.5858L31.3137 18.6569C31.4155 18.7586 31.5039 18.8686 31.5792 18.9846C32.4013 19.233 33 19.9963 33 20.8995C33 21.8027 32.4013 22.566 31.5792 22.8144C31.5039 22.9304 31.4155 23.0404 31.3137 23.1421L24.2426 30.2132C23.4616 30.9942 22.1953 30.9942 21.4142 30.2132C20.6332 29.4322 20.6332 28.1658 21.4142 27.3848Z'
            transform='translate(6 6)'
            id='Button-Weiter'
            fill='#FFFFFF'
            fillRule='evenodd'
            stroke='none'
          />
          <path
            d='M54 0L0 0L0 54L54 54L54 0ZM2 27C2 13.1929 13.1929 2 27 2C40.8071 2 52 13.1929 52 27C52 40.8071 40.8071 52 27 52C13.1929 52 2 40.8071 2 27Z'
            id='Combined-Shape'
            fill='#FFFFFF'
            fillRule='evenodd'
            stroke='none'
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg viewBox='0 0 54 54' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='button-go-on'>
        <g id='Button-Weiter-Export'>
          <path
            d='M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42C32.598 42 42 32.598 42 21ZM21.4142 27.3848L25.8995 22.8995L11 22.8995C9.89543 22.8995 9 22.0041 9 20.8995C9 19.7949 9.89543 18.8995 11 18.8995L25.8995 18.8995L21.4142 14.4142C20.6332 13.6332 20.6332 12.3668 21.4142 11.5858C22.1953 10.8047 23.4616 10.8047 24.2426 11.5858L31.3137 18.6569C31.4155 18.7586 31.5039 18.8686 31.5792 18.9846C32.4013 19.233 33 19.9963 33 20.8995C33 21.8027 32.4013 22.566 31.5792 22.8144C31.5039 22.9304 31.4155 23.0404 31.3137 23.1421L24.2426 30.2132C23.4616 30.9942 22.1953 30.9942 21.4142 30.2132C20.6332 29.4322 20.6332 28.1658 21.4142 27.3848Z'
            transform='translate(6 6)'
            id='Button-Weiter'
            fill='#FFFFFF'
            fillRule='evenodd'
            stroke='none'
          />
          <path
            d='M54 0L0 0L0 54L54 54L54 0ZM2 27C2 13.1929 13.1929 2 27 2C40.8071 2 52 13.1929 52 27C52 40.8071 40.8071 52 27 52C13.1929 52 2 40.8071 2 27Z'
            id='Combined-Shape'
            fill='#FFFFFF'
            fillRule='evenodd'
            stroke='none'
          />
        </g>
      </g>
    </svg>
  )
}

export const SVGType = {
  BatteryStorage,
  EinfamilienHaus,
  None,
  MehrfamilienHaus,
  PVAnlage,
  ReihenHaus,
  WaermePumpe,
  Wallbox,
  Wohnung,
  Info,
  ArrowUp,
  ArrowDown,
  Exit,
  Edit,
  House,
  ShowResult,
  Next
}

export enum SVGNames {
  BatteryStorage = 'BatteryStorage',
  EinfamilienHaus = 'EinfamilienHaus',
  None = 'None',
  MehrfamilienHaus = 'MehrfamilienHaus',
  PVAnlage = 'PVAnlage',
  ReihenHaus = 'ReihenHaus',
  WaermePumpe = 'WaermePumpe',
  Wallbox = 'Wallbox',
  Wohnung = 'Wohnung',
  Info = 'Info',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Exit = 'Exit',
  Edit = 'Edit',
  House = 'House',
  ShowResult = 'ShowResult',
  Next = 'Next'
}
