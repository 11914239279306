import { SelectOptionType, SVGNames } from 'components/index'

export const BuildingTypes: SelectOptionType[] = [
  {
    value: 'Einfamilienhaus',
    checked: false,
    svgName: SVGNames.EinfamilienHaus,
    value_id: 'EFH',
    name: 'EFH'
  },
  {
    value: 'Mehrfamilienhaus',
    checked: false,
    svgName: SVGNames.MehrfamilienHaus,
    value_id: 'MFH',
    name: 'MFH'
  },
  {
    value: 'Reihenhaus',
    checked: false,
    svgName: SVGNames.ReihenHaus,
    value_id: 'RH',
    name: 'RH'
  },
  {
    value: 'Wohnung',
    checked: false,
    svgName: SVGNames.Wohnung,
    value_id: 'WHG',
    name: 'WHG'
  }
]
