import {
  AccordionMobile,
  AppTypography,
  QuestionTextField,
  RangeSlider,
  Space,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { AppTheme } from 'theme/index'
import { Units } from 'utils/index'

export const QuestionAnnualPowerM: React.FC = observer(() => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore

  const initialFamilySize = Math.floor((questionStore.familySizeSteps.length - 1) / 2)
  const familySizeIndex = questionStore.familySizeSteps.findIndex(
    (el: string) => el === questionStore.familySize
  )

  const handleOpen = () => {
    if (!questionStore.questionsStates[1].blocked) {
      questionStore.updateQuestionStateById(
        'ANNUALPOWER',
        'open',
        !questionStore.questionsStates[1].open
      )
    }
  }

  return (
    <AccordionMobile
      className='question_annual_power_mobile'
      index={1}
      title='Stromverbrauch'
      isBlocked={questionStore.questionsStates[1].blocked}
      isOpen={!questionStore.questionsStates[1].blocked && questionStore.questionsStates[1].open}
      setIsOpen={handleOpen}
      isEdited={questionStore.questionsStates[1].answered}>
      <>
        <Typography type={AppTypography.QUESTION} fontWeight={300}>
          {'Wie viele Personen leben in Ihrem Haushalt?'}
        </Typography>
        <Space mt={`${AppTheme.spacingSize.mobile.S}px`} mb={`${AppTheme.spacingSize.mobile.M}px`}>
          <RangeSlider
            steps={questionStore.familySizeSteps}
            stepDividedBy={60}
            value={questionStore.familySize !== '' ? familySizeIndex : initialFamilySize}
            setValue={questionStore.updateFamilySize}
            output={
              !!questionStore.familySize
                ? `${questionStore.familySizeSteps[familySizeIndex]} ${
                    familySizeIndex === 0 ? 'Person' : 'Personen'
                  }`
                : 'Bitte wählen'
            }
          />
        </Space>
        <Space mb={`${AppTheme.spacingSize.mobile.XXL}px`}>
          <QuestionTextField
            hint={Units.KWH}
            question='Geschätzter Jahresstromverbrauch:'
            value={
              +questionStore.estimatedAnnualConsum === 0 ? '' : +questionStore.estimatedAnnualConsum
            }
            name='PvPower'
            placeholder=''
            onInputChange={questionStore.updateEstimatedAnnualConsum}
          />
          <QuestionTextField
            hint={Units.CTKWH}
            question='Strompreis (brutto):'
            value={
              +questionStore.grossElectricityPrice === 0 ? '' : +questionStore.grossElectricityPrice
            }
            name='PvPower'
            placeholder=''
            onInputChange={questionStore.updateGrossElectricityPrice}
          />
        </Space>
      </>
    </AccordionMobile>
  )
})

export default QuestionAnnualPowerM
