import React, { ReactElement } from 'react'
import styled from 'styled-components'

interface LinkProps extends LinkStyleProps {
  children: string | ReactElement
  href: string
  target?: string
}

interface LinkStyleProps {
  fontSize?: string
}

export const Link: React.FC<LinkProps> = ({ children, href, target, fontSize }) => {
  return (
    <StyledLink href={href} target={target} fontSize={fontSize}>
      {children}
    </StyledLink>
  )
}

const StyledLink = styled.a<LinkStyleProps>`
  font-family: ${(props) => props.theme.fonts.family};
  font-weight: 300;
  font-size: ${(props) => props.fontSize};
  color: inherit;
  &:visited {
    color: ${(props) => props.theme.colors.gray65};
  }
  &:hover {
    color: ${(props) => props.theme.colors.gray65};
  }
`

export default Link
