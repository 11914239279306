import { readableColor } from 'polished'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { Flex } from '../Flex'
import { IconState, SVG } from '../SVG'
import { Space } from '../Space'
import { AppTypography, Typography } from '../Typography'

interface SelectItemProps extends SelectItemStyleProps {
  name?: string
  selected?: boolean
  svgName?: string
  value: string
  onSelect?(): void
  isMultiple?: boolean
  disabled?: boolean
}

interface SelectItemStyleProps {
  itemWidth?: string
  isInline?: boolean
}

export const SelectItem: React.FC<SelectItemProps> = ({
  name,
  selected,
  svgName,
  value,
  onSelect,
  itemWidth,
  isInline,
  isMultiple,
  disabled
}) => {
  const [isSelected, setIsSelected] = useState(selected)

  useEffect(() => {
    setIsSelected(selected)
  }, [selected])

  const handleChangeCheckState = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMultiple) {
      setIsSelected(event.target.checked)
    } else {
      setIsSelected(true)
    }
    if (onSelect) {
      onSelect()
    }
  }

  const handleClick = () => {
    setIsSelected(true)
    if (onSelect) {
      onSelect()
    }
  }

  return (
    <>
      {!!svgName ? (
        <Flex
          onClick={handleClick}
          className='option_with_icon'
          flexDirection='column'
          alignItems='center'
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            pointerEvents: disabled ? 'none' : 'auto'
          }}>
          <SelectItemContainer disabled={disabled}>
            {!!svgName && (
              <SVG
                name={svgName}
                iconState={
                  disabled
                    ? IconState.DEACTIVATED
                    : selected || isSelected
                    ? IconState.SELECTED
                    : IconState.ACTIVE
                }
              />
            )}
            <Space mt={`${AppTheme.spacingSize.laptop.XXS}px`}>
              <Typography
                type={AppTypography.TABLE}
                color={disabled ? AppTheme.colors.grayD4 : AppTheme.colors.gray65}
                fontWeight={300}>
                {value}
              </Typography>
            </Space>
          </SelectItemContainer>
          <OptionInput
            disabled={disabled}
            type='checkbox'
            value={value}
            name={name}
            checked={selected || isSelected}
            onChange={handleChangeCheckState}
          />
        </Flex>
      ) : (
        <TextOptionContainer
          onClick={handleClick}
          isInline={isInline}
          itemWidth={itemWidth}
          className='text_option'>
          <TextOption active={selected || isSelected}>{value}</TextOption>
          <OptionInput
            type='checkbox'
            value={value}
            name={name}
            checked={selected || isSelected}
            onChange={handleChangeCheckState}
          />
        </TextOptionContainer>
      )}
    </>
  )
}

SelectItem.defaultProps = {
  isInline: true
}

interface OptionStyleProps {
  active?: boolean
}

const SelectItemContainer = styled.div<Partial<SelectItemProps>>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`

const TextOptionContainer = styled.div<SelectItemStyleProps>`
  display: ${(props) => (props.isInline ? 'inline-block' : 'block')};
  width: ${(props) => props.itemWidth && props.itemWidth};
  cursor: pointer;
`

const TextOption = styled.label<OptionStyleProps>`
  display: inline-block;
  border: ${(props) =>
    !props.active
      ? `1px solid ${props.theme.colors.grayD4}`
      : `1px solid ${props.theme.colors.secondary}`};
  padding: 10px 16px;
  box-sizing: border-box;
  width: 100%;
  color: ${(props) =>
    !props.active
      ? props.theme.colors.grayB9
      : readableColor(
          props.theme.colors.secondary,
          props.theme.colors.black,
          props.theme.colors.white
        )};
  font-size: ${(props) => props.theme.fonts.fontsize.app.L}px;
  line-height: ${(props) => props.theme.fonts.lineheight.app.L}px;
  font-family: ${(props) => props.theme.fonts.family};
  font-weight: 300;
  background-color: ${(props) => (props.active ? props.theme.colors.secondary : 'transparent')};
  cursor: pointer;
  transition: all 0.5s ease;
  user-select: none;
  &:hover {
    color: ${(props) => props.theme.colors.black};
  }
`

const OptionInput = styled.input`
  display: none;
`

export default SelectItem
