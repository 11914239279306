/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-undef */
import {
  Accordion,
  Alert,
  AppTypography,
  RangeSlider,
  Select,
  Space,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { AppTheme } from 'theme/index'
import {
  BuildingAreaSteps,
  BuildYearSteps,
  numberWithThousandSeparator,
  Question,
  Units
} from 'utils/index'
import 'react-confirm-alert/src/react-confirm-alert.css'

interface QuestionHeatpumpProps {
  index: number
  isLast: boolean
}

export const QuestionHeatpump: React.FC<QuestionHeatpumpProps> = observer(({ index, isLast }) => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore

  const initialBuildYear = Math.floor((BuildYearSteps.length - 1) / 2)
  const buildYearIndex = BuildYearSteps.findIndex((el) => el.output === questionStore.buildYear)
  const stepIndex = buildYearIndex === -1 ? initialBuildYear : buildYearIndex
  // const [hasBuildYearChanged, setHasBuildYearChanged] = useState(false)

  const initialBuildingArea = Math.floor((BuildingAreaSteps.length - 1) / 2)
  const buildAreaIndex = BuildingAreaSteps.findIndex(
    (el) => el.output === questionStore.buildingArea
  )
  const areaStepIndex = buildAreaIndex === -1 ? initialBuildingArea : buildAreaIndex
  // const [hasAreaChanged, setHasAreaChanged] = useState(false)

  const handleOpen = () => {
    if (!questionStore.questionsStates[4].blocked) {
      questionStore.updateQuestionStateById(
        Question.HEATPUMP,
        'open',
        !questionStore.questionsStates[4].open
      )
    }
  }

  const handleClose = () => {
    // onSubmit()
    questionStore.handleSumbitHeatpump()
    if (!questionStore.questionsStates[4].answered) {
      questionStore.updateQuestionStateById(Question.HEATPUMP, 'open', true)
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert
              onClose={onClose}
              title='Bitte beantworten Sie die Frage'
              description='Nach der Beantwortung kommen Sie zur nächsten Frage.'
            />
          )
        }
      })
    } else {
      if (isLast && !store.uiStore.resultDeactivated) {
        store.uiStore.updateLeftSideWidth(35)
        store.uiStore.updateShowContact(true)
        store.calculateResult()
      } else {
        questionStore.openNextAccordion(index)
      }
    }
  }

  return (
    <Accordion
      isBlocked={questionStore.questionsStates[4].blocked}
      className='question_heatpump'
      isOpen={!questionStore.questionsStates[4].blocked && questionStore.questionsStates[4].open}
      title='Wärmepumpe'
      index={4}
      btnText={isLast ? 'Angebot anzeigen' : 'weiter'}
      onSubmit={handleClose}
      setIsOpen={handleOpen}
      isEdited={questionStore.questionsStates[4].answered}
      btnDisabled={isLast ? !store.questionStore.allAnswered : isLast}>
      <>
        <Space mb={`${AppTheme.spacingSize.laptop.XL}px`}>
          <Typography type={AppTypography.QUESTION} fontWeight={300}>
            {'Wählen Sie das passende Gebäude oder die Wohnung aus.'}
          </Typography>
          <Select
            itemGapX={`${AppTheme.spacingSize.laptop.XL}px`}
            name={'building_type'}
            disabledItemName={[]}
            isMultiple={false}
            selectedOptions={questionStore.buildingType}
            setSelectedOptions={questionStore.updateBuildingType}
          />
        </Space>

        <Typography type={AppTypography.QUESTION} fontWeight={300}>
          {'Wann wurde Ihre Wohnung/Ihr Haus gebaut?'}
        </Typography>
        <Space mt={`${AppTheme.spacingSize.laptop.S}px`} mb={`${AppTheme.spacingSize.laptop.XL}px`}>
          <RangeSlider
            steps={BuildYearSteps}
            stepDividedBy={50}
            value={questionStore.buildYear !== '' ? stepIndex : initialBuildYear}
            setValue={questionStore.updateBuildYear}
            output={!!questionStore.buildYear ? BuildYearSteps[stepIndex].output : 'Bitte wählen'}
          />
        </Space>

        <Typography type={AppTypography.QUESTION} fontWeight={300}>
          {'Wie groß ist Ihre Wohnung/Ihr Haus?'}
        </Typography>
        <Space mt={`${AppTheme.spacingSize.laptop.S}px`} mb={`${AppTheme.spacingSize.laptop.L}px`}>
          <RangeSlider
            steps={BuildingAreaSteps}
            stepDividedBy={40}
            value={questionStore.buildingArea !== '' ? areaStepIndex : initialBuildingArea}
            setValue={questionStore.updateBuildingArea}
            output={
              !!questionStore.buildingArea
                ? BuildingAreaSteps[areaStepIndex].output
                : 'Bitte wählen'
            }
          />
        </Space>
        <Space mb={`${AppTheme.spacingSize.laptop.L}px`}>
          <Typography type={AppTypography.SUBLINE} fontWeight={400} color={AppTheme.colors.primary}>
            {`Ihr Mehrverbrauch pro Jahr liegt bei etwa ${numberWithThousandSeparator(
              store.electricityConsumYear
            )} ${Units.KWH}.`}
          </Typography>
        </Space>
      </>
    </Accordion>
  )
})

export default QuestionHeatpump
