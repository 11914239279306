import { Facility } from './questionInteractStates'

type HintContent = {
  result_disabled: boolean
  min_power_consum: number
  hint__disabled_result: string
  hint__low_power_consum: string
  hint__not_in_plz: string
}

type PVSetting = {
  product_description: any
  product_caption: any
  pvanlage: any
  battery: any
  wallbox: any
  heatpump: any
  electricity: any
  evq: any
  showPrice: any
  form: any
  offer: any
  hint: HintContent
}

const isTrueSet = (myValue: string | boolean | undefined) => {
  if (typeof myValue === 'boolean') {
    return myValue
  } else if (typeof myValue === 'string') {
    return myValue === 'true'
  } else {
    return false
  }
}

const purchaseRendModelDefault = {
  rentModel: false,
  purchaseModel: false,
  standardSelect: '',
  leaseRuntime: '20'
}

export const mapDataToPVSettings = (data: any) => {
  const pvSettings: PVSetting = {
    product_description: {},
    product_caption: {},
    pvanlage: {},
    battery: {},
    wallbox: {},
    heatpump: {},
    electricity: {},
    evq: {},
    showPrice: {},
    form: {},
    offer: {},
    hint: {} as HintContent
  }

  // product description
  pvSettings.product_description.pv = data.settings_sw_text_product_description_pv
  pvSettings.product_description.bs = data.settings_sw_text_product_description_bs
  pvSettings.product_description.hp = data.settings_sw_text_product_description_hp
  pvSettings.product_description.wb = data.settings_sw_text_product_description_wb
  pvSettings.product_description.default =
    data.settings_sw_text_product_description_default?.question

  // product caption
  pvSettings.product_caption.pv = data.settings_sw_text_caption_pv?.question
  pvSettings.product_caption.bs = data.settings_sw_text_caption_only_bs?.question
  pvSettings.product_caption.wb = data.settings_sw_text_caption_wb?.question
  pvSettings.product_caption.hp = data.settings_sw_text_caption_hp?.question

  // pvanlage
  pvSettings.pvanlage.area_per_kwp = data.settings_sw_text_pv__area_per_kwp?.question
  pvSettings.pvanlage.power_per_module = data.settings_sw_text_pv__power_per_module?.question
  pvSettings.pvanlage.price_pv_facility = data.settings_sw_text_pv__price_pv_facility
  pvSettings.pvanlage.purchase_rent =
    data.settings_sw_text_pv__purchase_rent ?? purchaseRendModelDefault
  pvSettings.pvanlage.purchase_rent.purchaseModel = isTrueSet(
    data.settings_sw_text_pv__purchase_rent.purchaseModel
  )
  pvSettings.pvanlage.purchase_rent.rentModel = isTrueSet(
    data.settings_sw_text_pv__purchase_rent.rentModel
  )
  pvSettings.pvanlage.operating_costs_per_year =
    data.settings_sw_text_pv__operating_costs_per_year?.question
  pvSettings.pvanlage.annual_yield = data.settings_sw_text_pv__pv_annual_yield?.question

  // batteriespeicher
  pvSettings.battery.price_battery = data.settings_sw_text_bs__price_battery
  pvSettings.battery.purchase_rent =
    data.settings_sw_text_bs__purchase_rent ?? purchaseRendModelDefault
  pvSettings.battery.purchase_rent.purchaseModel = isTrueSet(
    data.settings_sw_text_bs__purchase_rent.purchaseModel
  )
  pvSettings.battery.purchase_rent.rentModel = isTrueSet(
    data.settings_sw_text_bs__purchase_rent.rentModel
  )
  pvSettings.battery.expansion = isTrueSet(data.settings_sw_boolean_bs__battery_expansion)

  // wallbox
  pvSettings.wallbox.price_wallbox = data.settings_sw_text_wb__price_wallbox

  //heatpump
  pvSettings.heatpump.average_heating_hours =
    data.settings_sw_text_hp__average_heating_hours?.question ?? 2000
  pvSettings.heatpump.drinking_hotwater_supply_included =
    data.settings_sw_boolean_hp__drinking_hotwater_supply_included === undefined ? false : true
  pvSettings.heatpump.price = data.settings_sw_text_hp__price_heatpump
  pvSettings.heatpump.purchase_rent =
    data.settings_sw_text_hp__purchase_rent ?? purchaseRendModelDefault
  pvSettings.heatpump.purchase_rent.purchaseModel = isTrueSet(
    data.settings_sw_text_hp__purchase_rent.purchaseModel
  )
  pvSettings.heatpump.purchase_rent.rentModel = isTrueSet(
    data.settings_sw_text_hp__purchase_rent.rentModel
  )

  // electricity_consum
  pvSettings.electricity.prices = data.settings_sw_text_ec__electricity_consum
  pvSettings.electricity.electricity_price_gross =
    data.settings_sw_text_electricity_price_gross?.question ?? 28
  pvSettings.electricity.additional_electricity_consum_per_km =
    data.settings_sw_text_additional_electricity_consum_per_km?.question ?? 0.2
  pvSettings.electricity.annual_price_increasement =
    data.settings_sw_text_annual_price_increasement?.question ?? 2

  // Eigenvebrauchsquote
  pvSettings.evq.pv_without_bs = data.settings_sw_text_evq__pv_without_bs?.question
  pvSettings.evq.pv_without_bs_with_wb = data.settings_sw_text_evq__pv_without_bs_with_wb?.question
  pvSettings.evq.pv_without_bs_with_hp = data.settings_sw_text_evq__pv_without_bs_with_hp?.question
  pvSettings.evq.pv_without_bs_with_wb_hp =
    data.settings_sw_text_evq__pv_without_bs_with_wb_hp?.question
  pvSettings.evq.pv_with_bs = data.settings_sw_text_evq__pv_with_bs?.question
  pvSettings.evq.pv_with_bs_hp = data.settings_sw_text_evq__pv_with_bs_hp?.question
  pvSettings.evq.pv_with_bs_wb = data.settings_sw_text_evq__pv_with_bs_wb?.question
  pvSettings.evq.pv_with_bs_wb_hp = data.settings_sw_text_evq__pv_with_bs_wb_hp?.question

  // show price
  pvSettings.showPrice.show_price_pv = data.settings_sw_boolean_show_price_pv
    ? isTrueSet(data.settings_sw_boolean_show_price_pv)
    : false
  pvSettings.showPrice.show_price_bs = data.settings_sw_boolean_show_price_bs
    ? isTrueSet(data.settings_sw_boolean_show_price_bs)
    : false
  pvSettings.showPrice.show_price_wb = data.settings_sw_boolean_show_price_wb
    ? isTrueSet(data.settings_sw_boolean_show_price_wb)
    : false
  pvSettings.showPrice.show_price_hp = data.settings_sw_boolean_show_price_hp
    ? isTrueSet(data.settings_sw_boolean_show_price_hp)
    : false

  // form
  pvSettings.form.text_show_before_price =
    data.settings_sw_text_show_before_price?.question === undefined
      ? ''
      : `${data.settings_sw_text_show_before_price?.question} `
  pvSettings.form.cross_selling_text = data.settings_sw_text_form_cross_selling_text?.question
  pvSettings.form.accept_data_protection_checkbox_text =
    data.settings_sw_text_form_accept_data_protection_checkbox_text?.question
  pvSettings.form.heat_tool_name = data.settings_sw_text_heat_tool_name?.question
  pvSettings.form.plz = data.settings_sw_text_plz?.question
  pvSettings.form.plz_format = data.settings_sw_text_plz_format ?? 'Deutschland'
  pvSettings.form.send_success = data.settings_sw_text_form_send_success?.question
  pvSettings.form.protection_url = data.settings_sw_text_form_data_protection_url?.question
  pvSettings.form.imprint_url = data.settings_sw_text_form_data_imprint_url?.question
  pvSettings.form.heat_tool_claim = data.settings_sw_text_heat_tool_claim?.question
  //requiredFields
  pvSettings.form.requiredFields = data.settings_sw_boolean_form_required_fields
  //additionalQuestions
  pvSettings.form.additional_question_1 =
    data.settings_sw_boolean_form_required_add_additional_question_1
  pvSettings.form.additional_question_2 =
    data.settings_sw_boolean_form_required_add_additional_question_2
  //Erreichbarkeiten
  pvSettings.form.available_days = data.settings_sw_text_form_available_days?.question
  pvSettings.form.available_time = data.settings_sw_text_form_available_time?.question

  // hint
  pvSettings.hint.result_disabled = isTrueSet(data.settings_sw_boolean_result_disabled) ?? false
  pvSettings.hint.min_power_consum = +data.settings_sw_text_min_power_consum?.question
  pvSettings.hint.hint__disabled_result = data.settings_sw_text_hint__disabled_result?.question
  pvSettings.hint.hint__low_power_consum = data.settings_sw_text_hint__low_power_consum?.question
  pvSettings.hint.hint__not_in_plz = data.settings_sw_text_hint__not_in_plz?.question

  const offeredFacility: string[] = []
  if (!!data.settings_sw_boolean_has_battery) {
    offeredFacility.push(Facility.BATTERTY)
  }
  if (!!data.settings_sw_boolean_has_pv_facility) {
    offeredFacility.push(Facility.PVA)
  }
  if (!!data.settings_sw_boolean_has_wallbox) {
    offeredFacility.push(Facility.WALLBOX)
  }
  if (!!data.settings_sw_boolean_has_heatpump) {
    offeredFacility.push(Facility.HEATPUMP)
  }

  pvSettings.offer = offeredFacility

  return pvSettings
}

export default mapDataToPVSettings
