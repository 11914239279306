import { readableColor } from 'polished'
import React, { useRef, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { Space } from '../Space'
import { AppTypography, Typography } from '../Typography'

import IconCar from './assets/Auto.svg'
import IconPlug from './assets/Stecker.svg'

interface RangeSliderCarProps {
  steps: string[] | { [key: string]: string | number }[]
  value: number
  setValue(val: number, sendDataToAPI?: boolean): void
  setValueFor?(key: string, value: string): void
  name?: string
  hasChanged?: boolean
  setHasChanged?(hasChanged: boolean): void
  output?: string
  description?: string
  stepDividedBy?: number
}

export const RangeSliderCar: React.FC<RangeSliderCarProps> = ({
  steps,
  value,
  setValue,
  name,
  setHasChanged,
  output,
  description,
  stepDividedBy = 1
}) => {
  const rangeOutputRef = useRef<HTMLOutputElement>(null)
  const rangeInputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState(value * stepDividedBy)

  const [outputLeftPosition, setOutputLeftPosition] = useState(
    `${(value / (steps.length - 1)) * 100}%`
  )
  const [wireWidth, setWireWidth] = useState('47%')

  function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(parseInt(event.target.value))
    const new_value = Math.floor(parseInt(event.target.value) / stepDividedBy)
    setValue(new_value, false)
    setOutput(parseInt(event.target.value))

    if (setHasChanged) {
      setHasChanged(true)
    }
  }

  const setOutput = (inputValue: number) => {
    const max = (steps.length - 1) * stepDividedBy
    const newVal = Number((inputValue / max) * 100)
    setOutputLeftPosition(`calc(${newVal}% + (${8 - newVal * 0.15}px))`)

    if (!!rangeInputRef && rangeInputRef.current) {
      const widthPercent = newVal > 10 ? newVal * 0.93 : newVal * 1.05
      setWireWidth(`${widthPercent}%`)
    }
    // Sorta magic numbers based on size of the native UI thumb
  }

  return (
    <RangeContainer className='range_slider_car'>
      <OutputContainer className='output'>
        <RangeOutput
          className='range_slider_output'
          name={name}
          left={outputLeftPosition}
          ref={rangeOutputRef}>
          {output}
        </RangeOutput>
      </OutputContainer>
      <InputContainer className='input'>
        <RangeInput
          type='range'
          className='range_slider_input'
          ref={rangeInputRef}
          name={name}
          min={0}
          max={(steps.length - 1) * stepDividedBy}
          step={1}
          value={inputValue}
          onChange={handleChangeValue}
          onMouseUp={() => setValue(Math.floor(inputValue / stepDividedBy), true)}
        />
        {value !== 0 && (
          <>
            <PlugContainer>
              <IconPlug />
            </PlugContainer>
            <WireContainer width={wireWidth} />
          </>
        )}
      </InputContainer>

      {!!description && (
        <Space mt={`${AppTheme.spacingSize.laptop.XXS}px`}>
          <Typography type={AppTypography.SUBLINE} color={AppTheme.colors.primary}>
            {description}
          </Typography>
        </Space>
      )}
    </RangeContainer>
  )
}

const svgCarString = encodeURIComponent(renderToStaticMarkup(<IconCar />))

const OutputContainer = styled.div`
  position: relative;
  height: 50px;
  width: 98%;
  margin: 0 auto;
`
export const RangeContainer = styled.div`
  width: 100%;
  position: relative;
  font-family: ${(props) => props.theme.fonts.family};
`

const InputContainer = styled.div`
  position: relative;
`
export const RangeInput = styled.input`
  width: 100%;
  display: block;
  height: 35px;
  -webkit-appearance: none;
  appearance: none;
  margin: 12px 0px;
  padding: 2px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.grayE};
  outline: none;
  box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: visible;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 70px;
    height: 30px;
    background: url('data:image/svg+xml,${svgCarString}') no-repeat;
    border: none;
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
    z-index: 100;
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
  }
  &::-moz-range-thumb {
    appearance: none;
    width: 70px;
    height: 31px;
    /* background-color: #fff; */
    background: url('data:image/svg+xml,${svgCarString}') no-repeat;
    border: none;
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
    z-index: 100;
    transition: background-color 150ms;
  }
`

const PlugContainer = styled.div`
  display: inline-block;
  position: absolute;
  left: 2px;
  top: 0;
  height: 30px;
  width: 30px;
  transform: scale(1.4);
  z-index: 2;
`

interface WireProps {
  width: string
}

const WireContainer = styled.div<WireProps>`
  display: inline-block;
  position: absolute;
  left: 16px;
  top: 48%;
  box-shadow: -10px 1px 4px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
  z-index: 1;
  background-color: white;
  height: 4px;
  width: ${(props) => props.width};
`

interface RangeOutputStyleProps {
  left?: string
}

export const RangeOutput = styled.output<RangeOutputStyleProps>`
  position: absolute;
  left: ${(props) => props.left};
  transform: translateX(-50%);
  display: table;
  padding: 12px 7px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.secondary};
  white-space: nowrap;
  color: ${(props) =>
    readableColor(
      props.theme.colors.secondary,
      props.theme.colors.black,
      props.theme.colors.white
    )};
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-top: solid 8px ${(props) => props.theme.colors.secondary};
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }
`

export default RangeSliderCar
