import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: ${(props) => props.theme.fonts.family};
    overflow: hidden;
  }

  @font-face {
    font-family: ${(props) => props.theme.fonts.customFont};
    src: url("${(props) => props.theme.fonts.fontURL}");
  }


  *, *:before, *:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  section {
    height: 100%;
    width: 100%;
    margin: 0px;
    display: block;
    overflow: hidden;
  }

  .collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .react-confirm-alert-overlay {
    z-index:10000!important;
  }

  .react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .react-confirm-alert {
    padding:  ${({ theme }) => theme.spacingSize.laptop.XS}px;
  }

  .pv-app-enter {
  opacity: 0;
  }
  .pv-app-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  
  input[type='number'] {
      -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

`
