import { DefaultTheme } from 'styled-components'

const AppColors: DefaultTheme['colors'] = {
  primary: '#A0C4C9',
  secondary: '#FFE000',
  white: '#FFF',
  black: '#000',
  grayE: '#EEE',
  grayD4: '#D4D4D4',
  grayB9: '#B9B9B9',
  gray65: '#656565',
  red: '#E20000'
}

const SpacingSizeL = {
  XXS: 8,
  XS: 12,
  S: 16,
  M: 20,
  L: 40,
  XL: 60,
  XXL: 64
}

const SpacingSizeM = {
  XXS: 4,
  XS: 12,
  S: 12,
  M: 16,
  L: 32,
  XL: 40,
  XXL: 64
}

export const AppWindow: DefaultTheme['window'] = {
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth
}

const customFont: string = "'Roboto',sans-serif"
const fontURL: string = ''

export const AppTheme: DefaultTheme = {
  colors: AppColors,
  spacingSize: {
    laptop: SpacingSizeL,
    mobile: SpacingSizeM
  },
  window: AppWindow,
  fonts: {
    customFont,
    fontURL,
    family: "'Roboto',sans-serif",
    fontsize: {
      app: {
        XS: 12,
        S: 13,
        M: 14,
        L: 16,
        XL: 18
      },
      teaser: {
        laptop: {
          S: 13,
          M: 18,
          L: 22,
          XL: 50
        },
        mobile: {
          S: 13,
          M: 14,
          L: 16,
          XL: 28
        }
      }
    },
    lineheight: {
      app: {
        S: 14,
        M: 16,
        L: 20
      },
      teaser: {
        laptop: {
          XS: 16,
          S: 20,
          M: 24,
          L: 25,
          XL: 60
        },
        mobile: {
          S: 16,
          M: 20,
          L: 32
        }
      }
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    }
  }
}
