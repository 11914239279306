import React from 'react'
import styled from 'styled-components'

import { SVG, SVGNames } from '../SVG'

export const Hint: React.FC<{ showCloseBtn?: boolean; onClose?(): void }> = ({
  children,
  showCloseBtn,
  onClose
}) => {
  return (
    <HintContainer className='hint-text'>
      {showCloseBtn && (
        <IconWrapper onClick={onClose}>
          <SVG name={SVGNames.Exit} style={{ transform: 'scale(2)' }} />
        </IconWrapper>
      )}
      {children}
    </HintContainer>
  )
}

const HintContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  border: 2px solid ${({ theme }) => theme.colors.grayD4};
  padding: ${({ theme }) => theme.spacingSize.laptop.XS}px;
  margin-top: ${({ theme }) => theme.spacingSize.laptop.M}px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacingSize.laptop.M}px;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
`
