export const getCheckedAndDisabledState = (key: 'checked' | 'disabled', value: boolean) => {
  let checkedAndDisabled: { checked: boolean; disabled: boolean } = {
    checked: false,
    disabled: false
  }

  if (key === 'checked') {
    if (value) {
      checkedAndDisabled = {
        checked: true,
        disabled: false
      }
    } else {
      checkedAndDisabled = {
        checked: false,
        disabled: false
      }
    }
  } else {
    if (value) {
      checkedAndDisabled = {
        checked: false,
        disabled: true
      }
    } else {
      checkedAndDisabled = {
        checked: false,
        disabled: false
      }
    }
  }

  return checkedAndDisabled
}
