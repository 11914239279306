import {
  AccordionMobile,
  AppTypography,
  RangeSlider,
  Select,
  Space,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { AppTheme } from 'theme/index'
import { BuildingAreaSteps, BuildYearSteps, numberWithThousandSeparator, Units } from 'utils/index'

export const QuestionHeatpump: React.FC = observer(() => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore

  const initialBuildYear = Math.floor((BuildYearSteps.length - 1) / 2)
  const buildYearIndex = BuildYearSteps.findIndex((el) => el.output === questionStore.buildYear)
  const stepIndex = buildYearIndex === -1 ? initialBuildYear : buildYearIndex
  // const [hasBuildYearChanged, setHasBuildYearChanged] = useState(false)

  const initialBuildingArea = Math.floor((BuildingAreaSteps.length - 1) / 2)
  const buildAreaIndex = BuildingAreaSteps.findIndex(
    (el) => el.output === questionStore.buildingArea
  )
  const areaStepIndex = buildAreaIndex === -1 ? initialBuildingArea : buildAreaIndex
  // const [hasAreaChanged, setHasAreaChanged] = useState(false)

  const handleOpen = () => {
    if (!questionStore.questionsStates[4].blocked) {
      questionStore.updateQuestionStateById(
        'HEATPUMP',
        'open',
        !questionStore.questionsStates[4].open
      )
    }
  }

  return (
    <AccordionMobile
      className='question_heatpump'
      title='Wärmepumpe'
      index={4}
      isOpen={!questionStore.questionsStates[4].blocked && questionStore.questionsStates[4].open}
      isBlocked={questionStore.questionsStates[4].blocked}
      setIsOpen={handleOpen}
      isEdited={questionStore.questionsStates[4].answered}>
      <>
        <Space mb={`${AppTheme.spacingSize.mobile.XL}px`}>
          <Typography type={AppTypography.QUESTION} fontWeight={300}>
            {'Wählen Sie das passende Gebäude oder die Wohnung aus.'}
          </Typography>
          <Select
            name={'building_type'}
            isMultiple={false}
            useGrid
            gridGap={`${AppTheme.spacingSize.laptop.S * 2}px`}
            selectedOptions={questionStore.buildingType}
            setSelectedOptions={questionStore.updateBuildingType}
          />
        </Space>

        <Typography type={AppTypography.QUESTION} fontWeight={300}>
          {'Wann wurde Ihre Wohnung/Ihr Haus gebaut?'}
        </Typography>
        <Space mt={`${AppTheme.spacingSize.mobile.S}px`} mb={`${AppTheme.spacingSize.mobile.XL}px`}>
          <RangeSlider
            steps={BuildYearSteps}
            stepDividedBy={50}
            value={questionStore.buildYear !== '' ? stepIndex : initialBuildYear}
            setValue={questionStore.updateBuildYear}
            output={!!questionStore.buildYear ? BuildYearSteps[stepIndex].output : 'Bitte wählen'}
          />
        </Space>

        <Typography type={AppTypography.QUESTION} fontWeight={300}>
          {'Wie groß ist Ihre Wohnung/Ihr Haus?'}
        </Typography>
        <Space mt={`${AppTheme.spacingSize.mobile.S}px`} mb={`${AppTheme.spacingSize.mobile.L}px`}>
          <RangeSlider
            steps={BuildingAreaSteps}
            stepDividedBy={40}
            value={questionStore.buildingArea !== '' ? areaStepIndex : initialBuildingArea}
            setValue={questionStore.updateBuildingArea}
            output={
              !!questionStore.buildingArea
                ? BuildingAreaSteps[areaStepIndex].output
                : 'Bitte wählen'
            }
          />
        </Space>
        <Space mb={`${AppTheme.spacingSize.mobile.XXL}px`}>
          <Typography type={AppTypography.SUBLINE} fontWeight={400} color={AppTheme.colors.primary}>
            {`Ihr Mehrverbrauch pro Jahr liegt bei etwa ${numberWithThousandSeparator(
              store.electricityConsumYear
            )} ${Units.KWH}.`}
          </Typography>
        </Space>
      </>
    </AccordionMobile>
  )
})

export default QuestionHeatpump
