import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { AppTheme } from 'theme/index'

import { Space } from '../Space'
import { AppTypography, Typography } from '../Typography'

interface AlertProps {
  onClose: () => void
  title: string
  description: string
}
export const Alert: React.FC<AlertProps> = ({ onClose, title, description }) => {
  return (
    <ThemeProvider theme={AppTheme}>
      <AlertContainer onClick={onClose}>
        <Space px={AppTheme.spacingSize.laptop.M}>
          <Space my={AppTheme.spacingSize.laptop.M}>
            <Typography type={AppTypography.HEADLINE}>{title}</Typography>
          </Space>
          <Typography type={AppTypography.QUESTION}>{description}</Typography>
        </Space>
      </AlertContainer>
    </ThemeProvider>
  )
}

const AlertContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10 10 10 10;
`

export default Alert
