import React, { createContext, ReactElement, ReactNode, useState } from 'react'
import { AppTheme } from 'theme/index'

export enum DeviceType {
  MOBILE = 'MOBILE',
  LAPTOP = 'LAPTOP'
}

export type deviceTypes = DeviceType.MOBILE | DeviceType.LAPTOP

type DeviceContextType = {
  device: deviceTypes
  setDevice: (device: deviceTypes) => void
  windowHeight: number
}

const setDevice = (dv: deviceTypes) => {
  console.log(dv)
}

const defaultValue: DeviceContextType = {
  device: window.innerWidth < 968 ? DeviceType.MOBILE : DeviceType.LAPTOP,
  setDevice,
  windowHeight: window.innerHeight
}

export const DeviceContext = createContext<DeviceContextType>(defaultValue)

// create ContextProvider
interface DeviceProviderProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}
export const DeviceContextProvider: React.FC<DeviceProviderProps> = ({ children }) => {
  const checkSize = () => {
    if (window.innerWidth < 968) {
      return DeviceType.MOBILE
    } else {
      return DeviceType.LAPTOP
    }
  }

  const handleResize = () => {
    setDevice(checkSize())
    setWindowHeight(window.innerHeight)
    AppTheme.window.innerHeight = window.innerHeight
    AppTheme.window.innerWidth = window.innerWidth
  }

  React.useEffect(() => {
    setDevice(checkSize())
    window.addEventListener('resize', () => handleResize())
    return () => {
      window.removeEventListener('resize', () => handleResize())
    }
  }, [])

  const [device, setDevice] = useState(checkSize())
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  return (
    <DeviceContext.Provider value={{ device, setDevice, windowHeight }}>
      {children}
    </DeviceContext.Provider>
  )
}

export default DeviceContext
