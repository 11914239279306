import { AppStoreContext } from 'contexts/index'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { AppTypography, Link, Space, Typography } from '..'

export const LinkFooter: React.FC = () => {
  const store = useContext(AppStoreContext)

  return (
    <FooterContainer>
      {!!store.pvSettings?.form?.protection_url && (
        <Link target='_blank' href={store.pvSettings?.form?.protection_url}>
          <Typography type={AppTypography.SUBLINE}>Datenschutz</Typography>
        </Link>
      )}
      {!!store.pvSettings?.form?.imprint_url && (
        <Space ml={AppTheme.spacingSize.laptop.S}>
          <Link target='_blank' href={store.pvSettings?.form?.imprint_url}>
            <Typography type={AppTypography.SUBLINE}>Impressum</Typography>
          </Link>
        </Space>
      )}
      <Space ml={AppTheme.spacingSize.laptop.S}>
        <Typography
          type={AppTypography.SUBLINE}
          width='auto'
          display='inline'
          color={AppTheme.colors.grayB9}>{`v${process.env.VERSION}`}</Typography>
      </Space>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`

export default LinkFooter
