import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { ThemeProvider } from 'styled-components'

import { AppStoreContext, DeviceContextProvider, StoreContextProvider } from './contexts/index'
import { AppTheme, GlobalStyles } from './theme/index'
import { MainContainer as Main } from './views/page/MainContainer'
import '../src/theme/App.css'

const App: React.FC = observer(() => {
  const store = useContext(AppStoreContext)
  const [isLoading, setIsLoading] = useState(true)
  const [inProp, setInProp] = useState(false)
  const [appTitle, setAppTitle] = useState('Loading...')

  useEffect(() => {
    store.loadPVSettings().then((pvSettings: any) => {
      store.loadCISettings().then((ciSettings: any) => {
        store.updateThemeFromCiSettings(ciSettings)
        setAppTitle(pvSettings.form?.heat_tool_name)
        setIsLoading(false)
        setInProp(true)
      })
    })
  }, [isLoading, store])

  return (
    <DeviceContextProvider>
      <StoreContextProvider>
        <ThemeProvider theme={AppTheme}>
          {!isLoading && (
            <React.Fragment>
              <CSSTransition in={inProp} timeout={400} classNames='pv-app'>
                <Main appTitle={appTitle} />
              </CSSTransition>
              <GlobalStyles />
            </React.Fragment>
          )}
        </ThemeProvider>
      </StoreContextProvider>
    </DeviceContextProvider>
  )
})

export default App

function renderApp(rootEl: HTMLElement) {
  ReactDOM.render(<App />, rootEl)
}

function renderStandaloneApp(rootEl: HTMLElement) {
  ReactDOM.render(<App />, rootEl)
}

console.log('Render PVRechner APP')

const methodName = `render_app_pv`
//this one we need for integrated app start
;(window as any)[methodName] = renderApp
//this one we need for standalone app start
;(window as any).renderPVApp = renderStandaloneApp
