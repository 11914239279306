import {
  AppTypography,
  Button,
  Flex,
  Hint,
  Image,
  LinkFooter,
  SanitizedContent,
  Space,
  TableRow,
  Typography
} from 'components/index'
import { AppStoreContext, DeviceContext, DeviceType } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import { readableColor } from 'polished'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'
import { baseUploadsUrl, Facility, numberFormat } from 'utils/index'

interface ResultBeforeCalculationProps {
  onSubmit(): void
}

export const ResultBeforeCalculation: React.FC<ResultBeforeCalculationProps> = observer(
  ({ onSubmit }) => {
    const { device } = useContext(DeviceContext)
    const isMobile = device === DeviceType.MOBILE
    const store = useContext(AppStoreContext)
    const logoURL = baseUploadsUrl + store.ciSettings?.settings_sw_file_logo

    return (
      <Flex
        justifyContent='space-between'
        flexDirection='column'
        style={{ height: '100%' }}
        className='container__before-calculation'>
        <div>
          <Space mt={`${AppTheme.spacingSize.laptop.XXS}px`}>
            <ImageContainer>
              <Image
                src={logoURL}
                maxWidth='80%'
                display='block'
                width='100%'
                maxHeight='200px'
                style={{ alignSelf: 'flex-start', objectFit: 'contain' }}
              />
            </ImageContainer>
          </Space>
          <Space
            mt={`${AppTheme.spacingSize.laptop.L}px`}
            mb={`${AppTheme.spacingSize.laptop.M}px`}>
            <Typography
              type={AppTypography.HEADLINE}
              color={AppTheme.colors.gray65}
              fontWeight={500}>
              {store.pvSettings?.form?.heat_tool_claim}
            </Typography>
          </Space>
          <TextContainer className='result-form__before-calculation'>
            {!!store.questionStore.recommandFacility?.length ? (
              <>
                <Typography
                  type={AppTypography.RESULT}
                  color={readableColor(
                    AppTheme.colors.secondary,
                    AppTheme.colors.black,
                    AppTheme.colors.white
                  )}>
                  Unsere Empfehlung für Sie:
                </Typography>
                <div
                  style={{
                    width: '100%',
                    display: 'table',
                    marginTop: `${AppTheme.spacingSize.laptop.XS}px`
                  }}>
                  {store.questionStore.recommandFacility?.map((item: { [key: string]: string }) => {
                    switch (item.id) {
                      case Facility.HEATPUMP:
                        return (
                          <TableRow
                            key={item.id}
                            column1={item.name}
                            column2='mit'
                            column3={`${numberFormat(
                              !!store.priceHP?.heatload && store.priceHP?.heatload !== '0'
                                ? store.priceHP?.heatload
                                : store.pvSettings?.heatpump?.price[0]?.heatload
                            )} ${item.unit}`}
                          />
                        )

                      case Facility.BATTERTY:
                        return (
                          <TableRow
                            key={item.id}
                            column1={item.name}
                            column2='mit'
                            column3={`${numberFormat(
                              !!store.priceBS?.facilityPower && store.priceBS?.facilityPower !== '0'
                                ? store.priceBS?.facilityPower
                                : store.pvSettings?.battery?.price_battery?.prices[0]?.facilityPower
                            )} ${item.unit}`}
                          />
                        )

                      case Facility.WALLBOX:
                        return (
                          <TableRow
                            key={item.id}
                            column1={item.name}
                            column2='mit'
                            column3={`${numberFormat(
                              !!store.priceWB?.facilityPower && store.priceWB?.facilityPower !== '0'
                                ? store.priceWB?.facilityPower
                                : store.pvSettings?.wallbox?.price_wallbox?.prices[0]?.facilityPower
                            )} ${item.unit}`}
                          />
                        )
                      case Facility.PVA:
                        return (
                          <TableRow
                            key={item.id}
                            column1={item.name}
                            column2='mit'
                            column3={`${numberFormat(
                              !!store.pricePV?.facilityPower && store.pricePV?.facilityPower !== '0'
                                ? store.pricePV?.facilityPower
                                : store.pvSettings?.pvanlage?.price_pv_facility?.prices[0]
                                    .facilityPower
                            )} ${item.unit}`}
                          />
                        )
                      default:
                        return (
                          <TableRow
                            key={item.id}
                            column1={item.name}
                            column2='mit'
                            column3={`${numberFormat(item.value)} ${item.unit}`}
                          />
                        )
                    }
                  })}
                </div>
              </>
            ) : (
              <Typography
                type={AppTypography.RESULT}
                color={readableColor(
                  AppTheme.colors.secondary,
                  AppTheme.colors.black,
                  AppTheme.colors.white
                )}>
                {store.pvSettings?.product_description?.default}
              </Typography>
            )}
          </TextContainer>

          {store.uiStore.resultDeactivated && (
            <Hint>
              <Typography type={AppTypography.QUESTION}>
                <SanitizedContent content={store.uiStore.deactivedResultHint} />
              </Typography>
            </Hint>
          )}
        </div>
        <div>
          {store.questionStore.allAnswered && (
            <Flex justifyContent='flex-end'>
              <Button onClick={onSubmit} disabled={store.uiStore.resultDeactivated}>
                Angebot
              </Button>
            </Flex>
          )}
          {!isMobile && (
            <Space mt={`${AppTheme.spacingSize.laptop.M}px`}>
              <LinkFooter />
            </Space>
          )}
        </div>
      </Flex>
    )
  }
)

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TextContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: ${(props) => props.theme.spacingSize.laptop.XS}px;
  border-radius: 5px;
`

export default ResultBeforeCalculation
