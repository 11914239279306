import { readableColor } from 'polished'
import React from 'react'
import { AppTheme } from 'theme/index'

import { Flex } from '../Flex'
import { AppTypography, Typography } from '../Typography'

interface TableRowProps {
  column1: string
  column2: string
  column3: string
}

export const TableRow: React.FC<TableRowProps> = ({ column1, column2, column3 }) => {
  return (
    <Flex flexDirection='row' justifyContent='space-between'>
      <Typography
        type={AppTypography.RESULT}
        fontWeight={500}
        display='inline-block'
        width='60%'
        color={readableColor(
          AppTheme.colors.secondary,
          AppTheme.colors.black,
          AppTheme.colors.white
        )}>
        {column1}
      </Typography>

      <Typography
        type={AppTypography.RESULT}
        fontWeight={500}
        display='inline-block'
        width='auto'
        color={readableColor(
          AppTheme.colors.secondary,
          AppTheme.colors.black,
          AppTheme.colors.white
        )}>
        {column2}
      </Typography>

      <Typography
        type={AppTypography.RESULT}
        fontWeight={500}
        display='inline-flex'
        width='40%'
        justifyContent='flex-end'
        color={readableColor(
          AppTheme.colors.secondary,
          AppTheme.colors.black,
          AppTheme.colors.white
        )}>
        {column3}
      </Typography>
    </Flex>
  )
}

export default TableRow
