import { SelectOptionType } from 'components/index'
import { makeAutoObservable, toJS } from 'mobx'
import {
  BatteryCapacitySteps,
  BuildingAreaSteps,
  BuildingTypes,
  BuildYearSteps,
  DrivingDistanceSteps,
  Facility,
  FacilityType,
  getCheckedAndDisabledState,
  InstalledFacility,
  InterestedFacility,
  ProductDescription,
  Question,
  QuestionInteractStates,
  QuestionStateType,
  RecommendedFacility,
  removeThousandSeparator,
  SelectOptionTypeForInstalled,
  SelectOptionTypeForInterested
} from 'utils/index'

import AppStore from './ApplicationStore'

class QuestionStore {
  appStore: typeof AppStore

  /** ============== 1st Question ==========  */
  installedFacility: SelectOptionTypeForInstalled[] = InstalledFacility
  interestedFacility: SelectOptionTypeForInterested[] = InterestedFacility
  recommandFacility: { [key: string]: string }[] = []
  disabledInstalledFacility: string[] = []
  disabledInterestedFacility: string[] = []
  powerPVA: string = ''
  isPVInstalled: boolean = false
  isBSInstalled: boolean = false
  isWallboxInstalled: boolean = false
  isPVInterested: boolean | undefined = false
  isWallboxInterested: boolean | undefined = false
  isBSInterested: boolean | undefined = false
  isHPInterested: boolean | undefined = false
  isInterestedNoneSelected: boolean = true

  possessionAnswer: {
    installedFacility: string[]
    interestedFacility: string[]
    powerPVA: number
  } = {
    installedFacility: [] as string[],
    interestedFacility: [] as string[],
    powerPVA: this.powerPVA.includes(',') ? +this.powerPVA.replace(',', '.') : +this.powerPVA
  }

  /** ============== 2nd Question ==========  */
  familySizeSteps: string[] = ['1', '2', '3', '4', '5']

  familySize: string = ''
  estimatedAnnualConsum: string = ''
  grossElectricityPrice: string = ''

  annualPowerAnswer: any = {
    familySize: this.familySize,
    estimatedAnnualConsum: this.estimatedAnnualConsum,
    grossElectricityPrice: this.grossElectricityPrice
  }

  /** ============== 3rd Question ==========  */
  isEmobilityFutureIssue: boolean = false
  averageDrivingDistance: string = ''

  eMobilityAnswer: any = {
    isEmobilityFutureIssue: this.isEmobilityFutureIssue,
    averageDrivingDistance: this.averageDrivingDistance
  }

  /** ============== 4th Question ==========  */

  eMobilityCapacity: string = ''

  wallboxAnswer: any = {
    averageDrivingDistance: this.averageDrivingDistance,
    eMobilityCapacity: this.eMobilityCapacity
  }

  /** ============== 5th Question ==========  */

  buildYear: string = ''
  buildYearValue: string = ''
  buildingArea: string = ''
  buildingAreaValue: number = 0

  buildingType: SelectOptionType[] = BuildingTypes
  heatpumAnswer: any = {
    buildYear: '',
    buildingArea: 0,
    buildingType: ''
  }

  /** ============ something common ============= */
  questionsStates: QuestionStateType[] = QuestionInteractStates
  shownQuestions: QuestionStateType[] = this.questionsStates.filter((question) => !question.hidden)
  allAnswered: boolean = false
  evq: number = 0

  public constructor(appStore: typeof AppStore) {
    makeAutoObservable(this)
    this.appStore = appStore
    this.disabledInstalledFacility = this.getDisabledInstall()
    this.disabledInterestedFacility = this.getDisabledInterest()
    // this.updateDisabled()
  }

  textBehindResultForm: string = ''
  textProductDescription: ProductDescription = {
    description: ''
  }

  /** ============== 1st Question ==========  */

  getInterestedFacilities(facilities: SelectOptionTypeForInterested[]) {
    return facilities.filter((item) => this.appStore.pvSettings!.offer.includes(item.name))
  }

  updateInstalledFacility = (newData: SelectOptionTypeForInstalled[]) => {
    this.installedFacility = toJS(newData)

    const isNoneInstalled: boolean = this.installedFacility.find(
      (item) => item.name === 'NONE'
    )!.checked
    this.isPVInstalled = this.installedFacility.find((item) => item.name === Facility.PVA)!.checked
    this.isWallboxInstalled = this.installedFacility.find(
      (item) => item.name === Facility.WALLBOX
    )!.checked
    this.isBSInstalled = this.installedFacility.find(
      (item) => item.name === Facility.BATTERTY
    )!.checked

    const installedF = this.installedFacility.filter((f) => f.name !== Facility.NONE && f.checked)

    const selectedInstalledNames: FacilityType[] = []
    installedF.forEach(
      (item: SelectOptionTypeForInstalled) =>
        item.name !== Facility.NONE && selectedInstalledNames.push(item.name)
    )

    if (isNoneInstalled) {
      // "None" is selected
      this.installedFacility.forEach((f) => {
        if (f.name !== Facility.NONE) {
          this.updateInstalledFacilityById(f.name, 'disabled', true)
          this.interestedFRemainSame(f as SelectOptionTypeForInterested)
          // this.interestedFacility.find((item) => item.name === f.name)!.checked
          //   ? this.updateInterestedFacilityById(f.name, 'checked', true)
          //   : this.updateInterestedFacilityById(f.name, 'disabled', false)
        }
      })
    } else {
      // if None is not selected, others (in Install) also are neither selected nor disabled
      if (installedF.length === 0) {
        this.installedFacility.forEach((f) => {
          if (f.name !== Facility.NONE) {
            this.updateInstalledFacilityById(f.name, 'disabled', false)
            this.interestedFRemainSame(f as SelectOptionTypeForInterested)
          }
        })
      } else {
        // if any Facility is installed, the relavant facility in Interest should be disabled
        // if (installedF.length > 0) {
        this.installedFacility.forEach((f) => {
          if (f.name !== Facility.NONE) {
            f.checked // if installed, disable it in Interest
              ? // if not installed, check if the facility is interested
                //  ---- if interested, keep "checked" true
                //  ---- if not interested, keep "disabled" false
                this.updateInterestedFacilityById(f.name, 'disabled', true)
              : this.interestedFacility.find((item) => item.name === f.name)?.checked
              ? this.updateInterestedFacilityById(f.name, 'checked', true)
              : this.updateInterestedFacilityById(
                  f.name,
                  'disabled',
                  false
                  // !this.appStore.pvSettings.battery.expansion && f.name === Facility.BATTERTY
                  //   ? true
                  //   : false
                ) // 要检查这个facility是否checked？
          }
        })
        // }
      }

      if (this.appStore.pvSettings.battery.expansion) {
        if (this.isPVInstalled) {
          if (this.isBSInstalled) {
            if (this.isBSInterested) {
              this.updateInterestedFacilityById(Facility.BATTERTY, 'checked', true)
            }
            this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', true)
          } else {
            // this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', false)
            if (this.isBSInterested) {
              this.updateInterestedFacilityById(Facility.BATTERTY, 'checked', true)
            } else {
              this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', false)
            }
          }

          // if (this.isBSInterested) {
          //   this.updateInterestedFacilityById(Facility.BATTERTY, 'checked', true)
          // } else {
          //   this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', false)
          // }
        } else {
          if (!this.isPVInterested) {
            this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', true)
          }
        }
      } else {
        if (this.isPVInstalled) {
          this.updateInterestedFacilityById(Facility.PVA, 'disabled', true)
          this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', true)
        } else {
          if (this.isBSInterested) {
            this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', false)
            this.updateInterestedFacilityById(Facility.BATTERTY, 'checked', true)
          } else {
            this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', !this.isPVInterested)
          }
        }
      }

      if (this.isPVInstalled) {
        if (this.isBSInstalled) {
          this.updateInstalledFacilityById(Facility.BATTERTY, 'checked', true)
        } else {
          this.updateInstalledFacilityById(Facility.BATTERTY, 'disabled', false)
        }
      } else if (!this.isPVInstalled) {
        if (this.isBSInstalled) {
          this.updateInstalledFacilityById(Facility.BATTERTY, 'checked', false)
        }
        this.updateInstalledFacilityById(Facility.BATTERTY, 'disabled', true)
      }

      if (this.isWallboxInstalled) {
        this.updateQuestionStateById(Question.EMOBILITY, 'hidden', true)
      } else {
        if (this.isPVInterested) {
          this.updateQuestionStateById(Question.EMOBILITY, 'hidden', false)
        }
      }
    }

    // update question
    this.isPVInterested = this.interestedFacility.find(
      (item) => item.name === Facility.PVA
    )?.checked

    this.isBSInterested = this.interestedFacility.find(
      (item) => item.name === Facility.BATTERTY
    )?.checked

    this.isWallboxInterested = this.interestedFacility.find(
      (item) => item.name === Facility.WALLBOX
    )?.checked

    this.isHPInterested = this.interestedFacility.find(
      (item) => item.name === Facility.HEATPUMP
    )?.checked

    if (this.isPVInterested) {
      if (!this.isWallboxInstalled) {
        this.updateQuestionStateById(Question.EMOBILITY, 'hidden', false)
      }
    } else {
      this.updateQuestionStateById(Question.EMOBILITY, 'hidden', true)
      this.updateIsEmobilityFutureIssue(false)
    }

    if (!this.isWallboxInterested) {
      // this.updateQuestionStateById(Question.EMOBILITY, 'hidden', false)
      this.updateQuestionStateById(Question.WALLBOX, 'hidden', true)
    } else {
      this.updateQuestionStateById(Question.EMOBILITY, 'hidden', true)
      this.updateQuestionStateById(Question.WALLBOX, 'hidden', false)
    }

    if (!this.isHPInterested) {
      this.updateQuestionStateById(Question.HEATPUMP, 'hidden', true)
    } else {
      this.updateQuestionStateById(Question.HEATPUMP, 'hidden', false)
    }

    this.possessionAnswer.installedFacility = toJS(this.installedFacility)
      .filter((item) => item.checked === true)
      .map((item) => item.name)

    this.disabledInstalledFacility = this.getDisabledInstall()
    this.updateRecommandFacility(toJS(this.interestedFacility))
    this.updateShownQuestions()
  }

  updateInterestedFacility = (newData: SelectOptionTypeForInterested[]) => {
    this.interestedFacility = toJS(this.getInterestedFacilities(newData))

    this.isPVInterested = this.interestedFacility.find(
      (item) => item.name === Facility.PVA
    )?.checked

    this.isBSInterested = this.interestedFacility.find(
      (item) => item.name === Facility.BATTERTY
    )?.checked

    this.isWallboxInterested = this.interestedFacility.find(
      (item) => item.name === Facility.WALLBOX
    )?.checked

    this.isHPInterested = this.interestedFacility.find(
      (item) => item.name === Facility.HEATPUMP
    )?.checked

    this.isInterestedNoneSelected =
      toJS(newData).filter((facility) => facility.checked === true).length === 0

    if (!!this.isPVInterested) {
      if (!!this.isBSInterested) {
        this.updateInterestedFacilityById(Facility.BATTERTY, 'checked', true)
      } else {
        this.updateInterestedFacilityById(Facility.BATTERTY, 'checked', false)
        this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', false)
      }

      if (!this.isWallboxInstalled) {
        this.updateQuestionStateById(Question.EMOBILITY, 'hidden', false)
      }
    } else {
      if (this.appStore.pvSettings.battery.expansion) {
        if (this.isBSInterested && this.isPVInstalled) {
          this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', false)
          this.updateInterestedFacilityById(Facility.BATTERTY, 'checked', true)
        } else {
          if (this.isPVInstalled) {
            this.updateInterestedFacilityById(Facility.BATTERTY, 'checked', false)
            this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', false)
          }
        }
      } else {
        this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', true)
      }

      // this.updateInterestedFacilityById(Facility.BATTERTY, 'checked', false)
      this.updateQuestionStateById(Question.EMOBILITY, 'hidden', true)
      this.updateIsEmobilityFutureIssue(false)
    }

    if (!this.isWallboxInterested) {
      // this.updateQuestionStateById(Question.EMOBILITY, 'hidden', false)
      this.updateQuestionStateById(Question.WALLBOX, 'hidden', true)
    } else {
      this.updateQuestionStateById(Question.EMOBILITY, 'hidden', true)
      this.updateQuestionStateById(Question.WALLBOX, 'hidden', false)
    }

    if (!this.isHPInterested) {
      this.updateQuestionStateById(Question.HEATPUMP, 'hidden', true)
    } else {
      this.updateQuestionStateById(Question.HEATPUMP, 'hidden', false)
    }

    // this.updateInterestedFacilityById(Facility.BATTERTY, 'disabled', !this.isPVInterested)

    this.possessionAnswer.interestedFacility = toJS(this.interestedFacility)
      .filter((item) => item.checked === true)
      .map((item) => item.name)

    this.disabledInterestedFacility = this.getDisabledInterest()

    this.updateShownQuestions()
    this.updateRecommandFacility(toJS(newData))
    this.updateTextUnderResult()
    this.updateEVQ()
  }

  interestedFRemainSame = (f: SelectOptionTypeForInterested) => {
    this.interestedFacility.find((item) => item.name === f.name)?.checked
      ? this.updateInterestedFacilityById(
          f.name,
          'checked',
          f.name === Facility.BATTERTY ? !!(this.isPVInterested && this.isBSInterested) : true
        )
      : this.updateInterestedFacilityById(
          f.name,
          'disabled',
          f.name === Facility.BATTERTY ? !this.isPVInterested : false
        )
  }

  updateRecommandFacility = (interested: SelectOptionTypeForInterested[]) => {
    const interestedProduct = interested.filter((item) => item.checked === true)
    const recommand: any = []
    interestedProduct.map((product) => {
      RecommendedFacility.map((facility) => {
        if (facility.id === product.value_id) {
          recommand.push(facility)
        }
      })
    })
    this.recommandFacility = recommand
  }

  updatePowerPVA = (newData: string) => {
    this.powerPVA = newData
    this.appStore.uiStore.updateFieldPowerPVAHasError(this.isPVInstalled && !this.powerPVA)
  }

  handleSubmitQuestionPossession = () => {
    this.possessionAnswer = {
      installedFacility: this.installedFacility
        .filter((item) => item.checked === true)
        .map((item) => item.name),
      interestedFacility: this.interestedFacility
        .filter((item) => item.checked === true)
        .map((item) => item.name),
      powerPVA: !!this.powerPVA
        ? this.powerPVA.includes(',')
          ? +this.powerPVA.replace(',', '.')
          : +this.powerPVA
        : 0
    }

    const answered =
      this.installedFacility.filter((item) => item.name === Facility.PVA && item.checked).length > 0
        ? !!this.powerPVA && !this.isInterestedNoneSelected
        : !this.isInterestedNoneSelected

    this.updateQuestionStateById(Question.POSSESSION, 'answered', answered)
    this.appStore.uiStore.updateFieldPowerPVAHasError(this.isPVInstalled && !this.powerPVA)
    this.allAnswered = this.checkQuestionsAllAnswered()
  }

  /** ============== 2nd Question ==========  */

  updateFamilySize = (newDataIndex: number, shouldUpdateAnnualConsum?: boolean) => {
    this.familySize = toJS(this.familySizeSteps[newDataIndex])
    const annualConsum: any = toJS(this.appStore.pvSettings.electricity.prices.prices)

    if (shouldUpdateAnnualConsum) {
      this.updateEstimatedAnnualConsum(annualConsum[newDataIndex].consumAmount)
    }

    if (!this.grossElectricityPrice) {
      this.updateGrossElectricityPrice(
        toJS(this.appStore.pvSettings.electricity.electricity_price_gross)
      )
    }
    this.handleSubmitAnnualPower()
  }

  timeout: any = null
  updateEstimatedAnnualConsum = (newData: string) => {
    if (newData !== this.estimatedAnnualConsum) {
      this.estimatedAnnualConsum = newData
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        // this.annualPowerAnswer.estimatedAnnualConsum = parseInt(this.estimatedAnnualConsum)
        this.updateFamilySize(this.changeFamilySizeByConsum(newData), false)
        this.appStore.calculateBattery()
        this.appStore.calculatePV()
      }, 800)
    }
    this.handleSubmitAnnualPower()
  }

  changeFamilySizeByConsum = (userInput: string) => {
    const input = +userInput

    const ranges = this.appStore.pvSettings.electricity.prices.prices
    let newFamilySize: number = 0
    if (input < +ranges[1].consumAmount) {
      newFamilySize = 0
    } else if (input < +ranges[2].consumAmount && input >= +ranges[1].consumAmount) {
      newFamilySize = 1
    } else if (input < +ranges[3].consumAmount && input >= +ranges[2].consumAmount) {
      newFamilySize = 2
    } else if (input < +ranges[4].consumAmount && input >= +ranges[3].consumAmount) {
      newFamilySize = 3
    } else {
      newFamilySize = 4
    }
    return newFamilySize
  }

  updateGrossElectricityPrice = (newData: string) => {
    this.grossElectricityPrice = newData
    this.handleSubmitAnnualPower()
  }

  handleSubmitAnnualPower = (openNext?: boolean, isMobile?: boolean) => {
    this.annualPowerAnswer = {
      familySize: parseInt(this.familySize),
      estimatedAnnualConsum: parseInt(this.estimatedAnnualConsum),
      grossElectricityPrice: parseInt(this.grossElectricityPrice)
    }

    const answered =
      !!this.annualPowerAnswer.familySize &&
      !!this.annualPowerAnswer.estimatedAnnualConsum &&
      !!this.annualPowerAnswer.grossElectricityPrice

    this.updateQuestionStateById(Question.ANNUALPOWER, 'answered', answered)

    if (answered && openNext) {
      if (this.isPVInterested) {
        this.updateQuestionStateById(Question.EMOBILITY, 'blocked', false)
        this.updateQuestionStateById(Question.EMOBILITY, 'open', true)
      }

      if (this.isWallboxInterested) {
        this.updateQuestionStateById(Question.WALLBOX, 'blocked', false)
        this.updateQuestionStateById(Question.WALLBOX, 'open', true)
      }

      if (this.isHPInterested) {
        this.updateQuestionStateById(Question.HEATPUMP, 'blocked', false)
        this.updateQuestionStateById(Question.HEATPUMP, 'open', true)
      }
    }
    if (
      this.possessionAnswer.interestedFacility.includes(Facility.PVA) &&
      this.possessionAnswer.interestedFacility.length === 1
    ) {
      this.allAnswered = isMobile ? false : true
    } else {
      this.allAnswered = this.checkQuestionsAllAnswered()
    }

    this.allAnswered = this.checkQuestionsAllAnswered()
  }

  /** ============== 3nd Question ==========  */

  updateIsEmobilityFutureIssue = (newData: boolean) => {
    this.isEmobilityFutureIssue = newData
    this.handleSubmitEmobility()
  }

  updateAverageDrivingDistance = (newDataIndex: number, sendDataToAPI?: boolean) => {
    this.averageDrivingDistance = toJS(DrivingDistanceSteps[newDataIndex].output as string)
    this.wallboxAnswer.averageDrivingDistance = this.averageDrivingDistance
    this.eMobilityAnswer.averageDrivingDistance = this.averageDrivingDistance
    if (sendDataToAPI) {
      this.appStore.calculationStore.updateConsumEM(
        DrivingDistanceSteps[newDataIndex]!.value as number
      )
    }

    const isEmobilityShown =
      this.shownQuestions.filter((question) => question.question === Question.EMOBILITY).length > 0
    if (isEmobilityShown) {
      this.handleSubmitEmobility()
    } else {
      this.handleSubmitWallbox()
    }
  }

  handleSubmitEmobility = (openNext?: boolean) => {
    this.eMobilityAnswer = {
      isEmobilityFutureIssue: this.isEmobilityFutureIssue,
      averageDrivingDistance: this.averageDrivingDistance
    }

    const answered = !this.isEmobilityFutureIssue ? true : !!this.averageDrivingDistance

    this.updateQuestionStateById(Question.EMOBILITY, 'answered', answered)

    if (answered && openNext) {
      if (this.isWallboxInterested) {
        this.updateQuestionStateById(Question.WALLBOX, 'blocked', false)
        this.updateQuestionStateById(Question.WALLBOX, 'open', true)
      }

      if (this.isHPInterested && !this.isWallboxInterested) {
        this.updateQuestionStateById(Question.HEATPUMP, 'blocked', false)
        this.updateQuestionStateById(Question.HEATPUMP, 'open', true)
      }
    }
    this.allAnswered = this.checkQuestionsAllAnswered()
  }

  /** ============== 4th Question ==========  */

  updateEmobilityCapacity = (newDataIndex: number, sendDataToAPI?: boolean) => {
    this.eMobilityCapacity = toJS(BatteryCapacitySteps[newDataIndex].output)
    this.wallboxAnswer.eMobilityCapacity = toJS(BatteryCapacitySteps[newDataIndex].output)
    this.handleSubmitWallbox()
    if (sendDataToAPI) {
      this.appStore.calculateWallbox()
    }
  }

  handleSubmitWallbox = (openNext?: boolean) => {
    this.wallboxAnswer = {
      averageDrivingDistance: removeThousandSeparator(this.averageDrivingDistance),
      eMobilityCapacity: this.eMobilityCapacity === '> 105' ? 105 : parseInt(this.eMobilityCapacity)
    }

    const answered = this.isEmobilityFutureIssue
      ? !!this.eMobilityCapacity
      : !!this.averageDrivingDistance && !!this.eMobilityCapacity

    this.updateQuestionStateById(Question.WALLBOX, 'answered', answered)

    if (answered && openNext) {
      if (this.isHPInterested) {
        this.updateQuestionStateById(Question.HEATPUMP, 'blocked', false)
        this.updateQuestionStateById(Question.HEATPUMP, 'open', true)
      }
    }
    this.allAnswered = this.checkQuestionsAllAnswered()
  }

  /** ============== 5th Question ==========  */
  updateBuildingType = (newData: SelectOptionType[]) => {
    this.buildingType = newData
    this.heatpumAnswer.buildingType = this.buildingType.find(
      (item) => item.checked === true
    )?.value_id
    this.calculateHPConsum()
  }

  updateBuildYear = (newDataIndex: number) => {
    this.buildYear = toJS(BuildYearSteps[newDataIndex].output)
    this.updateBuildYearValue(BuildYearSteps[newDataIndex].value)
    this.calculateHPConsum()
  }

  updateBuildYearValue = (value: string) => {
    this.buildYearValue = value
    this.heatpumAnswer.buildYear = this.buildYearValue
    this.handleSumbitHeatpump()
  }

  updateBuildingArea = (newDataIndex: number, sendDataToAPI?: boolean) => {
    this.buildingArea = toJS(BuildingAreaSteps[newDataIndex].output)
    this.updateBuildingAreaValue(BuildingAreaSteps[newDataIndex].value)
    if (sendDataToAPI) {
      this.calculateHPConsum()
    }
    this.handleSumbitHeatpump()
  }

  updateBuildingAreaValue = (value: number) => {
    this.buildingAreaValue = value
    this.heatpumAnswer.buildingArea = this.buildingAreaValue
    this.handleSumbitHeatpump()
  }

  calculateHPConsum = () => {
    if (
      !!this.heatpumAnswer.buildYear &&
      !!this.heatpumAnswer.buildingArea &&
      !!this.heatpumAnswer.buildingType
    ) {
      this.appStore.calculateHPConsum(this.heatpumAnswer)
      this.appStore.calculateBattery()
      this.appStore.calculatePV()
    }
  }

  handleSumbitHeatpump = () => {
    this.heatpumAnswer = {
      buildYear: this.buildYearValue,
      buildingArea: this.buildingAreaValue,
      buildingType: this.buildingType.find((item) => item.checked === true)?.value_id
    }

    const answered =
      !!this.buildYearValue &&
      !!this.buildingAreaValue &&
      !!this.buildingType.find((item) => item.checked === true)?.value_id

    this.updateQuestionStateById(Question.HEATPUMP, 'answered', answered)

    if (answered) {
      this.allAnswered = this.checkQuestionsAllAnswered()
    }
  }

  /** ============ common states =========== */

  updateInstalledFacilityById = (
    id: FacilityType | Facility.NONE,
    key: 'checked' | 'disabled',
    value: boolean
  ) => {
    const checkedAndDisabled = getCheckedAndDisabledState(key, value)

    // ==== update the item
    const toUpdateIndex = this.installedFacility.findIndex((item) => item.name === id)
    const newFs = [
      ...this.installedFacility.slice(0, toUpdateIndex),
      { ...this.installedFacility[toUpdateIndex], ...checkedAndDisabled },
      ...this.installedFacility.slice(toUpdateIndex + 1)
    ]
    this.installedFacility = newFs

    // update disabled
    this.disabledInstalledFacility = this.getDisabledInstall()

    // update relavant facility in Interesse
    // if (key === 'checked' && id !== Facility.NONE) {
    //   this.updateInterestedFacilityById(id, 'checked', value)
    // }

    switch (id) {
      case Facility.PVA:
        this.isPVInstalled = this.installedFacility.find(
          (item) => item.name === Facility.PVA
        )!.checked

        break
      case Facility.BATTERTY:
        this.isBSInstalled = this.installedFacility.find(
          (item) => item.name === Facility.BATTERTY
        )!.checked
        break

      case Facility.WALLBOX:
        this.isWallboxInstalled = this.installedFacility.find(
          (item) => item.name === Facility.WALLBOX
        )!.checked
        break

      default:
        break
    }
  }

  updateInterestedFacilityById = (
    id: FacilityType,
    key: 'checked' | 'disabled',
    value: boolean
  ) => {
    const checkedAndDisabled = getCheckedAndDisabledState(key, value)
    const toUpdateIndex: number = this.interestedFacility.findIndex((item) => item.name === id)
    if (toUpdateIndex !== -1) {
      const newFs = [
        ...this.interestedFacility.slice(0, toUpdateIndex),
        { ...this.interestedFacility[toUpdateIndex], ...checkedAndDisabled },
        ...this.interestedFacility.slice(toUpdateIndex + 1)
      ]
      this.interestedFacility = newFs
      this.disabledInterestedFacility = this.getDisabledInterest()

      switch (id) {
        case Facility.PVA:
          this.isPVInterested = this.interestedFacility.find(
            (item) => item.name === Facility.PVA
          )?.checked
          break

        case Facility.BATTERTY:
          this.isBSInterested = this.interestedFacility.find(
            (item) => item.name === Facility.BATTERTY
          )?.checked
          break

        case Facility.WALLBOX:
          this.isWallboxInterested = this.interestedFacility.find(
            (item) => item.name === Facility.WALLBOX
          )?.checked
          break

        case Facility.HEATPUMP:
          this.isHPInterested = this.interestedFacility.find(
            (item) => item.name === Facility.HEATPUMP
          )?.checked
          break

        default:
          break
      }
    }
  }
  checkQuestionsAllAnswered = () => {
    const answeredNum = this.shownQuestions.filter(
      (ques: QuestionStateType) => ques.answered
    ).length
    if (answeredNum === this.shownQuestions.length) {
      this.appStore.deactivateResult()
    }

    return answeredNum === this.shownQuestions.length
  }

  openOneAccordionAndCloseOthers = (activeIndex: number) => {
    const updatedActiveStates = this.questionsStates
    updatedActiveStates.map((item, index: number) => {
      if (index === activeIndex && !item.hidden) {
        item.open = true
      } else {
        item.open = false
      }
    })
    this.questionsStates = toJS(updatedActiveStates)
    this.updateShownQuestions()
    // this.checkAllAccordionState()
  }

  openNextAccordion = (activeIndex: number) => {
    const length = this.shownQuestions.length
    if (activeIndex < length - 1) {
      const newStates = this.shownQuestions
      newStates.map((item, index) => {
        if (index === activeIndex + 1) {
          // first unblock the question then it can be open
          this.updateQuestionStateById(item.question, 'blocked', false)
          this.updateQuestionStateById(item.question, 'open', true)
          item.open = true
        } else {
          this.updateQuestionStateById(item.question, 'open', false)
          item.open = false
        }
      })
      this.shownQuestions = newStates
    }
  }

  updateQuestionStateById = (id: string, key: string, newValue: boolean) => {
    const toUpdateIndex = this.questionsStates.findIndex((item) => item.question === id)
    if (key === 'open' && newValue === true) {
      const updatedOpenStates = this.questionsStates
      updatedOpenStates.map((item, index: number) => {
        if (index === toUpdateIndex && !item.hidden) {
          item.open = true
        } else {
          item.open = false
        }
      })
      this.questionsStates = toJS(updatedOpenStates)
    } else {
      const newStates = [
        ...this.questionsStates.slice(0, toUpdateIndex),
        { ...this.questionsStates[toUpdateIndex], [key]: newValue },
        ...this.questionsStates.slice(toUpdateIndex + 1)
      ]
      this.questionsStates = newStates
    }

    this.updateShownQuestions()
  }

  updateShownQuestions = () => {
    this.shownQuestions = this.questionsStates.filter((question) => !question.hidden)
  }

  updateTextUnderResult = () => {
    if (this.isPVInterested) {
      this.textBehindResultForm = this.appStore.pvSettings.product_caption.pv
      this.textProductDescription = this.appStore.pvSettings.product_description.pv
      this.appStore.updateEmailPDFText(this.appStore.notifierSettings.PV)
    } else if (this.isHPInterested && !this.isPVInterested) {
      this.textBehindResultForm = this.appStore.pvSettings.product_caption.hp
      this.textProductDescription = this.appStore.pvSettings.product_description.hp
      this.appStore.updateEmailPDFText(this.appStore.notifierSettings.HP)
    } else if (this.isWallboxInterested && !this.isHPInterested && !this.isPVInterested) {
      this.textBehindResultForm = this.appStore.pvSettings.product_caption.wb
      this.textProductDescription = this.appStore.pvSettings.product_description.wb
      this.appStore.updateEmailPDFText(this.appStore.notifierSettings.WB)
    } else {
      this.textBehindResultForm = this.appStore.pvSettings.product_caption.bs
      this.textProductDescription = this.appStore.pvSettings.product_description.bs
      this.appStore.updateEmailPDFText(this.appStore.notifierSettings.BS)
    }
  }

  updateEVQ = () => {
    const interestedFacility = toJS(this.possessionAnswer.interestedFacility)
    const hasFacility = (
      facilityName: Facility.PVA | Facility.WALLBOX | Facility.BATTERTY | Facility.HEATPUMP
    ): boolean => interestedFacility.includes(facilityName)

    if (hasFacility(Facility.BATTERTY)) {
      if (hasFacility(Facility.HEATPUMP) && hasFacility(Facility.WALLBOX)) {
        this.evq = +this.appStore.pvSettings.evq.pv_with_bs_wb_hp
      } else if (hasFacility(Facility.HEATPUMP) && !hasFacility(Facility.WALLBOX)) {
        this.evq = +this.appStore.pvSettings.evq.pv_with_bs_hp
      } else if (hasFacility(Facility.WALLBOX) && !hasFacility(Facility.HEATPUMP)) {
        this.evq = +this.appStore.pvSettings.evq.pv_with_bs_wb
      } else {
        this.evq = +this.appStore.pvSettings.evq.pv_with_bs
      }
    } else {
      if (hasFacility(Facility.HEATPUMP) && hasFacility(Facility.WALLBOX)) {
        this.evq = +this.appStore.pvSettings.evq.pv_without_bs_with_wb_hp
      } else if (hasFacility(Facility.HEATPUMP) && !hasFacility(Facility.WALLBOX)) {
        this.evq = +this.appStore.pvSettings.evq.pv_without_bs_with_hp
      } else if (hasFacility(Facility.WALLBOX) && !hasFacility(Facility.HEATPUMP)) {
        this.evq = +this.appStore.pvSettings.evq.pv_without_bs_with_wb
      } else {
        this.evq = +this.appStore.pvSettings.evq.pv_without_bs
      }
    }
  }

  getDisabledInstall = () => {
    const newArr: string[] = []
    this.installedFacility.forEach((item) => {
      if (item.disabled) {
        newArr.push(item.name)
      }
    })
    return newArr
  }

  getDisabledInterest = () => {
    const newArr: string[] = []
    this.interestedFacility.forEach((item) => {
      if (item.disabled) {
        newArr.push(item.name)
      }
    })
    return newArr
  }

  get userInputForCalculation() {
    return {
      ...toJS(this.possessionAnswer),
      ...this.annualPowerAnswer,
      ...this.eMobilityAnswer,
      ...this.wallboxAnswer,
      ...this.heatpumAnswer
    }
  }
}

export default QuestionStore
