import {
  Button,
  Card,
  Hint,
  LinkFooter,
  SanitizedContent,
  Space,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import { readableColor } from 'polished'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { ContactForm, EmailFeedback, QuestionsMobile, ResultMobile } from '../template'

interface MainContentMobileProps {
  appTitle: string
}
export const MainContentMobile: React.FC<MainContentMobileProps> = observer(({ appTitle }) => {
  const store = useContext(AppStoreContext)
  const uiStore = store.uiStore

  const [showWelcome, setShowWelcome] = useState(
    store.pvWelcomeSettings?.settings_pvwelcome_text_content_display
  )

  const handleBackToQuestionFromContact = () => {
    store.uiStore.triggerContactMobile(false)
    uiStore.updateEmailState('')
    // store.questionStore.showResultMobile(false)
    // store.questionStore.hideResultMobileAfterTriggered(-1)
  }

  return (
    <>
      <Container className='result_mobile'>
        <TitleContainer>
          {store.uiStore.isContactMobileVisible && (
            <BackBtnContainer>
              <Button
                hasBorder={false}
                color={AppTheme.colors.white}
                fontSize={24}
                onClick={handleBackToQuestionFromContact}>
                ←
              </Button>
            </BackBtnContainer>
          )}
          <Typography
            color={readableColor(
              AppTheme.colors.primary,
              AppTheme.colors.black,
              AppTheme.colors.white
            )}>
            {appTitle}
          </Typography>
        </TitleContainer>
        <Card height={'calc(100% - 44px)'}>
          <QuestionsMobile />
        </Card>
        {store.uiStore.isContactMobileVisible ? (
          <ContactContainer className='contact_mobile'>
            <Card height={'calc(100% - 44px)'}>
              {uiStore.emailState === '' ? (
                <ContactForm />
              ) : (
                // <EmailFeedback succeeded={uiStore.emailState === 'succeeded'} />
                <EmailFeedback succeeded />
              )}
            </Card>
          </ContactContainer>
        ) : (
          <ResultMobile
            hidden={!uiStore.isResultMobileOpen}
            onShowResult={() => console.log('open contact in mobile')}
          />
        )}
      </Container>
      {showWelcome && (
        <HintContainer className='welcome-text-container'>
          <Hint onClose={() => setShowWelcome(false)} showCloseBtn>
            <Space p={AppTheme.spacingSize.laptop.S}>
              <SanitizedContent
                content={store.pvWelcomeSettings.settings_pvwelcome_text_content_text?.question}
              />
            </Space>
          </Hint>
        </HintContainer>
      )}
      <FooterContainer className='link_footer'>
        <LinkFooter />
      </FooterContainer>
    </>
  )
})

const HintContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 85vw;
  z-index: 1000;
  transform: translateX(-50%) translateY(-50%);
`

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  height: ${(props) => props.theme.window.innerHeight - 30}px;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  text-align: center;
`

const ContactContainer = styled.div`
  position: absolute;
  top: 44px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
`

const BackBtnContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  left: 10px;
  top: 0;
`

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0px;
  height: 30px;
  width: 100%;
  z-index: 100000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 16px;
  border-top: 1px dashed #e1e1e1;
  background-color: ${(props) => props.theme.colors.white};
`

export default MainContentMobile
