import { observer } from 'mobx-react-lite'
import { readableColor } from 'polished'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { Space } from '../Space'
import { AppTypography, Typography } from '../Typography'

interface RangeSliderProps {
  steps: string[] | { [key: string]: string | number }[]
  value: number
  setValue(val: number, sendDataToAPI?: boolean): void
  setValueFor?(key: string, value: string): void
  name?: string
  hasChanged?: boolean
  setHasChanged?(hasChanged: boolean): void
  output?: string
  description?: string
  stepDividedBy?: number
}

export const RangeSlider: React.FC<RangeSliderProps> = observer(
  ({ steps, value, setValue, name, setHasChanged, output, description, stepDividedBy = 1 }) => {
    const rangeOutputRef = useRef<HTMLOutputElement>(null)
    const rangeInputRef = useRef<HTMLInputElement>(null)
    const [inputValue, setInputValue] = useState(value * stepDividedBy)

    const [outputLeftPosition, setOutputLeftPosition] = useState(
      `${(value / (steps.length - 1)) * 100}%`
    )

    useEffect(() => {
      setOutput(value * stepDividedBy)
      setInputValue(value * stepDividedBy)
    }, [value])

    const handleChangeValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(parseInt(event.target.value))
      const new_value = Math.floor(parseInt(event.target.value) / stepDividedBy)
      setValue(new_value, false)
      setOutput(parseInt(event.target.value))

      if (setHasChanged) {
        setHasChanged(true)
      }
    }, [])

    const setOutput = useCallback(
      (inputValue: number) => {
        const max = (steps.length - 1) * stepDividedBy
        const newVal = Number((inputValue / max) * 100)
        setOutputLeftPosition(`calc(${newVal}% + (${8 - newVal * 0.15}px))`)
        // Sorta magic numbers based on size of the native UI thumb
      },
      [inputValue]
    )

    return (
      <RangeContainer className='custom_range_slider'>
        <OutputContainer>
          <RangeOutput
            className='range_slider_output'
            name={name}
            left={outputLeftPosition}
            ref={rangeOutputRef}>
            {output}
          </RangeOutput>
        </OutputContainer>
        <RangeInput
          type='range'
          className='range_slider_input'
          ref={rangeInputRef}
          name={name}
          min={0}
          max={(steps.length - 1) * stepDividedBy}
          step={1}
          value={inputValue}
          onChange={handleChangeValue}
          onMouseUp={() => setValue(Math.floor(inputValue / stepDividedBy), true)}
          onPointerUp={() => setValue(Math.floor(inputValue / stepDividedBy), true)}
        />
        <>
          {!!description && (
            <Space mt={`${AppTheme.spacingSize.laptop.XXS}px`}>
              <Typography type={AppTypography.SUBLINE} color={AppTheme.colors.primary}>
                {description}
              </Typography>
            </Space>
          )}
        </>
      </RangeContainer>
    )
  }
)

RangeSlider.displayName = 'RangeSlider'

const OutputContainer = styled.div`
  position: relative;
  height: 50px;
  width: 98%;
  margin: 0 auto;
`
export const RangeContainer = styled.div`
  width: 100%;
  position: relative;
  font-family: ${(props) => props.theme.fonts.family};
`
export const RangeInput = styled.input`
  width: 100%;
  display: block;
  height: 35px;
  appearance: none;
  margin: 12px 0px;
  padding: 2px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.grayE};
  outline: none;
  box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: visible;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: none;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
  }

  &::-moz-range-thumb {
    width: 29px;
    height: 31px;
    border-radius: 100%;
    border: none;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
  }
`

interface RangeOutputStyleProps {
  left?: string
}

export const RangeOutput = styled.output<RangeOutputStyleProps>`
  position: absolute;
  left: ${(props) => props.left};
  transform: translateX(-50%);
  display: table;
  padding: 12px 7px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.secondary};
  white-space: nowrap;
  color: ${(props) =>
    readableColor(
      props.theme.colors.secondary,
      props.theme.colors.black,
      props.theme.colors.white
    )};
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    /* margin-left: -8px; */
    width: 0;
    height: 0;
    border-top: solid 8px ${(props) => props.theme.colors.secondary};
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }
`

RangeSlider.displayName = 'RangeSlider'

export default RangeSlider
