import React from 'react'
import { Helmet } from 'react-helmet'

interface DocumentHeadProps {
  title: string
}
export const DocumentHead: React.FC<DocumentHeadProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={title} />
      <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
    </Helmet>
  )
}

export default DocumentHead
