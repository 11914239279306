import React, { useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { SVG, SVGNames } from '../SVG'
import { AppTypography, Typography } from '../Typography'

interface SelectDropDownProps {
  options: string[]
  value: string
  setValue(value: string): void
}

export const SelectDropDown: React.FC<SelectDropDownProps> = ({ options, value, setValue }) => {
  // const [value, setValue] = useState('')
  const [expand, setExpand] = useState(false)

  const handleSelect = (option: string) => {
    setExpand(!expand)
    setValue(option)
  }

  const renderOptionList = (options: string[]) => {
    return options.map((option: string) => (
      <Option key={option} onClick={() => handleSelect(option)}>
        <Typography type={AppTypography.QUESTION} fontWeight={300} letterSpacing={'0px'}>
          {option}
        </Typography>
      </Option>
    ))
  }

  return (
    <Container>
      <StyledSelect className='select_dropdown'>
        <Title onClick={() => setExpand(!expand)}>
          <Typography
            type={AppTypography.QUESTION}
            fontWeight={300}
            letterSpacing={'0px'}
            color={value === '' ? AppTheme.colors.grayB9 : AppTheme.colors.black}>
            {value === '' ? 'Termin vereinbaren' : value}
          </Typography>
          <SVGContainer open={expand}>
            <SVG name={SVGNames.ArrowUp} />
          </SVGContainer>
        </Title>
        <OptionContainer open={expand} className='options'>
          {renderOptionList(options)}
        </OptionContainer>
      </StyledSelect>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 46px;
`

const StyledSelect = styled.div`
  position: absolute;
  width: 100%;
  cursor: pointer;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.grayE};
  border-radius: 2px;
`
const Title = styled.div`
  position: relative;
  padding: ${(props) => props.theme.spacingSize.laptop.XS}px
    ${(props) => props.theme.spacingSize.laptop.S}px;
  cursor: pointer;
  height: auto;
`

interface DropDownProps {
  open: boolean
}

const SVGContainer = styled.div<DropDownProps>`
  float: right;
  right: ${(props) => props.theme.spacingSize.laptop.XS}px;
  top: 50%;
  transform: translateY(-50%) ${(props) => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: all 0.4s ease;
  position: absolute;
  background-color: ${(props) => props.theme.colors.grayD4};
`

const OptionContainer = styled.div<DropDownProps>`
  position: relative;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.open ? '1' : '0')};
  z-index: 100;
  background-color: ${(props) => props.theme.colors.white};
  max-height: ${(props) => (props.open ? '250px' : '0px')};
  transition: max-height 0.3s ease, opacity ${(props) => (props.open ? '0.3s' : '0.1s')},
    visibility 0.6s ease;
`

const Option = styled.div`
  color: ${(props) => props.theme.colors.grayB9};
  padding: ${(props) => props.theme.spacingSize.laptop.XS}px
    ${(props) => props.theme.spacingSize.laptop.S}px;
  cursor: pointer;
  height: auto;
  transition: all 0.2s ease;
  &:hover {
    color: ${(props) => props.theme.colors.black};
  }
  border-top: 1px solid ${(props) => props.theme.colors.grayE};
`

export default SelectDropDown
