/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-undef */
import Collapse from '@kunukn/react-collapse'
import {
  Accordion,
  Alert,
  AppTypography,
  QuestionTextField,
  Select,
  Space,
  Typography
} from 'components/index'
import { AppStoreContext, DeviceContext, DeviceType } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { AppTheme } from 'theme/index'
import { Question, Units } from 'utils/index'
import 'react-confirm-alert/src/react-confirm-alert.css'

interface QuestionPossessionProps {
  index: number
}

export const QuestionPossession: React.FC<QuestionPossessionProps> = observer(({ index }) => {
  const { device } = useContext(DeviceContext)
  const store = useContext(AppStoreContext)
  const isMobile = device === DeviceType.MOBILE
  const questionStore = store.questionStore

  const handleOpen = () => {
    if (!questionStore.questionsStates[0].blocked) {
      questionStore.updateQuestionStateById(
        Question.POSSESSION,
        'open',
        !questionStore.questionsStates[0].open
      )
    }
  }

  const handleClose = () => {
    // onSubmit()
    questionStore.handleSubmitQuestionPossession()
    if (!questionStore.questionsStates[0].answered) {
      questionStore.updateQuestionStateById(Question.POSSESSION, 'open', true)
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert
              onClose={onClose}
              title='Bitte beantworten Sie die Frage'
              description='Nach der Beantwortung kommen Sie zur nächsten Frage.'
            />
          )
        }
      })
    } else {
      questionStore.openNextAccordion(index)
      // questionStore.updateQuestionStateById('POSSESSION', 'open', false)
    }
  }

  return (
    <Accordion
      className='question_possession'
      index={0}
      title='Bestand und Interesse'
      onSubmit={handleClose}
      isOpen={!questionStore.questionsStates[0].blocked && questionStore.questionsStates[0].open}
      isBlocked={questionStore.questionsStates[0].blocked}
      btnText='weiter'
      setIsOpen={handleOpen}
      isEdited={questionStore.questionsStates[0].answered}>
      <>
        <Typography type={AppTypography.QUESTION} fontWeight={400}>
          {'Das habe ich bereits:'}
        </Typography>
        <Select
          itemGapX={
            isMobile ? `${AppTheme.spacingSize.mobile.XS}px` : `${AppTheme.spacingSize.laptop.XL}px`
          }
          name={'installed_facility'}
          isMultiple
          disabledItemName={questionStore.disabledInstalledFacility}
          selectedOptions={questionStore.installedFacility}
          setSelectedOptions={questionStore.updateInstalledFacility}
        />

        <Collapse isOpen={questionStore.isPVInstalled}>
          <Space mt={`${AppTheme.spacingSize.laptop.M}px`}>
            <QuestionTextField
              hint={Units.KWP}
              hasError={store.uiStore.fieldPowerPVAHasError}
              question='Leistung Ihrer PV-Anlage:'
              value={questionStore.powerPVA as string}
              name='PvPower'
              placeholder='Anlagenleistung'
              onInputChange={questionStore.updatePowerPVA}
            />
          </Space>
        </Collapse>

        <Space mb={`${AppTheme.spacingSize.laptop.L}px`} mt={`${AppTheme.spacingSize.laptop.XL}px`}>
          <Typography type={AppTypography.QUESTION} fontWeight={400}>
            {'Dafür interessiere ich mich:'}
          </Typography>
          <Select
            isMultiple
            itemGapX={
              isMobile
                ? `${AppTheme.spacingSize.mobile.XS}px`
                : `${AppTheme.spacingSize.laptop.XL}px`
            }
            name={'interested_facility'}
            disabledItemName={questionStore.disabledInterestedFacility}
            selectedOptions={questionStore.getInterestedFacilities(
              questionStore.interestedFacility
            )}
            setSelectedOptions={questionStore.updateInterestedFacility}
          />
        </Space>
      </>
    </Accordion>
  )
})

export default QuestionPossession
