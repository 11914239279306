import { Units } from './units'

export const convertAreaToSteps = () => {
  const steps: { output: string; value: number }[] = []

  for (let i = 30; i <= 75; i = i + 5) {
    steps.push({ output: `${i} ${Units.QM}`, value: i })
  }

  for (let i = 85; i <= 350; i = i + 10) {
    steps.push({ output: `${i} ${Units.QM}`, value: i })
  }

  for (let i = 400; i <= 800; i = i + 50) {
    steps.push({ output: `${i} ${Units.QM}`, value: i })
  }

  steps.push({ output: `> 800 ${Units.QM}`, value: 850 })

  return steps
}

export const BuildingAreaSteps = convertAreaToSteps()
