import {
  AccordionMobile,
  AppTypography,
  InfoBox,
  RangeSlider,
  RangeSliderCar,
  Space,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { isFirefox } from 'react-device-detect'
import { AppTheme } from 'theme/index'
import { BatteryCapacitySteps, DrivingDistanceSteps, Units } from 'utils/index'

import CapacityInfobox from './CapacityInfobox'

export const QuestionWallbox: React.FC = observer(() => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore
  const [isInfoBoxOpen, setIsInfoBoxOpen] = useState([false, false])

  const initialDrivingDistance = Math.floor((DrivingDistanceSteps.length - 1) / 2)
  const elemIndex = DrivingDistanceSteps.findIndex(
    (el) => el.output === questionStore.averageDrivingDistance
  )
  const stepIndex = elemIndex === -1 ? initialDrivingDistance : elemIndex

  const initialCapacity = Math.floor((BatteryCapacitySteps.length - 1) / 2)
  const capacityIndex = BatteryCapacitySteps.findIndex(
    (el) => el.output === questionStore.eMobilityCapacity
  )
  const capacityStepIndex = capacityIndex === -1 ? initialCapacity : capacityIndex

  const handleOpen = () => {
    if (!questionStore.questionsStates[3].blocked) {
      questionStore.updateQuestionStateById(
        'WALLBOX',
        'open',
        !questionStore.questionsStates[3].open
      )
    }
  }

  return (
    <AccordionMobile
      className='question_wallbox'
      index={3}
      title='Wallbox'
      isOpen={!questionStore.questionsStates[3].blocked && questionStore.questionsStates[3].open}
      isBlocked={questionStore.questionsStates[3].blocked}
      setIsOpen={handleOpen}
      isEdited={questionStore.questionsStates[3].answered}>
      <>
        {!questionStore.isEmobilityFutureIssue && (
          <>
            <Typography type={AppTypography.QUESTION} fontWeight={300} width='100%'>
              <InfoBox
                open={isInfoBoxOpen[0]}
                setOpen={() => setIsInfoBoxOpen([true, false])}
                setHide={() => setIsInfoBoxOpen([false, false])}
                content={
                  <>
                    Beachten Sie bitte, dass ein Hybridfahrzeug deutlich weniger Strecke elektrisch
                    fährt, wodurch der Mehrverbrauch geringer ausfallen kann.
                  </>
                }
                info='Wie viele Kilometer fahren Sie durchschnittlich im Jahr?'
              />
            </Typography>
            <Space
              mt={`${AppTheme.spacingSize.mobile.S}px`}
              mb={`${AppTheme.spacingSize.mobile.XL}px`}>
              {isFirefox ? (
                <RangeSlider
                  steps={DrivingDistanceSteps}
                  stepDividedBy={20}
                  value={
                    questionStore.averageDrivingDistance !== '' ? stepIndex : initialDrivingDistance
                  }
                  setValue={questionStore.updateAverageDrivingDistance}
                  output={
                    !!questionStore.averageDrivingDistance
                      ? `${DrivingDistanceSteps[stepIndex].output} ${Units.KM}`
                      : 'Bitte wählen'
                  }
                  description={`Ihr Mehrverbrauch pro Jahr liegt bei etwa ${store.calculationStore.consumEM} ${Units.KWH}.`}
                />
              ) : (
                <RangeSliderCar
                  steps={DrivingDistanceSteps}
                  stepDividedBy={20}
                  value={
                    questionStore.averageDrivingDistance !== '' ? stepIndex : initialDrivingDistance
                  }
                  setValue={questionStore.updateAverageDrivingDistance}
                  output={
                    !!questionStore.averageDrivingDistance
                      ? `${DrivingDistanceSteps[stepIndex].output} ${Units.KM}`
                      : 'Bitte wählen'
                  }
                  description={`Ihr Mehrverbrauch pro Jahr liegt bei etwa ${store.calculationStore.consumEM} ${Units.KWH}.`}
                />
              )}
            </Space>
          </>
        )}

        <Space mb={`${AppTheme.spacingSize.mobile.XXL}px`}>
          <Typography type={AppTypography.QUESTION} fontWeight={300} width='100%'>
            <InfoBox
              open={isInfoBoxOpen[1]}
              setOpen={() => setIsInfoBoxOpen([false, true])}
              setHide={() => setIsInfoBoxOpen([false, false])}
              content={<CapacityInfobox />}
              info='Welche Kapazität hat die Batterie Ihres E-Mobils?'
            />
          </Typography>

          <Space mt={`${AppTheme.spacingSize.mobile.S}px`}>
            <RangeSlider
              steps={BatteryCapacitySteps}
              stepDividedBy={10}
              value={questionStore.eMobilityCapacity !== '' ? capacityStepIndex : initialCapacity}
              setValue={questionStore.updateEmobilityCapacity}
              output={
                !!questionStore.eMobilityCapacity
                  ? `${BatteryCapacitySteps[capacityStepIndex].output} ${Units.KWH}`
                  : 'Bitte wählen'
              }
            />
          </Space>
        </Space>
      </>
    </AccordionMobile>
  )
})

export default QuestionWallbox
