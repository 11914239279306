/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-undef */
import Collapse from '@kunukn/react-collapse'
import {
  Accordion,
  Alert,
  AppTypography,
  Flex,
  InfoBox,
  RangeSlider,
  RangeSliderCar,
  Space,
  ToggleButton,
  Typography
} from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { isFirefox } from 'react-device-detect'
import { AppTheme } from 'theme/index'
import { DrivingDistanceSteps, Question, Units } from 'utils/index'
import 'react-confirm-alert/src/react-confirm-alert.css'

interface QuestionEmobilityProps {
  index: number
  isLast: boolean
}

export const QuestionEmobility: React.FC<QuestionEmobilityProps> = observer(({ index, isLast }) => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore
  const [isInfoBoxOpen, setIsInfoBoxOpen] = useState(false)

  useEffect(() => {
    if (isLast) {
      store.deactivateResult()
    }
  })

  const initialDrivingDistance = Math.floor((DrivingDistanceSteps.length - 1) / 2)
  const elemIndex = DrivingDistanceSteps.findIndex(
    (el) => el.output === questionStore.averageDrivingDistance
  )
  const stepIndex = elemIndex === -1 ? initialDrivingDistance : elemIndex

  const handleOpen = () => {
    if (!questionStore.questionsStates[2].blocked) {
      questionStore.updateQuestionStateById(
        Question.EMOBILITY,
        'open',
        !questionStore.questionsStates[2].open
      )
    }
  }

  const handleClose = () => {
    // onSubmit()
    questionStore.handleSubmitEmobility()
    if (!questionStore.questionsStates[2].answered) {
      questionStore.updateQuestionStateById(Question.EMOBILITY, 'open', true)
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert
              onClose={onClose}
              title='Bitte beantworten Sie die Frage'
              description='Nach der Beantwortung kommen Sie zur nächsten Frage.'
            />
          )
        }
      })
    } else {
      if (isLast && !store.uiStore.resultDeactivated) {
        store.uiStore.updateLeftSideWidth(35)
        store.uiStore.updateShowContact(true)
        store.calculateResult()
      } else {
        questionStore.openNextAccordion(index)
      }

      // questionStore.updateQuestionStateById('EMOBILITY', 'open', false)
    }
  }

  return (
    <Accordion
      className='question_emobility'
      isOpen={!questionStore.questionsStates[2].blocked && questionStore.questionsStates[2].open}
      isBlocked={questionStore.questionsStates[2].blocked}
      title='E-Mobilität'
      index={2}
      btnText={isLast ? 'Angebot anzeigen' : 'weiter'}
      setIsOpen={handleOpen}
      onSubmit={handleClose}
      isEdited={questionStore.questionsStates[2].answered}
      btnDisabled={isLast ? !store.questionStore.allAnswered : isLast}>
      <>
        <Space mb={`${AppTheme.spacingSize.laptop.XL}px`}>
          <ToggleButton
            label='Ist E-Mobilität für Sie zukünftig ein Thema?'
            name='isEmobilityFutureIssue'
            setChecked={() =>
              questionStore.updateIsEmobilityFutureIssue(!questionStore.isEmobilityFutureIssue)
            }
            checked={questionStore.isEmobilityFutureIssue}
          />
        </Space>
        <Collapse isOpen={questionStore.isEmobilityFutureIssue} overflowOnExpanded={true}>
          <>
            <Flex flexDirection='row' justifyContent='flex-start' alignItems='center'>
              <Typography
                type={AppTypography.QUESTION}
                fontWeight={300}
                display='inline'
                width='auto'>
                {'Wie viele Kilometer fahren Sie durchschnittlich im Jahr?'}{' '}
              </Typography>
              <InfoBox
                open={isInfoBoxOpen}
                setOpen={() => setIsInfoBoxOpen(true)}
                setHide={() => setIsInfoBoxOpen(false)}
                content={
                  <>
                    Beachten Sie bitte, dass ein Hybridfahrzeug deutlich weniger Strecke elektrisch
                    fährt, wodurch der Mehrverbrauch geringer ausfallen kann.
                  </>
                }
              />
            </Flex>
            <Space
              mt={`${AppTheme.spacingSize.laptop.S}px`}
              mb={`${AppTheme.spacingSize.laptop.M}px`}>
              {isFirefox ? (
                <RangeSlider
                  steps={DrivingDistanceSteps}
                  stepDividedBy={50}
                  value={
                    questionStore.averageDrivingDistance !== '' ? stepIndex : initialDrivingDistance
                  }
                  setValue={questionStore.updateAverageDrivingDistance}
                  output={
                    !!questionStore.averageDrivingDistance
                      ? `${DrivingDistanceSteps[stepIndex].output} ${Units.KM}`
                      : 'Bitte wählen'
                  }
                  description={`Ihr Mehrverbrauch pro Jahr liegt bei etwa ${store.calculationStore.consumEM} ${Units.KWH}.`}
                />
              ) : (
                <RangeSliderCar
                  steps={DrivingDistanceSteps}
                  stepDividedBy={50}
                  value={
                    questionStore.averageDrivingDistance !== '' ? stepIndex : initialDrivingDistance
                  }
                  setValue={questionStore.updateAverageDrivingDistance}
                  output={
                    !!questionStore.averageDrivingDistance
                      ? `${DrivingDistanceSteps[stepIndex].output} ${Units.KM}`
                      : 'Bitte wählen'
                  }
                  description={`Ihr Mehrverbrauch pro Jahr liegt bei etwa ${store.calculationStore.consumEM} ${Units.KWH}.`}
                />
              )}
            </Space>
          </>
        </Collapse>
      </>
    </Accordion>
  )
})

export default QuestionEmobility
