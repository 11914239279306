import { makeAutoObservable } from 'mobx'
import { numberWithThousandSeparator, removeThousandSeparator } from 'utils/index'

import AppStore from './ApplicationStore'

class CalculationStore {
  appStore: typeof AppStore
  consumEM: string = '0' // 10.2
  consumHP: string = '0'
  // annualElecConsum: number = 0

  // const annualElecConsum: number =
  //     userInput.estimatedAnnualConsum + clientCalculate.additionConsumEM + this.additionalConsumHP

  pvShowPurchasePrice: boolean = false

  bsShowPurchasePrice: boolean = false

  hpShowPurchasePrice: boolean = false

  public constructor(appStore: typeof AppStore) {
    makeAutoObservable(this)
    this.appStore = appStore
    this.appStore.loadPVSettings().then((pvSettings: any) => {
      this.updatePvShowPurchasePrice(
        this.shouldShowFacilityPurchasePrice(pvSettings.pvanlage.purchase_rent)
      )
      this.updateBsShowPurchasePrice(
        this.shouldShowFacilityPurchasePrice(pvSettings.battery.purchase_rent)
      )
      this.updateHpShowPurchasePrice(
        this.shouldShowFacilityPurchasePrice(pvSettings.heatpump.purchase_rent)
      )
    })
  }

  get electricityConsumYear() {
    // 1. Jahresstromverbrauch, 2. Mehrverbrauch E-Mobilität, 3.Mehrverbrauch Wärmepumpe
    // 10.1
    // console.log({
    //   estimatedAnnualConsum: parseInt(this.appStore.questionStore.estimatedAnnualConsum),
    //   consumEM: removeThousandSeparator(this.consumEM),
    //   consumHP: removeThousandSeparator(this.consumHP)
    // })
    return (
      parseInt(this.appStore.questionStore.estimatedAnnualConsum) +
      removeThousandSeparator(this.consumEM) +
      removeThousandSeparator(this.consumHP)
    )
  }

  get optimalPVSize() {
    // 10.4
    return this.electricityConsumYear / parseInt(this.appStore.pvSettings?.pvanlage.annual_yield)
  }

  updateConsumEM = (newValue: number) => {
    const multiplyFactor =
      this.appStore.pvSettings?.electricity?.additional_electricity_consum_per_km
    this.consumEM = numberWithThousandSeparator(newValue * multiplyFactor)
    this.appStore.calculateBattery()
    this.appStore.calculatePV()
  }

  updatePvShowPurchasePrice = (newState: boolean) => {
    this.pvShowPurchasePrice = newState
  }

  updateBsShowPurchasePrice = (newState: boolean) => {
    this.bsShowPurchasePrice = newState
  }

  updateHpShowPurchasePrice = (newState: boolean) => {
    this.hpShowPurchasePrice = newState
  }

  shouldShowFacilityPurchasePrice = (facilityModel: Info): boolean => {
    if (facilityModel.rentModel) {
      if (facilityModel.purchaseModel) {
        return facilityModel.standardSelect === 'purchase'
      } else {
        return false
      }
    } else {
      return true
    }
  }
}

interface Info {
  rentModel: boolean
  purchaseModel: boolean
  standardSelect?: 'purchase' | 'rent' | ''
  leaseRuntime?: number
}

export default CalculationStore
