export const DrivingDistanceSteps: { output: string; value: number }[] = [
  { output: '3.000', value: 3000 },
  { output: '6.000', value: 6000 },
  { output: '9.000', value: 9000 },
  { output: '12.000', value: 12000 },
  { output: '15.000', value: 15000 },
  { output: '18.000', value: 18000 },
  { output: '21.000', value: 21000 },
  { output: '24.000', value: 24000 },
  { output: '27.000', value: 27000 },
  { output: '30.000', value: 30000 },
  { output: '> 30.000', value: 30000 }
]

// 3.000,6.000,9.000,12.000,15.000,18.000,21.000,24.000,27.000,30.000,>30.000
