import {
  AppTypography,
  Button,
  Flex,
  Image,
  Link,
  LinkFooter,
  Space,
  Typography
} from 'components/index'
import { AppStoreContext, DeviceContext, DeviceType } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import { readableColor } from 'polished'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'
import { baseUploadsUrl } from 'utils/index'

import ResultTable from './ResultTable'
import ResultTableMobile from './ResultTableMobile'

interface ResultAfterCalculationProps {
  onShowQuestions?(): void
}

export const ResultAfterCalculation: React.FC<ResultAfterCalculationProps> = observer(
  ({ onShowQuestions }) => {
    const { device } = useContext(DeviceContext)
    const store = useContext(AppStoreContext)
    const questionStore = store.questionStore
    const uiStore = store.uiStore
    const isMobile = device === DeviceType.MOBILE
    const logoURL = baseUploadsUrl + store.ciSettings?.settings_sw_file_logo

    return (
      <Flex justifyContent='space-between' flexDirection='column' style={{ height: '100%' }}>
        <div>
          <Space
            mt={`${AppTheme.spacingSize.laptop.XXS}px`}
            mb={
              isMobile
                ? `${AppTheme.spacingSize.mobile.L}px`
                : `${AppTheme.spacingSize.laptop.XXS}px`
            }
            pt={`${AppTheme.spacingSize.laptop.XXS}px`}
            pb={`${AppTheme.spacingSize.laptop.XXS}px`}>
            <ImageContainer>
              <Image
                src={logoURL}
                display='block'
                maxWidth='80%'
                width='100%'
                maxHeight='200px'
                style={{ alignSelf: 'flex-start', objectFit: 'contain' }}
              />
            </ImageContainer>
          </Space>

          {isMobile ? <ResultTableMobile /> : <ResultTable />}

          {!(isMobile && uiStore.resultDeactivated) && (
            <>
              {/* <Typography
                type={AppTypography.RESULT}
                fontWeight={300}
                color={AppTheme.colors.gray65}>
                {questionStore.textProductDescription?.description}
              </Typography> */}
              <Space my={`${AppTheme.spacingSize.laptop.M}px`}>
                <TextContainer>
                  <Typography
                    type={AppTypography.RESULT}
                    color={readableColor(
                      AppTheme.colors.secondary,
                      AppTheme.colors.black,
                      AppTheme.colors.white
                    )}
                    fontWeight='bold'>
                    {questionStore.textBehindResultForm}
                  </Typography>
                </TextContainer>
              </Space>
              <Typography
                type={AppTypography.RESULT}
                fontWeight={300}
                color={AppTheme.colors.gray65}>
                {questionStore.textProductDescription?.description}
              </Typography>
            </>
          )}
          {!!questionStore.textProductDescription?.url &&
            !!questionStore.textProductDescription?.linkText && (
              <Space my={`${AppTheme.spacingSize.laptop.S}px`}>
                <Link
                  href={questionStore.textProductDescription?.url}
                  target='_blank'
                  fontSize={`${AppTheme.fonts.fontsize.app.S}px`}>
                  {questionStore.textProductDescription?.linkText}
                </Link>
              </Space>
            )}
          {!isMobile && (
            <Space mt={`${AppTheme.spacingSize.laptop.M}px`}>
              <Flex flexDirection='row' justifyContent='flex-end' alignItems='center'>
                <Button hasBorder onClick={onShowQuestions}>
                  Zurück
                </Button>
              </Flex>
            </Space>
          )}
        </div>

        {!isMobile && (
          <Space mt={`${AppTheme.spacingSize.laptop.M}px`}>
            <LinkFooter />
          </Space>
        )}
      </Flex>
    )
  }
)

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TextContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: ${(props) => props.theme.spacingSize.laptop.XS}px;
  border-radius: 5px;
`

export default ResultAfterCalculation
