import React, { ReactElement, ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { AppTheme } from '../../../theme'

interface ButtonProps extends ButtonStyleProps {
  children: string | ReactElement | ReactNode
  onClick?(): void
}
export const Button: React.FC<ButtonProps> = ({
  children,
  display,
  isUnderlined,
  hasBorder,
  onClick,
  maxWidth,
  animated,
  visible,
  hasIcon,
  color,
  fontSize,
  disabled
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <ButtonContainer
      fontSize={fontSize}
      display={display}
      onClick={handleClick}
      className='pv-rechner__button'
      animated={animated}
      visible={visible}
      disabled={disabled}>
      {hasIcon ? (
        <>{children}</>
      ) : (
        <>
          {hasBorder ? (
            <ButtonOuterWrapper disabled={disabled}>
              <ButtonWithBorder disabled={disabled}>{children}</ButtonWithBorder>
            </ButtonOuterWrapper>
          ) : (
            <TextButton
              isUnderlined={isUnderlined}
              maxWidth={maxWidth}
              color={disabled ? AppTheme.colors.grayB9 : color}
              disabled={disabled}>
              {children}
            </TextButton>
          )}
        </>
      )}
    </ButtonContainer>
  )
}

Button.defaultProps = {
  hasBorder: true,
  display: 'inline-block',
  animated: false,
  visible: true,
  hasIcon: false
}

interface ButtonStyleProps {
  visible?: boolean
  animated?: boolean
  maxWidth?: string
  hasBorder?: boolean
  isUnderlined?: boolean
  display?: 'block' | 'inline-block'
  hasIcon?: boolean
  color?: string
  fontSize?: number
  disabled?: boolean
}

const zoom = keyframes`
  0% {transform: scale(1);}
  50% {transform: scale(1.5);}
  100% {transform: scale(1);}
`

const animation = css`
  animation: ${zoom} 2s ease 1s 1;
`

const ButtonContainer = styled.div<ButtonStyleProps>`
  display: ${(props) => props.display};
  font-family: ${(props) => props.theme.fonts.family};
  font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.fonts.fontsize.app.L)}px;
  text-transform: uppercase;
  user-select: none;
  ${(props) => props.animated && animation};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  white-space: nowrap;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

const TextButton = styled.div<Partial<ButtonStyleProps>>`
  width: auto;
  max-width: ${(props) => props.maxWidth};
  color: ${(props) => (props.color ? props.color : props.theme.colors.secondary)};
  transition: all 0.3s ease;

  &:hover {
    text-decoration: ${(props) => props.isUnderlined && 'underline'};
    color: ${(props) => !props.isUnderlined && props.theme.colors.primary};
  }
`

const ButtonOuterWrapper = styled.div<{ disabled?: boolean }>`
  display: block;
  border-radius: 5px;
  padding: 5px;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.grayB9 : theme.colors.secondary};
`

const ButtonWithBorder = styled.div<{ disabled?: boolean }>`
  height: auto;
  width: auto;
  padding: 10px 15px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.white};
  appearance: none;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 2px 2px;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.grayB9 : theme.colors.secondary)};
  transition: all 0.3s ease;
  &:active {
    box-shadow: none;
  }
`

export default Button
