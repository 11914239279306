import React from 'react'
import styled from 'styled-components'
interface ProgressUnitProps {
  isFirst?: boolean
  isCompleted?: boolean
  height: string
}

export const ProgressUnit: React.FC<ProgressUnitProps> = ({ height, isFirst, isCompleted }) => {
  return <StyledProgressUnit isFirst={isFirst} height={height} isCompleted={isCompleted} />
}

ProgressUnit.defaultProps = {
  isCompleted: false
}

const StyledProgressUnit = styled.div<ProgressUnitProps>`
  height: ${(props) => props.height};
  width: 8px;
  background-color: ${(props) => props.theme.colors.secondary};
  border-top-left-radius: ${(props) => props.isFirst && '3px'};
  border-top-right-radius: ${(props) => props.isFirst && '3px'};
  box-sizing: border-box;
  z-index: 10;
  margin-top: 2px;
  /* &::before {
    content: '';
    position: absolute;
    z-index: 8;
    box-shadow: rgb(0, 0, 0, 0.3) 6px 0 6px -6px inset;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } */
  &::after {
    content: '';
    position: absolute;
    width: inherit;
    height: ${(props) => props.height};
    border-top-left-radius: ${(props) => props.isFirst && '3px'};
    border-top-right-radius: ${(props) => props.isFirst && '3px'};
    background-color: ${(props) => props.theme.colors.primary};
    transition: all 0.4s ease;
    transform: ${(props) => (props.isCompleted ? 'scaleY(1)' : 'scaleY(0)')};
    -webkit-transform: ${(props) => (props.isCompleted ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: 0 100%;
    z-index: ${(props) => (props.isCompleted ? 15 : 5)};
  }
`
export default ProgressUnit
