import React from 'react'
import styled from 'styled-components'

interface ImageProps extends ImageStyleProps {
  src: string
}

interface ImageStyleProps {
  display: string
  width?: string
  maxWidth?: string
  height?: string
  maxHeight?: string
  style?: { [key: string]: string }
}

export const Image: React.FC<ImageProps> = ({
  src,
  display,
  width,
  maxWidth,
  height,
  maxHeight,
  style
}) => {
  return (
    <StyledImage
      className='image'
      src={src}
      display={display}
      width={width}
      maxWidth={maxWidth}
      height={height}
      style={style}
      maxHeight={maxHeight}
    />
  )
}

const StyledImage = styled.img<ImageProps>`
  display: ${(props) => props.display};
  width: ${(props) => (props.width ? '100%' : props.width)};
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.height};
  max-height: ${(props) => props.maxHeight};
  ${(props) => props.style};
`

export default Image
