export type AppTypoTypes =
  | 'headline'
  | 'question'
  | 'button'
  | 'iconDescription'
  | 'subline'
  | 'infobox'
  | 'result'
  | 'table'

export enum AppTypography {
  HEADLINE = 'headline',
  QUESTION = 'question',
  BUTTON = 'button',
  ICONDESCRIPTION = 'iconDescription',
  SUBLINE = 'subline',
  INFOBOX = 'infobox',
  RESULT = 'result',
  TABLE = 'table'
}

export type TeaserTypoTypes =
  | 'teaser_productName'
  | 'teaser_headline'
  | 'teaser_sublineA'
  | 'teaser_sublineB'
  | 'teaser_text'
  | 'teaser_textInBox'
  | 'teaser_productName_m'
  | 'teaser_headline_m'
  | 'teaser_sublineA_m'
  | 'teaser_sublineB_m'
  | 'teaser_text_m'
  | 'teaser_textInBox_m'

export enum TeaserTypography {
  PRODUCTNAME = 'teaser_productName',
  HEADLINE = 'teaser_headline',
  SUBLINEA = 'teaser_sublineA',
  SUBLINEB = 'teaser_sublineB',
  TEXT = 'teaser_text',
  TEXTINBOX = 'teaser_textInBox',
  PRODUCTNAME_M = 'teaser_productName_m',
  HEADLINE_M = 'teaser_headline_m',
  SUBLINEA_M = 'teaser_sublineA_m',
  SUBLINEB_M = 'teaser_sublineB_m',
  TEXT_M = 'teaser_text_m',
  TEXTINBOX_M = 'teaser_textInBox_m'
}

export type TypographyAlignmentType = 'left' | 'center' | 'right' | 'justify' | 'end'
export type TypographyDisplayType = 'inline-block' | 'block' | 'inline' | 'inline-flex'
