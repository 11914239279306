import { Card, Hint, SanitizedContent, Space, Typography } from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import { readableColor } from 'polished'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import { ContactForm, EmailFeedback, Questions, Result } from '../template'

interface MainContentLaptopProps {
  appTitle: string
}

export const MainContentLaptop: React.FC<MainContentLaptopProps> = observer(({ appTitle }) => {
  const store = useContext(AppStoreContext)
  const uiStore = store.uiStore

  const [showWelcome, setShowWelcome] = useState(
    store.pvWelcomeSettings?.settings_pvwelcome_text_content_display
  )

  const handleExpandContact = () => {
    if (!uiStore.resultDeactivated) {
      uiStore.updateLeftSideWidth(uiStore.showContact ? 65 : 35)
      uiStore.updateShowContact(true)
      store.calculateResult()
    }
  }

  const handleBackToQuestions = () => {
    uiStore.updateLeftSideWidth(65)
    uiStore.updateShowContact(false)
    uiStore.updateEmailState('')
  }

  return (
    <Container>
      {/* ======= Welcome card ======= */}
      {showWelcome && (
        <HintContainer className='welcome-text-container'>
          <Hint onClose={() => setShowWelcome(false)} showCloseBtn>
            <Space p={AppTheme.spacingSize.laptop.S}>
              <SanitizedContent
                content={store.pvWelcomeSettings.settings_pvwelcome_text_content_text?.question}
              />
            </Space>
          </Hint>
        </HintContainer>
      )}

      {/* ======= Left side: Questions / Contact form / Email Feedback ======= */}
      <LeftSideContainer width={`${uiStore.leftSideWidth}%`}>
        <TitleContainer>
          <Typography
            color={readableColor(
              AppTheme.colors.primary,
              AppTheme.colors.black,
              AppTheme.colors.white
            )}>
            {appTitle}
          </Typography>
        </TitleContainer>
        <Space marginTop='44px' height='calc(100% - 44px)'>
          <Card height='100%'>
            {!!store && !uiStore.showContact ? (
              <Questions />
            ) : uiStore.emailState === '' ? (
              <ContactForm />
            ) : (
              // <EmailFeedback succeeded={uiStore.emailState === 'succeeded'} />
              <EmailFeedback succeeded />
            )}
          </Card>
        </Space>
      </LeftSideContainer>

      {/* ======= Left side: Result ======= */}
      <RightSiderContainer width={`${100 - uiStore.leftSideWidth}%`}>
        <Space marginTop='44px' height='calc(100% - 44px)'>
          <Card>
            <Result
              onShowResult={handleExpandContact}
              showCalculation={uiStore.showContact}
              onShowQuestions={handleBackToQuestions}
            />
          </Card>
        </Space>
      </RightSiderContainer>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface ContainerStyleProps {
  width: string
}

const TitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  text-align: center;
`

const HintContainer = styled.div`
  position: absolute;
  left: 40px;
  top: 40px;
  max-width: 500px;
  z-index: 1000;
`

const LeftSideContainer = styled.div<ContainerStyleProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.theme.window.innerHeight}px;
  position: relative;
  background-color: ${(props) => props.theme.colors.primary};
  transition: all 0.5s ease;
`
const RightSiderContainer = styled.div<ContainerStyleProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.theme.window.innerHeight}px;
  position: relative;
  background-color: ${(props) => props.theme.colors.secondary};
  transition: all 0.5s ease;
`

export default MainContentLaptop
