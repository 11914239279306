import { Progress, ScrollContainer } from 'components/index'
import { AppStoreContext } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'
import { Question } from 'utils/index'

import QuestionAnnualPower from './QuestionAnnualPower'
import QuestionEmobility from './QuestionEmobility'
import QuestionHeatpump from './QuestionHeatpump'
import QuestionPossession from './QuestionPossession'
import QuestionWallbox from './QuestionWallbox'

export const Questions: React.FC = observer(({}) => {
  const store = useContext(AppStoreContext)
  const questionStore = store.questionStore

  return (
    <QuestionsContainer className='questions'>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginLeft: `${AppTheme.spacingSize.laptop.S}px`,
          bottom: '0px'
        }}>
        <Progress
          length={questionStore.shownQuestions.length}
          completeNumber={
            questionStore.shownQuestions.filter((item) => item.answered === true).length
          }
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <ScrollContainer
          height='100%'
          ySpace={[`${AppTheme.spacingSize.laptop.S}px`, `${AppTheme.spacingSize.laptop.M}px`]}
          xSpace={[`${AppTheme.spacingSize.laptop.L}px`]}>
          <>
            {questionStore.shownQuestions.map((question, index: number) => {
              switch (question.question) {
                case Question.POSSESSION:
                  return <QuestionPossession index={index} key={index} />
                case Question.ANNUALPOWER:
                  return (
                    <QuestionAnnualPower
                      index={index}
                      key={index}
                      isLast={index === questionStore.shownQuestions.length - 1}
                    />
                  )
                case Question.EMOBILITY:
                  return (
                    <QuestionEmobility
                      index={index}
                      key={index}
                      isLast={index === questionStore.shownQuestions.length - 1}
                    />
                  )
                case Question.WALLBOX:
                  return (
                    <QuestionWallbox
                      index={index}
                      key={index}
                      isLast={index === questionStore.shownQuestions.length - 1}
                    />
                  )
                case Question.HEATPUMP:
                  return (
                    <QuestionHeatpump
                      index={index}
                      key={index}
                      isLast={index === questionStore.shownQuestions.length - 1}
                    />
                  )
                default:
                  undefined
              }
            })}
          </>
        </ScrollContainer>
      </div>
    </QuestionsContainer>
  )
})

const QuestionsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.gray65};
  display: flex;
`

export default Questions
