type NotifierSetting = {
  PV: { [key: string]: string }
  BS: { [key: string]: string }
  WB: { [key: string]: string }
  HP: { [key: string]: string }
}

export const mapDataToNotifierSettings = (data: { [key: string]: { [key: string]: string } }) => {
  const notifierSettings: NotifierSetting = {
    PV: {},
    BS: {},
    WB: {},
    HP: {}
  }

  notifierSettings.PV.intro = data.settings_notifier_text_v1__intro_text.question
  notifierSettings.PV.middle = data.settings_notifier_text_v1__middle_text.question
  notifierSettings.PV.outro = data.settings_notifier_text_v1__outro_text.question

  notifierSettings.BS.intro = data.settings_notifier_text_v2__intro_text.question
  notifierSettings.BS.middle = data.settings_notifier_text_v2__middle_text.question
  notifierSettings.BS.outro = data.settings_notifier_text_v2__outro_text.question

  notifierSettings.WB.intro = data.settings_notifier_text_v3__intro_text.question
  notifierSettings.WB.middle = data.settings_notifier_text_v3__middle_text.question
  notifierSettings.WB.outro = data.settings_notifier_text_v3__outro_text.question

  notifierSettings.HP.intro = data.settings_notifier_text_v4__intro_text.question
  notifierSettings.HP.middle = data.settings_notifier_text_v4__middle_text.question
  notifierSettings.HP.outro = data.settings_notifier_text_v4__outro_text.question

  return notifierSettings
}
