import { DeviceContext, DeviceType } from 'contexts/index'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

import ProgressUnit from './ProgressUnit'

interface ProgressProps {
  length: number
  completeNumber: number
}
export const Progress: React.FC<ProgressProps> = ({ length, completeNumber }) => {
  const { windowHeight, device } = useContext(DeviceContext)

  const [completeNum, setCompleteNum] = useState(completeNumber)

  const createProgressBar = () => {
    const progressBar = []
    for (let i = 0; i < length; i++) {
      progressBar.push(
        <ProgressUnit
          key={i}
          height={`${(windowHeight - AppTheme.spacingSize.laptop.XL) / length - 1}px`}
          isCompleted={i < completeNum}
          isFirst={i === length - 1}
        />
      )
    }
    return progressBar
  }

  useEffect(() => {
    if (completeNumber !== completeNum) {
      setCompleteNum(completeNumber)
    }
  }, [completeNum, completeNumber])

  return (
    <ProgressBar
      height={`calc(${windowHeight}px - ${AppTheme.spacingSize.laptop.XL}px)`}
      left={device === DeviceType.MOBILE ? '12px' : '0px'}>
      {createProgressBar()}
    </ProgressBar>
  )
}

interface ProgressStyleProps {
  height: string

  left: string
}

const ProgressBar = styled.div<ProgressStyleProps>`
  position: absolute;
  top: 20px;
  bottom: -1px;
  left: ${(props) => props.left};
  display: inline-flex;
  flex-direction: column-reverse;
  height: ${(props) => props.height};
  box-sizing: border-box;
  /* box-shadow: rgb(0, 0, 0) 0px 0px 2px inset; */
  /* &::before {
    content: '';
    z-index: 1000;
    box-shadow: rgb(0, 0, 0, 0.3) 6px 0 6px -6px inset;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } */
`

export default Progress
