import React from 'react'
import { Path, UseFormRegister } from 'react-hook-form'
import styled from 'styled-components'
import { space, SpaceProps, typography, TypographyProps } from 'styled-system'

import { IFormValues } from '../../template'

interface InputAreaProps {
  label: Path<IFormValues>
  register: UseFormRegister<IFormValues>
  placeholder?: string
}
export const TextArea: React.VFC<InputAreaProps> = ({ label, register, placeholder }) => {
  return <StyledTextarea {...register(label)} placeholder={placeholder} rows={4} cols={50} />
}

const StyledTextarea = styled.textarea<SpaceProps & TypographyProps>`
  ${typography}
  ${space}
  width: 100%;
  border: solid 1px #eee;
  resize: none;
  overflow: auto;
  font-size: 16px;
  outline: none;
  font-family: ${(props) => props.theme.fonts.family};
  font-weight: ${(props) => props.theme.fonts.weight.light};
  padding: ${(props) => props.theme.spacingSize.laptop.XS}px;

  :focus {
    outline: none;
    font-family: ${(props) => props.theme.fonts.family};
  }

  ::placeholder {
    font-weight: lighter;
    color: #b9b9b9;
    font-family: ${(props) => props.theme.fonts.family};
  }
`
